
import React, { useEffect, useRef } from "react";
import './App.css';
import video_about from './images/about-us-video.mp4';
import pngwing1 from './images/pngwing-1.png';
import pngwing2 from './images/pngwing-2.png';
import pngwing3 from './images/pngwing-3.png';
import pngwing4 from './images/pngwing-4.png';
import sponsor from './images/sponsor.png';
import team1 from './images/team1.png';
import team2 from './images/team2.png';
import team3 from './images/team3.png';
import team4 from './images/team4.png';
import team5 from './images/team5.png';
import team6 from './images/team6.png';
import OurPartners from './component/OurPartners/OurPartners.jsx';
import {Helmet} from "react-helmet";

import AutoCounter from "../about/component/Countdown1";


import Learnmore from './component/Learnmore.js'
import { Link } from "react-router-dom";
import Technologyused from './component/Technologyused.js';
import TextSlider from './component/TextMoving.js';
//import {Link} from "react-router-dom";


function About() {

  



  const texts = [
    {
      title: 'Create',
    },
    {
      title: 'Connect',
    },
    {
      title: 'Conquer',
    },
  ];
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  
  return (
    <>
          <Helmet>
                <meta charSet="utf-8" />
                <title>About Us - Appedology.pk</title>
                <meta name="description" content="Get to know our story, our vision, our objectives, and what we stand for on our about us page!" />
                <link rel="canonical" href="https://appedology.pk/about" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="About Us - Appedology.pk" />
                <meta property="og:description" content="Get to know our story, our vision, our objectives, and what we stand for on our about us page!" />
                <meta property="og:url" content="https://appedology.pk/about" />
                <meta property="og:locale" content= "pakistan" />
                <meta property="og:site_name" content="Appedology" />
                <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>

      <div className='aboutslider'>
        <div className='container'>
            <div className='row aboutus mt-5'>
                <div className='col-md-12  col-xl-11 mx-auto text-white text-center'>
                    <h2 className='h6 mb-0'>Who Are We?</h2>
                    <h3 className='h1 mb-0 pt-3'>Pioneers in Business Technology and Innovation</h3>
                    <h4 className='h6 mb-0 w-100 d-block mx-auto lh-base pt-4'>
                    We are the architects of tomorrow's digital landscape. As a dynamic BPO company, Appedology specializes in a diverse range of services including but not limited to business management, medical billing & revenue cycle management, digital marketing, sales and business development. 
                    Our expertise extends to cutting-edge fields like Machine Learning & AI, Network and Cloud Computing, and Business Process Automation.</h4>
                </div>
            </div>
        </div>
      </div>  
        <div className='vision pt-4 pb-5'>
            <div className='container'>
                <div className='row'>
                    <h1 className='h1 mainheading text-center'><span>Our Vision</span></h1>
                </div>
                  <div className='col-md-11 mx-auto pt-5'>
                    <div className='row'>
                      <div className='col-md-7'>
                          <div className='visionimg'>
                          <div>
                            <video className="videomainpage about_us_video"
                              style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
                              playsInline
                              loop
                              muted
                              alt="All the devices"
                              src={video_about} type="video/mp4"
                              ref={videoEl}
                            />
                          </div>
                              
                          </div>
                      </div>
                      <div className='col-md-5 respon_cl1'>
                          <div className='visionimgtext'>
                            <h3 className='h3 pb-2'>At the heart of our vision lies a commitment to not just follow the future, but to lead it!  <br></br>
                              {/* <TextSlider texts={texts} /> */}
                            </h3>
                            <p className='pb-2'>We achieve this by empowering businesses with innovative solutions that are tailored to their unique needs. Our mission is to streamline complex processes, enhance efficiency, and drive growth, ensuring our clients are always one step ahead in the digital age.</p>
                            <Learnmore />
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='happyclient pt-5 pb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={27}
                                interval={30}
                                spanContnet="+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Unique Digital Solutions</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={50}
                                interval={20}
                                spanContnet="%"
                          /></h2>
                          <p className='text-uppercase pt-3'>Increased Client Base</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={98}
                                interval={10}
                                spanContnet="%"

                          /></h2>
                          <p className='text-uppercase pt-3'>Project Success Rate</p>
                        </div>
                    </div>
                    
                </div>
            </div>
         </div>
            <div className='container mb-24'>
              <div className='row pt-5 pb-5'>
                  <div className='heading text-center'>
                      <div className='col-md-8 mx-auto'>
                        <h4 className='h1 mb-2 pt-4 whychoose'>Why <strong>Appedology?</strong></h4>
                      </div>
                  </div>
              </div>
            <div className='row pb-4 mainchoose'>
                <div className='col-md-10 mx-auto'>
                    <div className='row'>
                        <div className='col-md-6 position-relative'>
                          <div className='whyimg '>
                          </div>
                        </div>
                          <div className='col-md-6 pt-0'>
                              <div className='col-md-12 mt-4 mb-4'>
                                <div className='chooseinnerbox'>
                                  <h4 className='h5 mb-0'>Do You Have a Dream?<span className='circletext'>
                                      <img src={pngwing1} alt='dream1' className='dreamimg1' />
                                    </span></h4>
                                  <p className='lh-base'>Pet Project? App Idea? Tell Appedology your thoughts. We'll confer as long as you want. Real Talk. No strings attached.</p>
                                  </div>
                              </div>
                              <div className='col-md-12 mt-4 mb-4'>
                              <div className='chooseinnerbox'>
                                  <h4 className='h5 mb-0'>We Offer Customized Solutions For Your Business<span className='circletext'>
                                  <img src={pngwing2} alt='dream1' className='dreamimg2' />
                                    </span></h4>
                                  <p className='lh-base'>Always strategizing, designing, and developing, our experts specialize in your industry and tailor digital transformation to fit your goals.</p>
                                  </div>
                              </div>
                              <div className='col-md-12 mt-4 mb-4'>
                              <div className='chooseinnerbox'>
                                  <h4 className='h5 mb-0'>For Us, Your Work Comes First<span className='circletext'>
                                  <img src={pngwing3} alt='dream1' className='dreamimg3' />
                                    </span></h4>
                                  <p className='lh-base'>From finalizing retainers to settlements, we generate clear payment plans that are mutually agreeable. We evaluate details together.</p>
                                  </div>
                              </div>
                              <div className='col-md-12 mt-4 mb-4'>
                              <div className='chooseinnerbox'>
                                  <h4 className='h5 mb-0'>Lasting Partnerships<span className='circletext'>
                                  <img src={pngwing4} alt='dream1' className='dreamimg4' />
                                    </span></h4>
                                  <p className='lh-base'>Going beyond deployment, our returning clients frequently become affiliates, referring others to us. Why? Because they experience greater ROIs than expected.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="container">
                <OurPartners />
              </div>
              
              <Technologyused />
        </>
      
    
  );
}

export default About;




