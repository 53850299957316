import React from "react";
import "./style.css";
import icon from "../icons/service_icon.svg";

import icon1 from "../icons/process-icon1.svg";
import icon2 from "../icons/process-icon2.svg";
import icon3 from "../icons/process-icon3.svg";
import icon4 from "../icons/process-icon4.svg";
import { Link } from "react-router-dom";




const items = [{
    title: "Discovery",
    desc: "We start by understanding your goals and target audience, laying the foundation for a tailored web solution."
   
},

{

   title: "Design & Development",
    desc: "Our team brings your vision to life, creating a visually appealing and functional website."



},
{
   title: "Testing & Optimization",
      desc: "We rigorously test and optimize the website for performance, security, and user-friendliness."
},

{
    title: "Launch & Support",
    desc: "Once everything is perfect, we launch your website and provide ongoing support whenever you need it. "

}
]

function Process() {
return (
    <div className="web-dev-process">
      <div className="web-dev-header">
        <div className="lh-base">A Process Focused on Achieving Success</div>
        <div>
        Our website application development services are centered on achieving success, combining expertise and creativity to deliver outstanding digital solutions.
        </div>
      </div>
      <div className="web-process-heading pt-5 pb-5">OUR PROCESS</div>
      <div>
        <div className="process-icons mb-4">
          <div className="process-icon-container ">
            <div className="absolute" />
            <div className="process-icon-pipe" />
            <div className="process-icon-dot" />
            <img className="" src={icon1} />
          </div>

          {/* <div className="process-icon-container">
            <div className="process-icon-pipe" />
            <div className="process-icon-dot" />
            <img className="" src={icon} />
          </div> */}
          <div className="process-icon-container">
            <div className="process-icon-pipe" />
            <div className="process-icon-dot" />
            <img className="" src={icon2} />
          </div>
          <div className="process-icon-container">
            <div className="process-icon-pipe" />
            <div className="process-icon-dot" />
            <img className="" src={icon3} />
          </div>
          <div className="process-icon-container">
          <div className="absolute" />

            <div className="process-icon-pipe" />
            <div className="process-icon-dot" />
            <img className="" src={icon4} />
          </div>
        </div>
        <div className="process-items">
        {items.map(item => (
  <div className="process-item">
  <div>{item.title}  </div>
  <div>{item.desc}</div>
  <div><Link to="/contact">READ MORE</Link></div>
  </div>
        ))}
           
          
            

        </div>
      </div>
    </div>
  );
}








export default Process;
