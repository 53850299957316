import React from 'react'
import { Link } from "react-router-dom";

function Learnmore() {
  return (
    <>
        <Link className="btn btn-outline-primary mt-4 btnblack pdreadmore"  to="/contact">
            Learn More
        </Link>
    </>
  )
}

export default Learnmore