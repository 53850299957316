import React, { useState } from 'react';
import Logofooter from "./images/Footerlogo.svg";
import { Link, useLocation } from "react-router-dom";
import ScrollToTopButton from "./Scrolltop";
import "./footer.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const initalState = {
  name: "",
  email: "",
  phone: "",
  address: ""
  }



  
function Footer() {

  const [state, setState] = useState(initalState);
  const navigate = useNavigate();
  const handleChange = (ev) => {
  const {name, value} = ev.target;
  setState({...state, [name]: value})

  }




  const handleSubmit = async (ev) => {
    ev.preventDefault();
  
    const formData = new FormData();
  
    formData.append("name", state.name);
    formData.append("email", state.email);
    formData.append("phone", state.phone);
    formData.append("address", state.address);
  
  
   try {
    console.log(state);
    axios({
      method: "post",
      url: "https://contact.appedology.pk/email_form",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        setState(initalState);
        toast.success("Form submitted successfully")
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        toast.error("Something went wrong")
      });
  //   const data = await axios("https://contact.appedology.pk/email_form", state);
  //   if(data) {
  //     setState(initalState);
  //     // navigate("/")
  //     toast.success("Form submitted successfully")
  
  //   }
   }catch(error) {
    console.log(error)
    // setState(initalState);
      // navigate("/")
      // toast.success("Form submitted successfully")
     
     
      toast.error("Something went wrong")
  
   }
  
  }












  const { pathname } = useLocation();

  const defaultData = {
    subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss your project!",
  };

  const data = {
    "/game-dev": {
      subtitle: "Ready to Get Started?",
      title: "Schedule An Appointment ",
      desc: "Let's discuss your project!",
    },

    "/web-dev": {
      subtitle: "CONTACT US",
      title: "Schedule An Appointment ",
      desc: "Let's discuss your project!",
    },
    "/machine-learning-and-ai": {
      subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss your project!",
    },
    "/social-media-management": {
      subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss your project!",
    },
    "/graphic-designing-services": {
      subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss your project!",
    },

    "/content-creation": {
      subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss your project!",
    },

    "/customer-relationship-management": {
      subtitle: "GET A QUOTE ",
      title: "Schedule An Appointment",
      desc: "Let's discuss!",
    }
  };

  const dataToRender = data[pathname] || defaultData;

  if (pathname === "/contact") return null;
  return (
    <>
      <div className="schedule text-white">
        <div className="container">
          <div className="row ">
            <div className="col-md-11 col-xl-9 mx-auto">
              <div className="row align-items-center">
                <div className="col-md-6 pt-2">
                  <div className="sechedulleft">
                    <h5 className="pb-3 ps-2">{dataToRender.subtitle}</h5>
                    <h6 className="pb-4">{dataToRender.title}</h6>
                    <p className="ps-2">{dataToRender.desc}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="sechedulright">
                      <form onSubmit={handleSubmit}>
                          <div className='row pt-5'>
                            <div className='col-md-12 float-start'>
                                <div className="form-floating mb-3">
                                    <input required={true} type="text" name='name' value={state.name} onChange={handleChange} className="form-control pt-0 pb-0" id="floatingInput" placeholder="Your Name" />
                                </div>
                            </div>
                            <div className='col-md-12 float-start'>
                                <div className="form-floating mb-3">
                                    <input required={true} type="email" name='email' value={state.email} onChange={handleChange} className="form-control pt-0 pb-0" id="floatingInput" placeholder="Email Address" />
                                </div>
                            </div>
                            <div className='col-md-12 float-start'>
                                <div className="form-floating mb-3">
                                    <input required={true} type="tel" name='phone' value={state.phone} onChange={handleChange}  className="form-control pt-0 pb-0" id="floatingInput" placeholder="Phone Number" />
                                </div>
                            </div>
                            <div className='col-md-12 float-start'>
                                <div className="form-floating mb-3">
                                    <textarea required={true} name='address' value={state.address} onChange={handleChange}  className='form-control textareaown' rows="6"></textarea>
                                </div>
                            </div>
                              <div className="col-md-12">
                                <button type="submit" className="btn-bg btn btn-primary w-100 btn-block pt-3 pb-3">Submit Now</button>
                              </div>
                          </div>
                        </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="pt-5 pb-5 text-white linkfooter">
        <div className="container">
          <div className="row text-white">
            <div className="col ">
              <a
                href="/"
                className="d-flex align-items-center mb-3 link-dark text-decoration-none"
              >
                <img className="footerlogo" src={Logofooter} alt="footerlogo" />
              </a>
              <p>
                Appedology is the hub for everything digital. We provide the
                best App & Web development, Digital Marketing, and AI & ML
                solutions in Pakistan.
              </p>
            </div>

            <div className="col col-lg-2 ps-5 mobilepd">
              <h4 className="pb-4">PAGES</h4>
              <ul className="list-unstyled footer_link">
                <li> <Link to="/" className='text-white'>Home</Link></li>
                <li><Link to="/about" className='text-white'>About Us</Link></li>
                <li> <Link to="/contact" className='text-white'>Contact Us</Link></li>
              </ul>
            </div>

            <div className='col col-md-6 col-lg-3'>
              <h4 className="pb-4">Services</h4>
              <ul className='list-unstyled footer_link'>
                <li> <Link className='text-white' to={"/business-management"}>Business Management</Link> </li>
                <li> <Link className='text-white' to={"/sales-and-business-development"}>Sales & Business Development</Link> </li>
                <li> <Link className='text-white' to={"/medical-billing"}>Medical Billing & RCM</Link> </li>
                <li> <Link className='text-white' to={"/chat-support"}>Exceptional Chat Support</Link> </li>
                <li> <Link className='text-white' to={"/tailored-solutions"}>Tailored Solutions</Link> </li>
                <li> <Link className='text-white' to={"/web-dev"}>Website Development</Link></li>
              </ul>
            </div>
            <div className='col col-md-6 col-lg-3'>
              <h4 className="pb-4">Services</h4>
              <ul className="list-unstyled footer_link">
                <li> <Link to="/machine-learning-and-ai" className='text-white'>Machine Learning & AI</Link></li>
                <li><Link to="/network-and-cloud" className='text-white'>Network and Cloud Computing</Link></li>
                <li> <Link to="/social-media-management" className='text-white'>Social Media Management</Link></li>
                <li> <Link to="/graphic-designing-services" className='text-white'>Graphic Designing service</Link></li>
                <li> <Link to="/content-creation" className='text-white'>Content Creation</Link></li>
                <li> <Link to="/seo-and-marketing" className='text-white'>SEO & Marketing Services</Link></li>
                <li> <Link to="/customer-relationship-management" className='text-white'>Customer Relationship Management</Link></li>
              </ul>
            </div>
            
            <div className="col">
              <h4 className="pb-4">
                <strong>CALL US:</strong>
              </h4>
              <p className="pb-3 number">021-35187210</p>
              <h6 className="pb-2 headingsmall">Email:</h6>
              <p>sales@appedology.pk</p>
              <h5 className="pb-2 pt-3 headingsmall">Address</h5>
              <p>
                2nd Floor, 66c-68c, 25th Street, Phase 5 Tauheed Commercial
                Area, Karachi, 75500.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright pt-3 pb-3 text-center">
        <p className="text-white fw-light">
          <strong className="fw-bold">Copyrights</strong> © 2024 Appedology Inc.
          All rights reserved.
        </p>
      </div>
      <ScrollToTopButton />
    </>
  );
}

export default Footer;
