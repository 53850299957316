import React, { useEffect, useRef, useState } from 'react'
import image from "../images/image8.svg";

import image2 from "../images/image10.png";
import image3 from "../images/image11.png";
import image4 from "../images/image12.png";
import image5 from "../images/image13.png";

import image6 from "../images/Devops.png";
import image7 from "../images/things.png";


// import image2 from "../images/image8.svg";

// import image3 from "../../../pages/about/images/vision-img.png";
// import image4 from "../../../pages/about/images/about-us-img.png";
// import image5 from "../../../pages/home/images/clientglobaly.png";



// import CollapsableItem from './CollapsableItem/CollapsableItem';
// import "./style.css";
import CollapsableItem from '../../mobilAppDevelopement/GoToExpert/CollapsableItem/CollapsableItem';


const collapsableData = [{
  title: "Backup and Disaster Recovery",
  desc: "We ensure data integrity and swift recovery if something goes wrong.",

},
{
  title: "Managed Cloud Services",
  desc: "We can handle all your cloud needs so everything runs smoothly and securely."
},
{
  title: "Cloud Migration Services",
  desc: "Seamless, secure transition of your business processes and data to the cloud environment."
},
{
  title: "Data Analytics and Business Intelligence",
  desc: "Transforming data into easy-to-understand insights for informed decision-making."
},

{
  title: "DevOps as a Service",
  desc: "We facilitate continuous integration and delivery for faster, reliable software deployment."
},


{
  title: "Internet of Things (IoT) Services",
  desc: "Here's to connecting your everyday devices to the internet for smarter operations."
},


]

function GoToExpert() {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [image, image2, image3, image4, image6, image7]
  const [sideImageClass, setSideImageClass] = useState("");
  const imageRef = useRef(null);
  const [show, setShow] = useState(0);

  useEffect(() => {
   if(imageRef.current) {
    setTimeout(()=> {
      imageRef.current.style.opacity = 0;
      imageRef.current.style.display = "block";


    }, 200)
    setTimeout(()=> {
      imageRef.current.style.opacity = 1;
      // imageRef.current.style.display = "block";


    }, 300)
    // imageRef.current.style.opacity = 0;

    imageRef.current.style.display = "none";

    
   }
  }, [imageIndex, imageRef])
  return (
    <div className='go-to-main'>
        <div>
            <div className='lh-base'>Our Expertise in Cloud Computing Services For Small Businesses & Large Enterprises </div>
            <div>Providing performance-oriented services to a wide range of clients across verticals.</div>
            <div className='collapsable-items'>
                {collapsableData.map((item, index) => 
                  
              <CollapsableItem show={show} setShow={setShow} setImageIndex={setImageIndex} item={item} index={index}  />
                  
                  )}
                
            </div>
        </div>
        {images[imageIndex] && 
        
        <img ref={imageRef}  className={sideImageClass} src={images[imageIndex]} />
        }
    </div>
  )
}

export default GoToExpert