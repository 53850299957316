import "./style.css";
import icon from "./icons/left-1.png";
 import icon1 from "./icons/left-2.png";
 import icon5 from "./icons/left-5.png";
import icon4 from "./icons/left-3.png";
import icon6 from "./icons/left-4.png";
import icon7 from "./icons/left-6.png";
import icon8 from "./icons/right-1.png";
 import icon9 from "./icons/right-2.png";
 import icon10 from "./icons/right-3.png";
 import icon11 from "./icons/right-4.png";
 import icon12 from "./icons/right-5.png";
 import icon13 from "./icons/right-6.png";
//  import icon15 from "./icons/icon5.png";
//  import icon16 from "./icons/icon4.png";
//  import icon17 from "./icons/icon6.png";
//  import icon18 from "./icons/icon8.png";





import bigIcon from "./icons/big-icon.png";
import bgGradient from "./icons/bg-gradient.png";

function TechStack({title, desc}) {
  return (
    <div className="tech-stack">
        <div className="content">
        <div>{title}</div>
      <div>
        {desc}
      </div>
        </div>
      <div className="icons">
        <div className="left-icons">
          <div>
            <img src={icon4} />
          </div>
          <div>
            <img className="html5" src={icon1} />
            <img src={icon5} />
          </div>
          <div className="icon4stack">
            <img className="html5" src={icon} />
            <img src={icon6} />
            <img src={icon7} />
          </div>
        </div>
        <div
          className="big-icon-container"
        style={{
          backgroundImage: `url(${bgGradient})`,
          "background-size":     "cover",                     
          "background-repeat":   "no-repeat",
          "background-position": "center center" 
        }}>
        <img className="big-icon bigsicon" src={bigIcon} />

        </div>
        <div className="right-icons">
        <div>
            <img className="html5" src={icon8} />
            <img src={icon11} />
            <img src={icon13} />
          </div>
          <div>
            <img src={icon9} />
            <img src={icon12} />
          </div>
          <div>
            <img src={icon10} />
          </div>
         
          
        </div>
      </div>
    </div>
  );
}

TechStack.defaultProps = {
  title: "Mobile app development technology stack",
  desc: "With a rich palette of major technologies at our fingertips, we ensure versatile, efficient, and future-proof solutions across various industry domains"
}

export default TechStack;
