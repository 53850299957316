import React from 'react'
import icon from "../../../images/item_icon.svg"
import "./style.css";

function Item({item}) {
  
  return (
    <div className={` col-sm-6 col-lg-4  ${item.column ? "col-xl-3" : ""} item_ mb-4 d-flex justify-content-center`} >
        <div style={{minHeight: "480px"}} className={`py-5 px-3 h-100 item ${item.class || ""}`}>
          {item.icon && <img className='img-not-active' src={item.icon} />}
          {!!(item.activeIcon || item.icon )&& <img className='img-active' src={item.activeIcon || item.icon} />}

          <div className='prem-item-title my-2'>{item.title}</div>
          <div>{item.desc}</div>
        </div>
        
    </div>
  )
}

export default Item