import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";

const Clickableservice = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const boxRef = useRef(null);

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsClicked(!isClicked);
    setIsActive(!isActive); // Toggle the state on each click
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the mega menu and button
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target) &&
        !event.target.classList.contains('mega-menu-button-mobile')
      ) {
        setIsMenuOpen(false);
        setIsClicked(false);
      }
    };

    // Attach the click event listener to the body
    document.body.addEventListener('click', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen, isClicked]);

 


 
  //const [isClicked, setIsClicked] = useState(false);

  


  return (
    <div className="mega-menu-container">
        <li>Services</li>
      <button className={isActive ? 'mega-menu-button-mobile' : 'mega-menu-button-mobile active_toggle_arrow'}  onClick={toggleMenu}>
          
      </button>

      {isMenuOpen && (
        
        <div className="mega-menu-content pb-5" ref={boxRef}>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>
                    <div className='menucontent definemegamenu'>
                        <h4 className='h5'>Our Services</h4>
                        <p className='lh-base'>Harnessing state-of-the-art technologies to craft pioneering digital solutions for our BPO technology company</p>
                    </div>
                </div>
                <div className='col-md-9 pt-4'>
                  <div className='row'>
                    <div className='col-md-4'>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                <h4 className='h6 mb-4'>BPO</h4>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/business-management">
                                          <li>Business Management</li>
                                          <span>Planning, Organizing, Leading, and Achieving</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/sales-and-business-development">
                                          <li>Sales & Business Development</li>
                                          <span>Selling, Expansion, and Relationships</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/medical-billing">
                                          <li>Medical Billing & RCM</li>
                                          <span>Coding, Reimbursement, and Compliance</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/chat-support">
                                          <li>Exceptional Chat Support</li>
                                          <span>Responsive, Knowledgeable, and Proactive</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/tailored-solutions">
                                          <li>Tailored Solutions</li>
                                          <span>Customized, Adaptive, and Personalized</span>
                                      </NavLink>
                                  </ul>
                            </div>
                          </div>
                      </div>
                      {/* end first link mega menu col */}
                      <div className='col-md-4'>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                <h4 className='h6 mb-4'>TECH</h4>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/mobile-app-dev">
                                          <li>Mobile App Development</li>
                                          <span>IOS and Android </span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        {/* <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/game-dev">
                                          <li>game Development</li>
                                          <span>IOS, andriod, TV, and wearables </span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div> */}
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/web-dev">
                                          <li>Website Development</li>
                                          <span>PHP and wordpress development</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/machine-learning-and-ai">
                                          <li>Machine Learning & AI</li>
                                          <span>Automation, Prediction, Optimization</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/network-and-cloud">
                                          <li>Network and Cloud Computing</li>
                                          <span>Connectivity, Scalability, Virtualization</span>
                                      </NavLink>
                                  </ul>
                            </div>
                          </div>
                      </div>
                      {/* end second menga menu */}
                      <div className='col-md-4'>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                <h4 className='h6 mb-4'>DIGITAL</h4>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/social-media-management">
                                          <li>Social Media Management</li>
                                          <span>Engagement, Content, Analytics</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/graphic-designing-services">
                                          <li>Graphic Designing service</li>
                                          <span>Creativity, Innovation, Aesthetics</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/content-creation">
                                          <li>Content Creation</li>
                                          <span>Storytelling, Engagement, Originality</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/seo-and-marketing">
                                          <li>SEO & Marketing Services</li>
                                          <span>Optimization, Visibility, Strategy</span>
                                      </NavLink>
                                  </ul>
                            </div>
                        </div>
                        <div className='col-md-12 pt-2 pb-2'>
                            <div className='menucontent'>
                                  <ul className='mengamenuul'>
                                      <NavLink to="/customer-relationship-management">
                                          <li>Customer Relationship Management</li>
                                          <span>Personalization, Engagement, Retention</span>
                                      </NavLink>
                                  </ul>
                            </div>
                          </div>
                      </div>
                    </div>
                  {/* end row colum */}
                  
                </div>
            </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default Clickableservice;
