import './App.css';


function Faq() {
  return (
    <div className='pagesdesign'>
        <div className='container'>
          <div className='centerpages'>
            <h3 className='h1 text-uppercase'>this is a FAQs page</h3>
          </div>
        </div>
    </div>
  );
}

export default Faq;
