import React from "react";
import TopSection from "../../components/TopSection";
import "./style.css";
import Item from "../mobilAppDevelopement/OurFeatured/Item";
import image from "./images/image.png";
import { Link } from "react-router-dom";

// import image2 from "./images/image2.svg";
// import image3 from "./images/image3.svg";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.svg";
import image5 from "./images/image5.svg";

import image6 from "./images/image6.png";
import {Helmet} from "react-helmet";



import PremiumExpetise from "../mobilAppDevelopement/PremiumExpertise/PremiumExpetise";
import serviceIcon from "./icons/service_icon.svg";
import serviceIcon2 from "./icons/service_icon2.svg";
import serviceIcon3 from "./icons/service_icon3.svg";
import serviceIcon4 from "./icons/service_icon4.svg";
import serviceIcon5 from "./icons/service_icon5.svg";
import serviceIcon6 from "./icons/service_icon6.svg";
import OurPartners from "../mobilAppDevelopement/OurPartners/OurPartners";
import TechStack from "./techstack/TechStackCloud.jsx";
import AutoCounter from "../about/component/Countdown1";


import GoToExpert from "../mobilAppDevelopement/GoToExpert/GoToExpert";
import Process from "./Process";
import rightArrowIcon from "./icons/right-arrow.svg";



import bgSideIcon1 from "../images/bg_side_icon1.png"
import bgSideIcon2 from "../images/bg-side-icon2.png"
import bgSideIcon3 from "../images/bg-side-icon3.png"



import activeIcon1 from "./icons/active-icon1.png";
import activeIcon2 from "./icons/active-icon2.png";
import activeIcon3 from "./icons/active-icon3.png";
import activeIcon4 from "./icons/active-icon4.png";
import activeIcon5 from "./icons/active-icon5.png";
import activeIcon6 from "./icons/active-icon6.png";









const data = [
  {
    className2: "web-dev-featured-item-content-right",
    className: "-ml-12",
     sideIconRight: "", // bgSideIcon2   
    title: "Full-Scale Mastery",
    image: image6,
    desc: "Appedology offers full-scale website development in Pakistan, offering a comprehensive suite of services tailored to your digital needs.",
    descexv2: "From the initial concept to the final launch, our website developers in Pakistan navigate every step. We specialize in creating dynamic, user-friendly websites and applications that not only look stunning but also perform flawlessly.",
    descexv3: "Our commitment to using the latest technologies ensures your project is not just current but future-ready."
  },
  {
    sideIconLeft: bgSideIcon1,   
    image: image3,
    flip: "flip",
    className2: "web-dev-featured-item-content-left",
    title: "Our Web Development Excellence",
    desc: "For over 15 years, Appedology has been at the forefront of website design and development services.",
    descexv2: "Our custom website development services are infused with the latest technological advancements. Whether it's engaging customer-facing web apps, efficient internal management portals, or cutting-edge e-commerce stores, our expert team handles it all.",
    descexv3: "We pride ourselves on delivering top-notch quality websites with a clear, efficient development process, ensuring every project stands out in the digital landscape."
  },

  {
    className2: "web-dev-featured-item-content-right",

    sideIconRight: bgSideIcon2,   
    image: image2,
    title: "Quality Promise",
    desc: "Quality is our cornerstone at Appedology. Our website design & development services process begins with a deep understanding of your needs, followed by meticulous planning and design.",
    descexv2: "We employ the latest technologies and follow industry best practices to build robust, scalable web solutions. Our dedicated QA team rigorously tests each project for functionality, performance, and security, ensuring flawless user experiences.",
    descexv3: "Regular client feedback loops and agile methodologies enable us to refine and perfect our work, delivering exceptional quality in every web development service."
    
  },

  {
    className2: "web-dev-featured-item-content-left",
    sideIconLeft: bgSideIcon1,   
    image: image3,
    flip: "flip",
    title: "Advanced Web Tech",
    desc: "Embrace the Future of Web Technology with Us! At Appedology, we're not just developers; we're pioneers in digital transformation.",
    descexv2: "Our expertise lies in seamlessly integrating the latest innovations into your web solutions, ensuring they're not just advanced but also impactful and measurable.",
    descexv3:"Let us elevate your digital presence with technology that sets you apart in the digital arena."
  },
];

const serviceData = [
  {
    icon: serviceIcon,
    activeIcon: activeIcon1,
    title: "WordPress Development",
    desc: "Specializing in custom WordPress sites, we offer tailored themes, plugins, and scalable solutions for a user-friendly experience.",
  },

  {
    icon: serviceIcon2,
    activeIcon: activeIcon2,
    title: "Shopify E-commerce Solutions",
    desc: "Expertly crafting Shopify stores, we provide bespoke designs, seamless shopping carts, and effective e-commerce strategies for online retail success.",
  },

  {
    icon: serviceIcon3,
    activeIcon: activeIcon3,
    title: "Drupal Development",
    desc: "Our team excels in Drupal development, offering robust, secure, and scalable solutions for complex websites and applications.",
  },

  {
    icon: serviceIcon4,
    activeIcon: activeIcon4,
    title: "Magento Ecommerce Development",
    desc: "We create powerful Magento-based online stores, featuring custom functionalities, integrations, and optimized for high performance and user engagement.",
  },

  {
    icon: serviceIcon5,
    activeIcon: activeIcon5,
    title: "Joomla Website Development",
    desc: "Building dynamic, responsive websites with Joomla, we ensure a blend of aesthetic appeal and functional excellence.",
  },

  {
    icon: serviceIcon6,
    activeIcon: activeIcon6,
    title: "Squarespace Website Design",
    desc: "Offering creative and visually striking Squarespace designs, we tailor each site to enhance brand identity and ensure ease of use.",
  },
];

function WebDev() {

return (

    <>

              <Helmet>
                <meta charSet="utf-8" />
                <title>Website Development Services - Appedology</title>
                <meta name="description" content="Let your website speak for you! Our website design and development services ensure innovative designs, seamless functionality, and responsive support!" />
                <link rel="canonical" href="https://appedology.pk/web-dev" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Website Development Services - Appedology" />
                <meta property="og:description" content="Let your website speak for you! Our website design and development services ensure innovative designs, seamless functionality, and responsive support!" />
                <meta property="og:url" content="" />
                <meta property="og:locale" content= "pakistan" />
                <meta property="og:site_name" content="Appedology" />
                <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>




    <div className="web-dev">
      <TopSection
        subtitle="Web Development"
        title="FROM CONCEPTUALIZING TO POST-DEPLOYMENT SUPPORT,WE ARE WITH YOU ALL THE WAY"
        desc="Elevate your online presence with our expert web development services. Custom designs, seamless functionality, and responsive support!"
        image={image}
      />
      <div className="our-featured position-relative">
        <div className="mb-5">
          <div className="mt-5">No One Ever Skipped a Fast-Loading Website</div>
          <p className="mt-4 mb-2">
            If your site takes too long to load, Appedology is your solution. We optimize website speed to ensure your pages load instantly, keeping visitors engaged and reducing bounce rates.
          </p>
          <p>Trust us to deliver a seamless, fast-loading web experience that captures and retains your audience's attention.</p>
          <img className={`position-absolute end-0`} style={{marginTop : "-100px", maxWidth:"140px", }} src={bgSideIcon2} alt="" />

        </div>
        <div className="web-dev-featured-items">
          {data.map((item) => (
            <div className="container-fluid">

              <div className={`web-dev-featured-item- row mt-5 pt-5 mx-0- ${item.flip ? "flip" : ""}`}>
                <div className={`px-0 featured-item ${item.flip ? "col-lg-5 order-lg-2 flip-div" : "col-lg-6"}`}>
                {item.image && (
                  <img className={`w-100 ${item.className || ""}`} src={item.image} alt="" />
                )}
                </div>
                <div className={` d-flex items-center col-lg-5 ${item.flip ? " offset-lg-2 order-lg-1" : ""}`}>
                  <div className={`web-dev-featured-item-content pt-0 ${item.className2}`}>
                      {item.sideIconLeft && <img className={`position-absolute 1 ${item.flip ? "start-0" : ""}`} style={{marginTop:"200px"}} src={item.sideIconLeft} />}
                      <div>
                        <p className="web-dev-featured-item-title">{item.title}</p>
                        <p className="web-dev-featured-item-desc mb-4">{item.desc}</p>
                        <p className="web-dev-featured-item-desc mb-4">{item.descexv2}</p>
                        <p className="web-dev-featured-item-desc">{item.descexv3}</p>
                      </div>
                    {item.sideIconRight && <img className={`position-absolute 2 ${item.flip ? "" : "end-0"}`} style={{ maxWidth:"140px", }} src={item.sideIconRight} />}

                  </div>
                </div>
                
              </div>

            </div>
          ))}
        </div>
      </div>

      <div className="web-dev-our-services pt-5 mt-5">
        <div className="pt-2 pb-5">
          <h1 className="h1 mainheading in-web-dev services-heading text-center position-relative ">
            <span className="ps-5 pe-5">Our Website Development Services </span>
          </h1>
          <p className="text-center h5">Specialized for Every Web Need</p>
        </div>
        <div className="webdevpremium hidepara1">
          <PremiumExpetise
            title="Lead Your Brand to Digital Dominance"
            desc="Secure your brand's position at the top of the digital ecosystem. Our expertise in web development and digital strategy ensures your brand outshines the competition.' "
            
            items={serviceData}
          />
        </div>
      </div>
          <div className="container">
            <OurPartners />
          </div>
      <TechStack
        title="Web Development Tech Stack"
        desc="We build websites with a modern toolkit for faster performance, user-friendly design, and scalability."
      />

      <div className="happyclient pt-4 pb-4 mb-14">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="client text-center">
                <h2 className="timecounter">
                 24/7
                </h2>
                <p className="text-uppercase pt-3">Technical Support</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client text-center">
                <h2>
                  <AutoCounter limit={2000} interval={1} spanContnet="+" />
                </h2>
                <p className="text-uppercase pt-3">Successful Projects</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client text-center">
                <h2>
                  <AutoCounter limit={50} interval={20} spanContnet="+" />
                </h2>
                <p className="text-uppercase pt-3">Skilled Developers</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GoToExpert
        title="Web Development Services"
        desc="Crafting stunning websites tailored to meet the unique needs of every industry, we guarantee online success, regardless of your business niche!"
        data={[
          {
            title: "E-commerce",
            desc: "Ready to turbocharge your online sales? We offer the best ecommerce website development in Pakistan. Our sites are known for seamless shopping experiences and secure & smooth payment processing.",
          },

          {
            title: "Healthcare",
            desc: "Streamline your medical practice effortlessly. Our custom web solutions simplify patient engagement and healthcare services.",
          },

          {
            title: "Nonprofits",
            desc: "Our websites tell captivating stories, amplifying your impact. Supporters easily connect and contribute online.",
          },

          {
            title: "Real Estate",
            desc: "Stand out in the real estate market with visually stunning websites. Explore properties with dynamic listings and immersive virtual tours.",
          },

          {
            title: "Manufacturing",
            desc: "Our websites showcase your products and industry expertise, propelling your business to new heights.",
          },
        ]}
      />
      <Process />

      <div className="maximize-value">
        <div className="maximize-content">
          <div className="mximize-title">Get Started Today!</div>
          <div className="mximize-desc lh-base">
            Ready to transform your online presence and achieve digital success?
            Let's start building your dream website today!
          </div>
          <div className="mximize-btn">
            <Link to="/contact"><span>Get Skilled Developers</span>
                <i class='bx bx-chevron-right'></i>
            </Link>
          </div>
        </div>
        <div className="maximize-value-images">
          <img src={image4}  />
          <img src={image5}  />
        </div>
      </div>
    </div>
    </>
  );
}

export default WebDev;
