import React, { useState } from 'react'
import "./style.css";


function CollapsableItem({item, index, show, setShow, setImageIndex}) {
   

    const handleClick = () => {
        setShow(index);
        setImageIndex(index);
    }
 
    return (
        <div className='collapsable-item'>
            <div className={show === index ? "active" : ""} onClick={handleClick} >{item.title}</div>
            <div className={show === index ? 'show' : 'hide'}>{item.desc}</div>
        </div>
    ) 
}

export default CollapsableItem