import React, { Fragment } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './css/reset.css';
import Header from './header/Header'
import Footer from './footer/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
// import Portfolio from './pages/portfolio/Portfolio';
import Blog from './pages/blog/Blog';
import Services from './pages/Services';
import Faq from './pages/faq/Faq';
import Solutions from './pages/solutions/Solutions';
import Contact from './pages/contactus/Contact';
import reportWebVitals from './reportWebVitals';
import MobileAppDev from './pages/mobilAppDevelopement';

import "./index.css";
import WebDev from './pages/webDev';
import GameDev from './pages/gameDev';
import NetworkAndCloud from './pages/NetworkAndCloud';
import MedicalBilling from './pages/medicalBilling';
import ChatSupport from './pages/chatSupport';
import TailoredSolutions from './pages/tailoredSolutions';
import ContentCreation from './pages/ContentCreation';
import SEO from './pages/seo';
import CustomerRelation from './pages/customerRelation';
import SocialMedia from './pages/socialMedia';
import GraphicDesign from './pages/graphicDesign';
import SalesAndBusinessDevelopment from './pages/sales';
import BusinessManagment from './pages/BusinessmManagement';
import AI from './pages/AI';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './hooks/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ToastContainer />

    <BrowserRouter>
      <ScrollToTop />
      <Header />

      <Routes>
      {/* <Fragment  > */}
          <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        {/* <Route path="portfolio" element={<Portfolio />} /> */}
        <Route path="solutions" element={<Solutions />} />
        <Route path="blog" element={<Blog />} />
        <Route path="services" element={<Services />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="/mobile-app-dev" element={<MobileAppDev />} />
        <Route path="/web-dev" element={<WebDev />} />
        <Route path="/game-dev" element={<GameDev />} />
        <Route path="/network-and-cloud" element={<NetworkAndCloud />} />
        <Route path="/medical-billing" element={<MedicalBilling />} />
        <Route path="/chat-support" element={<ChatSupport />} />
        <Route path="/tailored-solutions" element={<TailoredSolutions />} />
        <Route path="/content-creation" element={<ContentCreation />} />
        <Route path="/seo-and-marketing" element={<SEO />} />
        <Route path="/customer-relationship-management" element={<CustomerRelation />} />
        <Route path="/social-media-management" element={<SocialMedia />} />
        <Route path="/graphic-designing-services" element={<GraphicDesign />} />
        <Route path="/sales-and-business-development" element={<SalesAndBusinessDevelopment />} />
        <Route path="/business-management" element={<BusinessManagment />} />
        <Route path="/machine-learning-and-ai" element={<AI />} />
      {/* </Fragment > */}

      </Routes>
      <Footer />
    </BrowserRouter>


  </React.StrictMode>

);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
