import { TabPanel } from '@mui/lab'
import React, { useEffect } from 'react'
import arrowRight from "../../medicalBilling/icons/arrow_right.svg";

function Tab({el, index}) {
    // useEffect(() => {
    //     setTimeout(() )
    // }, [])

  return (
    <TabPanel
             className='ai-tab'
             value={String(index+1)}>
                 <div className='ai-tab-contnet'>
                     <div>
                       <div className='ai-tab-contnet-text'>{el.desc}</div>
                       {/* <button className="learn-more-btn2">
                     Learn more
                     <img src={arrowRight} />
                   </button> */}
                     </div>
                     <img className='ai-tab-image' src={el.image} />
                 </div>
             </TabPanel>
  )
}

export default Tab