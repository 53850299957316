import React, { useState, useEffect } from 'react';

const HalfScrollCounterthree = () => {
  const [count, setCount] = useState(1);
  const [hasScrolledHalfway, setHasScrolledHalfway] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the total scroll height of the document
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;

      // Get the current scroll position
      const scrollPosition = window.scrollY;

      // Check if the scroll position is greater than or equal to half of the document height
      if (scrollPosition >= totalHeight / 2 && !hasScrolledHalfway) {
        setHasScrolledHalfway(true);
      }
    };

    const countInterval = setInterval(() => {
      // Increment the count every 100 milliseconds
      if (hasScrolledHalfway && count < 7) {
        setCount((prevCount) => prevCount + 1);
      }
    }, 1);

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and interval on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(countInterval);
    };
  }, [count, hasScrolledHalfway]);

  return (
    <div className="counter-container">
      <p>{count} + </p>
    </div>
  );
};

export default HalfScrollCounterthree;
