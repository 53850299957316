import React, { Component } from "react";
import Sliderlogo from "react-slick";
import Logo1 from '../images/logo-slider/logo-1.png';
import Logo2 from '../images/logo-slider/logo-2.png';
import Logo3 from '../images/logo-slider/logo-3.png';
import Logo4 from '../images/logo-slider/logo-4.png';
import Logo5 from '../images/logo-slider/logo-5.png';
import Logo6 from '../images/logo-slider/logo-6.png';
import Logo7 from '../images/logo-slider/logo-7.png';

export default class PauseOnHover extends Component {
  render() {
    var settings = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      responsive: [{
        breakpoint: 1000,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
        }
    }, {
        breakpoint: 860,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }, {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }]
    };
    return (
      <div className="pb-5 logoslider container-fluid">
        <div className="row pt-4">
            <h3 className="text-uppercase h2 text-center pt-3 pb-4">Our tech stack</h3>
            <div className="col-md-12 mx-auto">
            <Sliderlogo {...settings}>
                
                <div>
                    <img src={Logo7} alt="Logo" className='img-fluid' />
                </div>

                <div>
                    <img src={Logo1} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo2} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo3} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo4} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo5} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo6} alt="Logo" className='img-fluid' />
                </div>
                <div>
                    <img src={Logo1} alt="Logo" className='img-fluid' />
                </div>
            </Sliderlogo>
        </div>
        </div>
      </div>
    );
  }
}