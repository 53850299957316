import React from 'react'

function Solutions() {
  return (
        <div className='pagesdesign'>
            <div className='container'>
            <div className='centerpages'>
                <h3 className='h1 text-uppercase'>this is a Solutions page</h3>
            </div>
            </div>
        </div>
  )
}

export default Solutions