import React from "react";
import Slider from "react-slick";
import Logo from "../../images/Logo";

export default function PartnersSaying({items = [], title = "What Our Partners Are Saying"}) {
    var settings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
   
      };
      
      console.log({items});

      return (
    
    <div className="partners-saying">
    <Logo />
    <div className="partners-saying-title">
      {title}
    </div>
    <Slider className="slick-social-media" {...settings}>

  

{items.map(el => {
    return <div className="partners-saying-slide-item">
    <div className="partners-saying-desc">
            {el.text}
            </div>
            <div className="partners-saying-user">
              {el.user}
            </div>
    </div>
})}
      
    </Slider>
    
   
  </div>
    
  );
}