import React, { useState } from 'react';
import { NavLink } from "react-router-dom";


const MegaMenu = ({setIsHovered, isHovered}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsClicked(!isClicked);
  };

 
  return (
    <div
      className="mega-menu-container"
      onMouseEnter={handleMouseEnter}
      
    >
      <button className="mega-menu-button">
        Services <span className={`${isClicked ? 'btnicon_servicelink' : ''}`}><i class='bx bx-chevron-down'></i></span>
      </button>
    
    </div>
  );
};

export default MegaMenu;
