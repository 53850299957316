import "./style.css";

import TopSection from "../../components/TopSection";
import image from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";


import tabs1 from "./images/tabs1.png";
import tabs2 from "./images/tabs2.png";
import tabs3 from "./images/tabs3.png";
import tabs4 from "./images/tabs4.png";
import tabs5 from "./images/tabs5.png";


import think from "./images/image6.png";

// import image4 from "./images/image4.jpg";
// import image5 from "./images/image5.jpg";
// import image6 from "./images/image6.jpg";
// import image13 from "./images/image6.jpg";
// import image14 from "./images/image14.png";
// import image15 from "./images/image15.png";
// import image16 from "./images/image16.png";
// import image17 from "./images/image17.png";
// import image18 from "./images/image18.png";
// import image19 from "./images/image19.png";

import icon from "../ContentCreation/icons/icon.png";
import icon2 from "../ContentCreation/icons/icon2.png";
import icon3 from "../ContentCreation/icons/icon3.png";
import icon4 from "../ContentCreation/icons/icon4.png";
import icon5 from "../ContentCreation/icons/icon5.png";
import icon6 from "../ContentCreation/icons/icon6.png";
import icon7 from "../ContentCreation/icons/icon7.png";
import icon9 from "./icons/icon.svg";


import Learnmore from "../BusinessmManagement/component/Learnmore";


import icon10 from "./icons/icon2.png";
import icon11 from "./icons/icon3.png";
import icon12 from "./icons/icon4.png";
import icon13 from "./icons/icon5.png";
import icon14 from "./icons/icon6.png";
import icon15 from "./icons/icon7.png";
import icon16 from "./icons/icon8.png";
import icon17 from "./icons/icon9.png";






import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.png";
import brand5 from "../images/brand5.png";
import brand6 from "../images/brand6.png";
import {Helmet} from "react-helmet";

import AutoCounter from "../about/component/Countdown1.js";

import arrowRight from "../ContentCreation/icons/arrow_right.svg";
import headPhone from "../ContentCreation/icons/headset_mic.svg";
import Logo from "../../images/Logo.jsx";
import PartnersSaying from "../../components/ParnersSying/index.jsx";
import OurServices from "../../components/OurServices/index.jsx";

import bgSideIcon2 from "../images/bg-side-icon2.png"

function CustomerRelation() {
  return (

    <>
       <Helmet>
          <meta charSet="utf-8" />
          <title>Master Client Relations with Appedology</title>
          <meta name="description" content="Deepen client relationships and improve customer satisfaction rates with our tailored CRM solutions! ✔Interaction tracking ✔Customer insights ✔Data security" />
          <link rel="canonical" href="https://appedology.pk/customer-relationship-management" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Master Client Relations with Appedology" />
          <meta property="og:description" content="Deepen client relationships and improve customer satisfaction rates with our tailored CRM solutions! ✔Interaction tracking ✔Customer insights ✔Data security" />
          <meta property="og:url" content="" />
          <meta property="og:locale" content= "pakistan" />
          <meta property="og:site_name" content="Appedology" />
          <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
      </Helmet>
    <div className="content-creation">
      <TopSection
        subtitle="CRM Consulting Companies"
        title="Master Client Relations with Appedology"
        image={image}
      />

      <div className="content-creation-content">
        <div className="medical-billing-intro pt-4 pb-4">
          <div className="medical-billing-intro-title">
            Personalized Services, <span>Streamlined Operations</span>
          </div>
          <div className="medical-billing-intro-desc">
            We bridge the gap in customer relations and synergize with your
            existing CRM to improve client satisfaction and deepen
            relationships, fostering a culture of excellence and long-lasting
            customer connections.
          </div>
        </div>

        <div className="happyclient pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={95} interval={10} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">Client Satisfaction</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={30} interval={30} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">More Referrals</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={90} interval={10} spanContnet="M+" />
                  </h2>
                  <p className="text-uppercase pt-3">Quicker Response</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="m-our-services in-content-creation">
          <div className="m-our-services-title in-content-creation">
            What do you get when you use the no. 1 graphic designing platform??
          </div>
          <div className="m-our-services-items">
            <div className="m-our-services-item">
              <img src={icon} />
              <div>Claim Management</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon2} />
              <div>Eligibility Verification</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon3} />
              <div>Medical Coding</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon4} />
              <div>Compliance Support</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon5} />
              <div>AR Management</div>
            </div>
          </div>

          <div className="m-our-services-content">
            <div>
              <ul>
                <li>Defining Sales Goals and Objectives</li>
                <li>Understanding the Target Market</li>
                <li>Creating a Value Proposition</li>
                <li>Choosing Sales Channels</li>
                <li>Developing Sales Processes and Techniques</li>
                <li>Training and Equipping the Sales Team</li>
              </ul>
              <button className="learn-more-btn2">
                Learn more
                <img src={arrowRight} />
              </button>
            </div>
            <img src={image3} />
          </div>
        </div> */}

        <OurServices
          additionalClass="in-content-creation"
          title="Why Choose Appedology for CRM and Customer Service"
          icons={[
            {
              icon: icon,
              title: "Expert Team",
            },

            {
              icon: icon2,
              title: "Customized Solutions",
            },
            {
              icon: icon3,
              title: "Seamless Integration",
            },
            {
              icon: icon4,
              title: "Reliable Support",
            },
            {
              icon: icon5,
              title: "Results Driven",
            },
          ]}
          images={[tabs1, tabs2, tabs3, tabs4, tabs5]}
          items={[
            [
              "Industry Experience",
              "Tailored Strategies",
              "Proven Expertise",
              "Innovative Thinking",
              "Customer Focused",
            ],

            [
              "Client Specific",
              "Flexible Integration",
              "Advanced Technology",
              "Scalable Systems",
              "Continuous Updates",
            ],
            [
              "Easy Setup",
              "CRM Compatibility",
              "Data Synchronization",
              "Workflow Efficiency",
              "Real-time Updates",
            ],

            [
              "24/7 Assistance",
              "Quick Response",
              "Knowledgeable Staff",
              "Continuous Training",
              "Problem Solving",
            ],

            [
              "Measurable Success",
              "Performance Metrics",
              "Goal Oriented",
              "Strategic Planning",
              "Client Satisfaction",
            ],
          ]}
        />
      </div>

      <div className="brands-container">
        <div className="title1">200k+ brands trust Appedology</div>

        <div className="brands-icons">
          <img src={brand1} />
          <img src={brand2} />
          <img src={brand3} />
          <img src={brand4} />
          <img src={brand5} />
          <img src={brand6} />
        </div>
      </div>

      <div className="why-use-content">
        <div className="title-with-befor-and-after in-content-creation">
          <span>Day-to-Day Tasks Management </span>
        </div>

        <div className="">
          <div>
            <div className="digitaljourpara">
              At Appedology, managing day-to-day client relation activities is
              streamlined whether through your CRM or other methods. We begin by
              understanding your unique client needs and tailor our approach
              accordingly. Our team expertly navigates your CRM system, ensuring
              all client information is up-to-date and easily accessible.
            </div>
            <div className="digitaljourpara">
              Our team offers full-cycle CRM consulting services, from emails to
              calls, maintaining consistent and positive interactions.
              Additionally, we monitor client feedback and concerns, providing
              timely responses and solutions. Even if you don’t have a CRM, our
              organized methods ensure no detail is missed, keeping client
              relations smooth and efficient. This hands-on approach fosters
              strong, lasting relationships, vital for your business growth and
              customer satisfaction.
            </div>
            {/* <Learnmore /> */}
          </div>
          <img src={image3} />
        </div>
      </div>

      <div className="position-relative">
      <div className="effectoive-designing">
        <div className="effectoive-designing-title title2">
          Appedology’s Advantage
        </div>
        <div className="effectoive-designing-items">
          <div className="effectoive-designing-item">
            <div><img src={icon10} /></div>
            <div>Enhanced Operations</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon11} /></div>
            <div>Interaction Tracking</div>
          </div>
          <div className="effectoive-designing-item">
            <div><img src={icon12} /></div>
            <div>Improved Efficiency</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon13} /></div>
            <div>Customer Insights</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon14} /></div>
            <div>Sales Increase</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon15} /></div>
            <div>Targeted Marketing</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon16} /></div>
            <div>Improved Service</div>
          </div>

          <div className="effectoive-designing-item">
            <div><img src={icon17} /></div>
            <div>Data Security</div>
          </div>
        </div>
        {/* <button className="learn-more-btn">LEARN MORE</button> */}
      </div>
      <img className={`position-absolute top-0 bottom-0 end-0 my-auto`} style={{ maxWidth:"140px" }} src={bgSideIcon2} alt="" />
      </div>

      <div className="why-choose-content-creation">
        <div className="title2">One Hub for All Client Interactions</div>
        <div className="why-choose-content-creation-content">
          <img src={think} />
          <div>
            <div className="digitaljourpara">
              Think of our service as a unifying force for all your customer
              interactions. Whether it's an email, a phone call, a message from
              your website, or a live web chat, we bring everything together in
              one place through seamless integration with your CRM system.
            </div>
            <div className="digitaljourpara">
              This means all your customer communications are not just
              collected, but also organized and easily accessible in one central
              hub. It's like having a virtual meeting room where every
              conversation, question, or feedback from your clients is available
              at a glance. Our CRM integration services simplify your client
              management, ensuring you have a complete, clear view of every
              interaction, making it easier to respond effectively and build
              stronger relationships.
            </div>
          </div>
        </div>
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Real-Time Data & Insights
          </div>
          <div className="digitaljourpara">
            Our client relation services, integrated with your CRM, offer the
            advantage of real-time data and insights. This means you get
            up-to-the-minute information about client interactions, preferences,
            and behaviors.
          </div>
          <div className="digitaljourpara">
            Whether it's a recent conversation, transaction, or feedback, you
            have immediate access to all client data. This instant access
            enables you to make informed decisions quickly, tailor your
            strategies to meet client needs, and stay ahead of market trends.
            With real-time insights, you can anticipate client requirements,
            address issues proactively, and seize opportunities as they arise,
            ensuring your business remains agile and responsive.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image3} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Smooth Collaboration
          </div>
          <div className="digitaljourpara">
            Smooth collaboration is a cornerstone of our client relation
            services, making us one of the best CRM companies in Pakistan. By
            syncing seamlessly with your CRM, we facilitate effortless teamwork
            within your organization. Our system ensures that every team member,
            from sales to customer support, has access to the same updated
            client information.
          </div>
          <div className="digitaljourpara">
            This uniformity in data prevents communication gaps and overlapping
            efforts, leading to more efficient and coherent client management.
            Whether it's sharing insights, dividing tasks, or updating client
            records, our services streamline these processes. The result is a
            harmonious work environment where collaboration is not just
            encouraged but is also made easy, enhancing the overall client
            experience.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Enhanced Customer Understanding
          </div>
          <div className="digitaljourpara">
            Our services enrich your CRM with deep insights into your customers'
            needs and preferences. By analyzing data from various touchpoints,
            we provide a comprehensive understanding of your clientele.
          </div>
          <div className="digitaljourpara">
            This insight goes beyond basic demographics, delving into buying
            behaviors, preferences, and pain points. With this enriched
            understanding, your team can create more personalized experiences,
            craft targeted marketing campaigns, and develop products or services
            that resonate with your clients. This deeper customer understanding
            fosters stronger connections and drives customer loyalty, setting
            your business apart in a competitive market.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image2} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Proactive Problem-Solving
          </div>
          <div className="digitaljourpara">
            Integrating our client relationship services with your CRM
            transforms how you tackle challenges. By continuously monitoring
            client interactions and feedback, our system identifies potential
            issues before they escalate. This proactive approach to
            problem-solving means you can address concerns early, often before
            the client is even aware of them.
          </div>
          <div className="digitaljourpara">
            It enhances your reputation for excellent customer service and
            reliability. And, this not only strengthens client trust but also
            reduces the time and resources spent on reactive measures, leading
            to a more efficient and client-focused business model.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image3} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Strategic Decision Making
          </div>
          <div className="digitaljourpara">
            Our service empowers your business with data-driven insights,
            enhancing strategic decision-making. By providing a clear view of
            client interactions and trends, we enable you to make informed
            decisions about your business direction.
          </div>
          <div className="digitaljourpara">
            Whether it’s identifying new market opportunities, refining customer
            service approaches, or tailoring product offerings, our integration
            with your CRM provides the necessary data to make decisions with
            confidence. This strategic advantage ensures that your business
            actions are aligned with customer needs and market trends, driving
            sustainable growth and competitive advantage.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Streamlined Customer Journeys
          </div>
          <div className="digitaljourpara">
            With our client relationship services integrated into your CRM, we
            streamline the customer journey from initial contact to post-sales
            support. Every customer touchpoint is tracked and optimized for
            maximum efficiency and satisfaction. This streamlined approach
            ensures a consistent and positive experience for your clients,
            regardless of how they interact with your business.
          </div>
          <div className="digitaljourpara">
            It reduces friction points, accelerates the sales process, and
            enhances overall customer satisfaction. By simplifying and enhancing
            the customer journey, we help you build stronger relationships,
            increase customer loyalty, and encourage repeat business.
          </div>
          {/* <Learnmore /> */}
        </div>

        <img src={image} />
      </div>

      <PartnersSaying
        title="Customer Testimonials"
        items={[
          {
            text: "Since partnering up with Appedology, our customer engagement has transformed. We're seeing higher satisfaction rates. Truly a game-changer!",
            user: "- Ali Rehman",
          },

          {
            text: "Appedology's integration with our CRM was seamless. Our teams now have real-time insights, leading to smarter decisions and better client relationships.",
            user: " - Sunita ",
          },

          {
            text: "I'm impressed with Appedology's proactive problem-solving and personalized customer service. It has helped us build trust and loyalty with our customers.",
            user: " - Khawar Hussain",
          },

          {
            text: "The strategic insights provided have been invaluable. We've optimized our marketing and sales efforts, resulting in a noticeable growth in our customer base.",
            user: " - Muzammil Hasan",
          },

          {
            text: "Appedology’s approach to streamlining customer journeys is remarkable and has led to improved feedback from our clients.",
            user: " - Saad Salim",
          },
        ]}
      />

      {/*       
  <div className="partners-saying">
    <Logo />
    <div className="partners-saying-title">What Our Partners Are Saying</div>
    <div className="partners-saying-desc">Absolutely thrilled with the animations! The attention to detail and seamless storytelling really brought our project to life. Highly recommend their services.</div>
      <div className="partners-saying-user">Saife Ali - Customer support</div>
</div> */}

      <div className="expectations">
        <div className="expactations-heading">
          <img src={icon9} />
          <div className="expect-title">Expectations</div>
        </div>

        <div className="expectations-items">
          <div className="expectation-item in-g-d-item1">
            <div className="expectation-item-title">Personalized Support</div>
            <div className="expectation-item-list">
              <div> Customized Solutions </div>
              <div> Quick Data Access </div>
              <div> Easy Integration </div>
              <div> Client-Specific Strategies </div>
              <div> Individualized Attention </div>
              <div> Specialized Expertise </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item2">
            <div className="expectation-item-title">Insightful Analytics</div>
            <div className="expectation-item-list">
              <div> Customer Behavior </div>
              <div> Sales Trends </div>
              <div> Performance Metrics </div>
              <div> Market Insights </div>
              <div> Predictive Analysis </div>
              <div> Engagement Tracking </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item3">
            <div className="expectation-item-title">Enhanced Communication</div>
            <div className="expectation-item-list">
              <div> Instant Notifications </div>
              <div> Multi-Channel Support </div>
              <div> Personalized Interactions </div>
              <div> Clear Conversations </div>
              <div> Feedback Collection </div>
              <div> Consistent Messaging </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-maximize-value in-content-creation">
        <div className="m-maximize-value-content">
          <div>Enhance Your Client Relations</div>
          <div>Get Started with Appedology!</div>
          <div>
            Contact Now
            <img src={headPhone} />
          </div>
        </div>

        <img src={image4} />
      </div>

      <div className="insights">
        <div className="insights-title">
          <span>Blogs</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CustomerRelation;
