import './App.css';


function Services() {
  return (
    <div>
        <h3>this is a services page</h3>
    </div>
  );
}

export default Services;
