import "./style.css";

import TopSection from "../../components/TopSection";
import image from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image5 from "./images/image5.png";
import { Link } from "react-router-dom";



import image6 from "./images/image6.png";
import image7 from "./images/image7.png";
import image8 from "./images/image8.png";
import image9 from "./images/image9.png";
import image10 from "./images/image10.png";
import image11 from "./images/image11.png";

import check from "./images/1080x1080.png";


import tabs1 from "./images/tabs1.png";
import tabs2 from "./images/tabs2.png";
import tabs3 from "./images/tabs3.png";
import tabs4 from "./images/tabsimg4.png";



import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.png";
import brand5 from "../images/brand5.png";
import brand6 from "../images/brand6.png";

import PartnersSaying from "../../components/ParnersSying";


import Learnmore from "../BusinessmManagement/component/Learnmore";

// import image4 from "./images/image4.jpg";
// import image5 from "./images/image5.jpg";
// import image6 from "./images/image6.jpg";

// import image13 from "./images/image6.jpg";

// import image14 from "./images/image14.png";
// import image15 from "./images/image15.png";
// import image16 from "./images/image16.png";
// import image17 from "./images/image17.png";
// import image18 from "./images/image18.png";
// import image19 from "./images/image19.png";

import icon from "../ContentCreation/icons/icon.png";
import icon2 from "../ContentCreation/icons/icon2.png";
import icon3 from "../ContentCreation/icons/icon3.png";
import icon4 from "../ContentCreation/icons/icon4.png";
import icon5 from "../ContentCreation/icons/icon5.png";
import icon6 from "../ContentCreation/icons/icon6.png";
import icon7 from "../ContentCreation/icons/icon7.png";
import icon9 from "../tailoredSolutions/icons/icon.svg";



import icon10 from "./icons/icon1.png";
import icon11 from "./icons/icon2.png";
import icon12 from "./icons/icon3.png";
import icon13 from "./icons/icon4.png";
import icon14 from "./icons/icon5.png";
import icon15 from "./icons/icon6.png";
import icon16 from "./icons/icon7.png";
import icon17 from "./icons/icon8.png";

import bgSideIcon2 from "../images/bg-side-icon2.png"

import AutoCounter from "../about/component/Countdown1.js";

import arrowRight from "../ContentCreation/icons/arrow_right.svg";
import headPhone from "../ContentCreation/icons/headset_mic.svg";
import Logo from "../../images/Logo.jsx";
import OurServices from "../../components/OurServices/index.jsx";

import {Helmet} from "react-helmet";

function SEO() {
  return (

      <>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Social Media Management Company - Appedology.pk</title>
                <meta name="description" content="Grow your online reach with the best social media management services in Pakistan. Our social media experts help you reach your target audience and drive growth!" />
                <link rel="canonical" href="https://appedology.pk/social-media-management" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Social Media Management Company - Appedology.pk" />
                <meta property="og:description" content="Grow your online reach with the best social media management services in Pakistan. Our social media experts help you reach your target audience and drive growth!" />
                <meta property="og:url" content="" />
                <meta property="og:locale" content= "pakistan" />
                <meta property="og:site_name" content="Appedology" />
                <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>

    <div className="content-creation">
      <TopSection
        subtitle="Social Media Management"
        title="Looking for someone to manage your social media accounts? "
        image={image}
        desc="Appedology is the best social media management company. We've got top-tier social media managers who boost your online presence, increase your followers, and give you a competitive edge over your competitors."
      />

      <div className="content-creation-content">
        <div className="medical-billing-intro pt-5 pb-5">
          <div className="medical-billing-intro-title">
            Strong <span className="gradiant-text-color">Presence</span>, Lucrative Benefits
          </div>
          <div className="medical-billing-intro-desc">
            If your social media efforts are not reaching your goals and your
            account is not achieving what you expected, it’s time to turn to
            Appedology. We offer all the help you need. Our team specializes in
            organically expanding your audience and uncovering leads from
            unexpected places.
          </div>
        </div>

        <div className="happyclient pt-5 pb-5 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={35} interval={100} spanContnet="+" />
                  </h2>
                  <p className="text-uppercase pt-3">Social Media Managers</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={500} interval={30} spanContnet="+" />
                  </h2>
                  <p className="text-uppercase pt-3">Leads Generated</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={50} interval={200} spanContnet="+" />
                  </h2>
                  <p className="text-uppercase pt-3">Ongoing Projects</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="m-our-services in-content-creation">
          <div className="m-our-services-title in-content-creation">
            What can you expect from us?
          </div>
          <div className="m-our-services-items">
            <div className="m-our-services-item">
              <img src={icon} />
              <div>Claim Management</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon2} />
              <div>Eligibility Verification</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon3} />
              <div>Medical Coding</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon4} />
              <div>Compliance Support</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon5} />
              <div>AR Management</div>
            </div>
          </div>

          <div className="m-our-services-content">
            <div>
              <ul>
                <li>Defining Sales Goals and Objectives</li>
                <li>Understanding the Target Market</li>
                <li>Creating a Value Proposition</li>
                <li>Choosing Sales Channels</li>
                <li>Developing Sales Processes and Techniques</li>
                <li>Training and Equipping the Sales Team</li>
              </ul>
              <button className="learn-more-btn2">
                Learn more
                <img src={arrowRight} />
              </button>
            </div>
            <img src={image3} />
          </div>
        </div> */}

        <OurServices
          additionalClass="in-content-creation"
          title="What can you expect from us?"
          icons={[
            {
              icon: icon,
              title: "Content Creation",
            },

            {
              icon: icon2,
              title: "Scheduling",
            },
            {
              icon: icon3,
              title: "Community Engagement",
            },
            {
              icon: icon4,
              title: "Analytics And Reporting",
            },
            {
              icon: icon5,
              title: "Social Advertising",
            },
          ]}
          images={[tabs1, tabs2, tabs3, tabs4, tabs1]}
          items={[
            [
              "Social Media Captions",
              "Graphic Design Posts",
              "Video Production Clips",
              "Infographic Development",
              "Interactive Polls",
              "Social Media Stories",
            ],

            [
              "Business Suite Account",
              "Post Timing Optimization",
              "Automated Publishing Setup",
              "Multi-Platform Coordination",
              "Audience Targeting Strategy",
              "Hashtag Management",
            ],
            [
              "Responding to Comments",
              "Hosting Live Sessions",
              " Sharing User Content",
              " Conducting Polls/Surveys",
              " Creating Discussion Threads",
              "  Celebrating Milestones/Events",
            ],

            [
              "Engagement Metrics",
              "Follower Growth",
              "Content Performance",
              "Audience Demographics",
              "Conversion Rates",
              "Reach Analysis",
            ],

            [
              "Sponsored Content Posts",
              "Targeted Ad Campaigns",
              "Influencer Collaborations",
              "Social Media Stories",
              "Video Advertisements",
              "Carousel Ads",
            ],
          ]}
        />
      </div>

      <div className="brands-container">
        <div className="title1 my-5 mb-5">200k+ brands trust Appedology</div>

        <div className="brands-icons">
          <img src={brand1} alt="" />
          <img src={brand2} alt="" />
          <img src={brand3} alt="" />
          <img src={brand4} alt="" />
          <img src={brand5} alt="" />
          <img src={brand6} alt="" />
        </div>
      </div>

      <div className="why-use-content">
        <div className="title-with-befor-and-after in-content-creation socialMedia resolvedheading">
          <span>Why Social Media Presence is Crucial for Your Businesses?</span>
        </div>

        <div className="images_resolved brands-container">
          <div className="col-md-12">
            <div className="increasefont">
                In today's digital world, a strong social media presence is essential. Imagine your brand catching the eye of countless potential customers on their feeds. We make this a reality for your business.
              <br>
              </br>
              <br>
              </br>
                Our social media marketing management services enable you to engage in meaningful conversations, foster lasting relationships, and build a community around your brand. Target your ideal audience with precision, making sure your message reaches the right people. This isn't just marketing—it's a strategic approach that's smart and effective. Plus, gain insights into market trends and customer preferences to drive your business forward.
                <br></br>
                <br>
                </br>
                A robust social media presence is a game-changer in the modern marketplace.
              
              Let's make it happen!
            </div>
            
            <Link to="/contact"><button className="learn-more-btn">Book Consultation</button></Link>
          </div>
          <img src={check} />
        </div>
      </div>

      <div className="position-relative">
        <div className="effectoive-designing pt-5">
          <div className="effectoive-designing-title title2 mb-5">
            Benefits of an Effective Online Presence
          </div>

          <div className="effectoive-designing-items">
            <div className="effectoive-designing-item">
              <div> <img src={icon10} /> </div>
              <div>Wider Audience</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon11} /></div>
              <div>Brand Recognition</div>
            </div>
            <div className="effectoive-designing-item">
              <div><img src={icon12} /></div>
              <div>Global Reach</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon13} /></div>
              <div>Increased Web Traffic</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon14} alt="" /></div>
              <div>24/7 Accessibility</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon15} alt=""/></div>
              <div>Market Insights</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon16} alt=""/></div>
              <div>Better Networking</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon17} alt="" /></div>
              <div>Higher Sales</div>
            </div>
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>
        <img className={`position-absolute top-0 bottom-0 end-0 my-auto`} style={{ maxWidth:"140px" }} src={bgSideIcon2} alt="" />
      </div>

      <div className="why-choose-content-creation">
        <div className="title2 mb-5">
          Why Outsourcing Social Media Management is a Smart Choice?
        </div>
        <div className="why-choose-content-creation-content">
          <img src={image6} alt=""/>
          <div>
            <div>
              Considering outsourcing social media management services? Smart
              move! Here's why: Outsourcing frees up your time, letting you
              focus on core business activities while experts handle your online
              presence. It's cost-effective; no need for a full-time employee
              when you have skilled professionals at a fraction of the cost.
            </div>
            <div>
              We stay ahead of ever-changing trends, ensuring your strategy
              remains top-notch. Plus, our team brings a fresh perspective,
              injecting creativity and innovation into your campaigns.
              <br />
              <br />
              And let's talk results – expect enhanced brand visibility,
              increased engagement, and a growing follower base. In short,
              outsourcing social media management isn’t just a choice; it's a
              strategic decision for growth and success. Are you ready to take
              the leap?
            </div>
          </div>
        </div>
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Start your digital journey with a bang!
          </div>
          
          <div className="digitaljourpara mb-3">
            At Appedology, we work not only with pre-made profiles but also
            specialize in building business profiles from scratch. Whether it’s
            a new e-commerce store or a brick-and-mortar shop planning to move
            online, we have social media profile management services for
            everyone.
          </div>
          <div className="digitaljourpara mb-3">
            We craft your online identity with precision and flair, blending art
            and strategy to design profiles that captivate and resonate with
            your audience.
          </div>
          <div className="digitaljourpara mb-3">
            From zero to hero on social media, our team makes
            your brand unforgettable. Your new, impactful online persona awaits!
          </div>
          <Learnmore />
        </div>

        <img src={image7} alt=""/>
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Join the 28% leading the online market!
          </div>
          <div className="digitaljourpara">
            Dive into the digital wave! Recent stats reveal that 28% of all
            business happens on the internet, highlighting its growing
            importance. It's a clear sign of how e-commerce is shaping our
            world.
          </div>
          <div className="digitaljourpara">
            Be part of this exciting trend and make your mark in the online
            marketplace. It's not just a shift; it's where the future of
            business thrives.
            <br />
            Are you in ?
          </div>
         <Learnmore />
        </div>

        <img src={image8} alt="" />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Influencer Marketing is also at its peak
          </div>
          <div className="digitaljourpara">
            Did you know that a significant number of businesses see better
            returns from influencer marketing than other channels? If you're
            considering hiring a social media management company for small
            business, this could be a game-changer.
          </div>
          <div className="digitaljourpara">
            Influencers can authentically connect your brand with a wider
            audience, offering a fresh, relatable approach. 
          </div>
          <div className="digitaljourpara">
            It's an effective
            way to enhance your online presence without the hard sell.
          </div>
          <Learnmore />
        </div>

        <img src={image3} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Social Media Connection Building{" "}
          </div>
          <div className="digitaljourpara">
            At Appedology, we're not just about likes and shares; we're about
            forging genuine relationships. Our tailored social media community
            management services bring your brand closer to your audience,
            creating a community that grows with you.
          </div>
          <div className="digitaljourpara">
            We go beyond the basics, crafting a unique digital presence that
            resonates with your audience. Imagine a social media space buzzing
            with engaged followers, where every post sparks conversations and
            builds lasting relationships.
          </div>
          <div className="digitaljourpara">
            With our expertise, your brand isn't just seen; it's remembered and cherished. 
          </div>
          
        </div>

        <img src={image9} alt="" />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Social Media Management Services Pricing
          </div>
          <div className="digitaljourpara">
            We understand that every business is unique, which is why our cost
            of social media management services is tailored to fit your specific
            needs and budget.</div>
            <div className="digitaljourpara"> No hidden fees, and no surprises – just
            transparent, adaptable solutions that grow with your business.
          </div>
          <div className="digitaljourpara">
            Our pricing models are designed to suit diverse business needs,
            providing transparency and control over your investment. </div>
            <div className="digitaljourpara">With us,
              affordability meets excellence, within a budget that works for you!
            </div>
            
        </div>

        <img src={image10} alt=""/>
      </div>

      {/* <div className="content-creation-item flip mb-24">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">Lorem</div>
          <div>
            With 4.8 billion social media users, do you still think there could
            be any other place where you can find your target audience? Grab
            today’s consumers on social media with the right social media
            management services!
          </div>
          <div>
            In today’s day and age social media isn’t just a place for posting
            kitty pictures or chit-chatting with friends. It has become a
            workspace for a big number of businesses. Millions of businesses are
            deriving big incomes by only understanding the worth of social media
            and the impact it can create. If you want the same to happen with
            your business, give Rankon Technologies a call for result-oriented
            social media management services in India today.
          </div>
          <button className="learn-more-btn">LEARN MORE</button>
        </div>

        <img src={image11} />
      </div> */}

      
      <PartnersSaying
        title="Hear about us from our clients"
        items={[
          {
            text: "Appedology's content creation is top-notch! Our social media pages have never looked better. Engaging, creative, and perfectly aligned with our brand voice.",
            user: "Ali Rehman",
          },

          {
            text: "Their strategy development service is a game-changer. We've seen a significant increase in engagement and reach. Appedology really understands our audience.",
            user: "Mohid Haider",
          },

          {
            text: "The community management from Appedology has transformed our social media presence. They're responsive, engaging, and truly connect with our followers.",
            user: "Aneel Kumar",
          },

          {
            text: "Impressed with Appedology's analytics and reporting. Their insights have helped us refine our approach and achieve better results. It's data-driven decision-making at its best!",
            user: "Sarah Ali",
          },

          {
            text: "Appedology's handling of our Meta ads has been exceptional. Their targeted strategies have significantly boosted our conversions and online visibility. Truly impressive!",
            user: "Robert Ch.",
          },
        ]}
      />

      {/* <div className="partners-saying">
        <Logo />
        <div className="partners-saying-title">
          What Our Partners Are Saying
        </div>
        <div className="partners-saying-desc">
          Absolutely thrilled with the animations! The attention to detail and
          seamless storytelling really brought our project to life. Highly
          recommend their services.
        </div>
        <div className="partners-saying-user">Saife Ali - Customer support</div>
      </div> */}

      <div className="expectations">
        <div className="expactations-heading">
          <img src={icon9} alt="" />
          <div className="expect-title">Expectations</div>
        </div>

        <div className="expectations-items">
          <div className="expectation-item socialexp1">
            <div className="expectation-item-title">Content Creation</div>
            <div className="expectation-item-list">
              <div> Creative Imagery </div>
              <div> Engaging Posts </div>
              <div> Trendy Topics </div>
              <div> Brand Storytelling </div>
              <div> Audience Interaction </div>
              <div> Consistent Quality </div>
            </div>
          </div>

          <div className="expectation-item socialexp2">
            <div className="expectation-item-title">Social Advertising </div>
            <div className="expectation-item-list">
              <div> Increased Reach </div>
              <div> Targeted Campaigns </div>
              <div> Brand Awareness </div>
              <div> Higher Engagement </div>
              <div> Measurable Results </div>
              <div> Cost Efficiency </div>
            </div>
          </div>

          <div className="expectation-item socialexp3">
            <div className="expectation-item-title">Analytics </div>
            <div className="expectation-item-list">
              <div> Insightful Data </div>
              <div> Engagement Metrics </div>
              <div> Audience Growth </div>
              <div> Performance Analysis </div>
              <div> Trend Insights </div>
              <div> ROI Tracking </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-maximize-value in-content-creation">
        <div className="m-maximize-value-content">
          <div>Boost your brand's social media presence now!</div>
          <div>
            Join us and watch your social media thrive! Engaging content, real
            connections, and increased sales! Let's start this journey together!
          </div>
          <div>
            Get in touch
            <img src={headPhone} />
          </div>
        </div>

        <img src={image5} />
      </div>

      <div className="insights mb-5 pb-5">
        <div className="insights-title">
          <span>Insights</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SEO;
