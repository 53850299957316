import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import PauseOnHover from "./component/Slide.js";
//import SliderFade from './component/Mainslider.js'
//import SliderTwo from './component/SliderTwo.js'
import SliderFour from "./component/SliderFour.js";
//import SliderThree from './component/SliderThree.js'
import video from "./images/video-1.mp4";
import HalfScrollCountertwo from "./Halfnu2.js";
import HalfScrollCounterthree from "./Halfnu3.js";
import HalfScrollCounterfour from "./Halfnu4.js";
//import AutoCounterfour from "./Countdown4.js";
import Slide1 from "./images/homepagebanner.png";
import Slide2 from "./images/homepagebanner-2.png";
import Slide3 from "./images/homepagebanner-3.png";
//import Whoweare from './component/Whoarewe.js'
//import Whoweare from './component/Whoarewe1.js'
// import whoarewe1 from './images/who-we-are.png';
// import whoarewe2 from './images/whoweare2.png';
// import whoarewe3 from './images/whoweare3.png';
//import ScrollCounter from './Halfnumber.js'
import FadingSlides from "./FadingSlide.js";
import HalfScrollCounter from "./Halfnu.js";
//import Learnmore from './Learnmore.js'
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";


function Home() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const images = [Slide1, Slide2, Slide3];

  // const imageswhoarewe =
  // [
  //   whoarewe1,
  //   whoarewe2,
  //   whoarewe3
  // ];

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Appedology.pk: Pakistan's Top-Rated Software Company</title>
                <meta name="description" content="Appedology is the hub for everything digital. We provide the best App & Web development, Digital Marketing, and AI & ML solutions in Pakistan." />
                <link rel="canonical" href="https://appedology.pk/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Appedology.pk: Pakistan's Top-Rated Software Company" />
                <meta property="og:description" content="Appedology is the hub for everything digital. We provide the best App & Web development, Digital Marketing, and AI & ML solutions in Pakistan" />
                <meta property="og:url" content="" />
                <meta property="og:locale" content= "pakistan" />
                <meta property="og:site_name" content="Appedology" />
                <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>
      <div className="homepageslider text-white">
        <SliderFour images={images} />
      </div>

      <div className="container journey">
        <div className="row pt-5 mt-5">
          <div className="heading text-center">
            <div className="col-md-10 col-lg-9 mx-auto">
              <h2 className="title2 h2 lh-base mb-3">
                At Appedology, we view success as a shared journey offering you
                simplified solutions and unparalleled support every step of the
                way.
                
              </h2>
            </div>
          </div>
        </div>
        <div className="row pb-5">
          <div className="heading text-center">
            <div className="col-md-10 col-lg-12 mx-auto videosection">
              <div>
                <video
                  className="videomainpage"
                  style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
                  playsInline
                  loop
                  muted
                  alt="All the devices"
                  src={video}
                  type="video/mp4"
                  ref={videoEl}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fullchallenges">
        <div className="container">
          <div className="row pt-2 pb-5">
            <div className="heading text-center">
              <div className="col-md-12 mx-auto">
                <h4 className="h2 mb-3 oportun">
                  Opportunities Over Obstacles
                </h4>
                <h2 className="h1">We Turn Challenges into Solutions</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 px-0 mx-auto">
              <div className="row">
                <div className="col-md-6 position-relative">
                  {/* <img src={globally} alt="Logo" className='img-fluid ' /> */}
                  <div className="globlyimg">
                    <div className="globlytest text-white px-4">
                      <h4 className="h6 text-uppercase">services</h4>
                      <h5 className="h5 text-uppercase">
                        Empowering clients globally with advanced tech and
                        streamlined processes
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ps-3">
                  <div className="col-md-12 mt-5 mb-5 hom_pddigtl">
                    <h4 className="h5 text-uppercase challeng_hea">
                      Digital Marketing
                    </h4>
                    <p className="lh-base mobilepara">
                    Hiring a digital marketing agency that can give a boost to your online presence is necessary. And, we are here to do that! Our team creates targeted campaigns that can drive engagement, accelerate growth, and thereby distinguish your brand in the digital landscape.
                    </p>
                    <Link className="btn btn-outline-primary mt-2 btnblack" to="/social-media-management">
                      LEARN MORE
                    </Link>
                  </div>
                  <div className="col-md-12 mt-5 mb-5 hom_pddigtl">
                    <h4 className="h5 text-uppercase challeng_hea">
                      App & Web Development
                    </h4>
                    <p className="lh-base mobilepara">
                    When looking for a web development company, Appedology is the best choice. We bring together the fusion of aesthetics and functionality with our app and web development services. Our team designs sleek, user-friendly digital platforms, each tailored to meet your unique business needs.
                    </p>
                    <Link className="btn btn-outline-primary mt-2 btnblack" to="/web-dev">
                      LEARN MORE
                    </Link>
                  </div>
                  <div className="col-md-12 mt-5 mb-5 hom_pddigtl">
                    <h4 className="h5 text-uppercase challeng_hea">
                      Design & Animation
                    </h4>
                    <p className="lh-base mobilepara">
                    We are proud to say that we stand as one of the best graphic designing companies in Pakistan. Our team turns visions into captivating visuals, specializing in creating stunning and memorable content that engages your audience, brings stories to life, and captures the imagination in every frame.
                    </p>
                    <Link className="btn btn-outline-primary mt-2 btnblack" to="/graphic-designing-services">
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="visiontocode">
        <div className="container">
          <div className="col-md-12 mx-auto pt-5 pb-5 techlogicalmain">
            <div className="row">
              <div className="col-md-6">
                <div className="techlogicaltext pt-3">
                  <h3 className="h1 text-uppercase mb-2">
                    <strong>From Vision to Code to Excellence</strong>
                  </h3>
                  <h5 className="h4 mb-3">
                    If you can dream it, we can develop it!
                  </h5>
                  <p className="lh-base">
                  Every technology is unique, just like your needs. Trust our mobile app development services to choose the perfect tech for your project. We can develop your apps using the best-suited technologies for you and your customers/clients.
                  </p>
                </div>
              </div>
              <div className="offset-md-1 col-md-5 techlogicalleft">
                <div className="row countdownnumbers">
                  <div className="col-md-6 pt-3 pb-3">
                    <h4 className="h1">
                      {/* <AutoCounter /> */}
                      <HalfScrollCounter />
                    </h4>
                    <p>Years of excellence</p>
                  </div>
                  <div className="col-md-6 pt-3 pb-3">
                    <h4 className="h1">
                      {/* <AutoCountertwo /> */}
                      <HalfScrollCountertwo />
                    </h4>
                    <p>Projects delivered</p>
                  </div>
                  <div className="col-md-6 pt-3 pb-3">
                    <h4 className="h1">
                      {/* <AutoCounterthree /> */}
                      <HalfScrollCounterthree />
                    </h4>
                    <p>Countries with our presence</p>
                  </div>
                  <div className="col-md-6 pt-3 pb-3">
                    <h4 className="h1">
                      {/* <AutoCounterfour /> */}
                      <HalfScrollCounterfour />
                    </h4>
                    <p>Clients served across the globe</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PauseOnHover />
      {/* <Whoweare images={imageswhoarewe} /> */}

      <FadingSlides />

      {/* <section className='whoweare text-white position-relative'>
              
          </section> */}
    </>
  );
}

export default Home;
