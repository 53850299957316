import TopSection from "../../components/TopSection";
import "./style.css";
import image from "./images/image1.png";

import image2 from "./images/image2.png";
import image4 from "./images/image4.svg";

import image14 from "./images/image14.png";

import headPhone from "../medicalBilling/icons/headset_mic.svg";

import {Helmet} from "react-helmet";

import PremiumExpetise from "../mobilAppDevelopement/PremiumExpertise/PremiumExpetise";
import Items from "../mobilAppDevelopement/PremiumExpertise/Items/Items";

import icon from "./icons/icon.svg";
import icon2 from "./icons/icon2.svg";
import icon3 from "./icons/icon3.svg";
import icon4 from "./icons/icon4.svg";
import icon5 from "./icons/icon5.svg";
import icon6 from "./icons/icon6.svg";

import icon7 from "./icons/CertifiedCloud-Resources.svg";
import icon8 from "./icons/Centralized-Management.svg";
import icon9 from "./icons/AWSandAzure-Expertise.svg";
import icon10 from "./icons/industry.svg";

import scalability from "./images/scalability.png";
import pooling from "./images/pooling.png";
import enablement from "./images/enablement.png";



import icon11 from "./icons/icon11.png";


import OurPartners from "../mobilAppDevelopement/OurPartners/OurPartners";
import TechStack from "./techstack/TechStackCloud.jsx";

import AutoCounter from "../about/component/Countdown1";

import GoToExpert from "../mobilAppDevelopement/GoToExpert/GoToExpert";

import YourTrusted from "./YourTrusted";

import activeIcon1 from "./icons/active1.png";
import activeIcon2 from "./icons/active2.png";
import activeIcon3 from "./icons/active3.png";
import activeIcon4 from "./icons/active4.png";
import activeIcon5 from "./icons/active5.png";
import activeIcon6 from "./icons/fileiconwhite.png";
import bgSideIcon1 from "../images/bg_side_icon1.png"
import bgSideIcon2 from "../images/bg-side-icon2.png"

const offersItems = [
  {
    icon,
    activeIcon: activeIcon1,
    title: "Networking Infrastructure",
    desc: "We build reliable and robust networks, ensuring your business stays connected. Our networking solutions are designed for speed, efficiency, and seamless connectivity.",
  },
  {
    activeIcon: activeIcon3,

    icon: icon2,
    title: "Cloud Computing Platforms",
    desc: "Experience the advantages of cloud computing services with our platforms. They offer flexibility and scalability, enabling your business to grow and adapt effortlessly.",
  },
  {
    activeIcon: activeIcon2,

    icon: icon3,
    title: "Data Storage and Management",
    desc: "Safeguard your data with our secure storage solutions. We manage your data effectively, ensuring easy access and protection against loss or damage.",
  },

  {
    activeIcon: activeIcon5,

    icon: icon4,
    title: "Virtualization",
    desc: "Our virtualization services make your IT operations more efficient. We create virtual versions of your technology, saving space and resources, while boosting performance.",
  },

  {
    activeIcon: activeIcon4,

    icon: icon5,
    title: "Service Models",
    desc: "Choose from different types of cloud computing services to match your specific business needs. Whether it's IaaS, PaaS, or SaaS, we provide the right solution for you.",
  },

  {
    activeIcon: activeIcon6,

    icon: icon6,
    title: "Security and Compliance",
    desc: "Rest assured with our top-notch security measures. We keep your data safe and ensure your business meets all regulatory compliance standards.",
  },
];

function NetworkAndCloud() {
  return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Networking & Cloud Computing Services - Appedology.pk</title>
          <meta name="description" content="Our professional networking & cloud computing services ensure smooth and uninterrupted data flow and optimizes your business for agility and success in the digital era!" />
          <link rel="canonical" href="https://appedology.pk/network-and-cloud"/>
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Networking & Cloud Computing Services - Appedology.pk" />
          <meta property="og:description" content="Our professional networking & cloud computing services ensure smooth and uninterrupted data flow and optimizes your business for agility and success in the digital era!" />
          <meta property="og:url" content="" />
          <meta property="og:locale" content= "pakistan" />
          <meta property="og:site_name" content="Appedology" />
          <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
      </Helmet>



    <div>
      <TopSection
        subtitle="Networking & Cloud Computing Services"
        title="Future-Proof Your Business with Integrated Network and Cloud Services"
        desc="Boost your business with our managed cloud computing services! Scale up, adapt fast, and stay secure in the cloud, all with our expert help."
        btn={{
          text: "Schedule Call",
          link: "/contact",
        }}
        image={image}
        
      />
      <div className="network-top mt-5 pt-3">
        <div className="network-top-title">
          How Can Your Business Benefit from Cloud Computing <br></br>Consulting
          Services?
        </div>

        <div className="network-top-content row">
          <div className="network-top-contnet-texts  col-lg-6">
            <div>
              <br style={{ marginBottom: "20px" }} />
              Cloud computing consulting services unlock the full potential of the cloud, guiding businesses in an environment where over 75% are already leveraging cloud technologies.
              <br />
              <br />
              These services not only enhance operational agility to swiftly adapt to market demands but also offer scalable resources, top-notch security, and reliable connectivity essential for running efficiently. More than just an upgrade, cloud consulting enables remote work, real-time analytics, and global collaboration, empowering your business in today's digital landscape.
              <br />
              <br />
              With network services ensuring smooth and uninterrupted data flow, embracing cloud computing consulting positions your business for agility and success in the digital era.
            </div>
          </div>
          <div className="col-lg-6">
            <img class="img-fluid" src={image2} alt="" />
          </div>
        </div>
      </div>

      <div className="network-our-offers">
        <div className="network-heading">
          <div>
            <span>Our Solutions</span>
          </div>
          <div>
            Here's how cloud computing services providers like us elevate your
            business with premier network and cloud computing services.
          </div>
        </div>
        <Items items={offersItems} />
      </div>
      <div className="network-benefits">
        <div className="network-benefits-title">
          <span>
            Discover the Appedology Advantage in Cloud Computing In Pakistan
          </span>
        </div>
        <div className="network-benefits-items pt-5">
          <div className="network-benefits-item">
            <img src={icon7} />
            <div className="network-benefits-item-title">
              Certified Cloud Resources
            </div>
            <div className="network-benefits-item-dec">
              Our team comprises certified cloud experts. We ensure you get
              access to the most skilled professionals working with the latest
              cloud technologies.
            </div>
          </div>

          <div className="network-benefits-item">
            <img src={icon8} />
            <div className="network-benefits-item-title">
              Centralized Management
            </div>
            <div className="network-benefits-item-dec">
              Efficiently manage your IT resources from one central location,
              enhancing operational control and simplifying your technology
              management.
            </div>
          </div>

          <div className="network-benefits-item">
            <img src={icon9} />
            <div className="network-benefits-item-title">
              AWS and Azure Expertise
            </div>
            <div className="network-benefits-item-dec">
              Leverage our deep expertise in leading cloud platforms like AWS
              and Azure for innovative, scalable, and reliable cloud solutions.
            </div>
          </div>
          <div className="network-benefits-item">
            <img src={icon10}  className="industryimg"/>
            <div className="network-benefits-item-title">
              Industry Best Practices
            </div>
            <div className="network-benefits-item-dec">
              Benefit from our commitment to industry best practices, ensuring
              your cloud and network solutions are not just cutting-edge, but
              also secure and reliable.
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative my-5">
          <img className={`position-absolute end-0`} style={{marginTop : "120px", maxWidth:"140px", }} src={bgSideIcon2} alt="" />
      </div>
      <div className="our-featured">
        
        <div>
          <div className="mb-4">What Gives Us an Edge Over Others?</div>
          <div>
            What sets us apart in the realm of Network and Cloud Computing is
            our unwavering commitment to excellence.
            <br />
            At Appedology, we blend certified expertise with personalized
            solutions, ensuring each client receives the utmost attention and
            tailor-made services.
            <br />
            We have a team of certified cloud computing experts that only a few
            cloud computing service provider companies in Pakistan have.
            <br />
            Our deep knowledge of AWS and Azure cloud computing services,
            combined with a steadfast adherence to industry best practices,
            provides a unique blend of innovation, reliability, and security,
            giving our clients a distinct competitive advantage.
          </div>
        </div>
        {/* <div className=""> */}
        <div className="network-featured-items mt-5 pt-5">
          {/* {data.map((item) => ( */}
          <div className={`network-featured-item flex-column flex-md-row`}>
            <img src={scalability} />
            <div className="network-featured-item-content">
              <div className="network-featured-item-title">Scalability</div>
              <div className="network-featured-item-desc">
                Our network and cloud computing services are designed for
                scalability, effortlessly adapting to your business's growth.
                Experience the ease of adjusting your IT resources with our
                solutions, which include rapid expansion capabilities, elastic
                workloads, auto-scaling features, and cost-efficient resource
                management.
              </div>
              <ul className="">
                <li> Rapid Expansion </li>
                <li> Elastic Workloads </li>
                <li> Auto-Scaling </li>
                <li> Cost-Efficiency </li>
              </ul>
            </div>
          </div>

          <div className={`network-featured-item flip position-relative flex-column flex-md-row-reverse`}>
            <img src={image4} />
            <div className="network-featured-item-content network-featured-item-content1">
              <div className="network-featured-item-title">Accessibility</div>
              <div className="network-featured-item-desc">
                With our focus on accessibility, we ensure that you can reach
                your network and cloud services anytime, anywhere. Our cloud
                computing services to businesses support remote access, are
                compatible across various platforms, offer a global reach, and
                guarantee 24/7 availability, enabling your workforce to stay
                connected and productive.
              </div>
              <ul className="">
                <li>Remote Access</li>
                <li>Cross-Platform Support</li>
                <li>Global Reach</li>
                <li>24/7 Availability</li>
              </ul>

              <img className={`position-absolute start-0`} style={{margin : "auto", top: "0" , bottom: "0", maxWidth:"140px" }} src={bgSideIcon1} alt="" />
            </div>
          </div>


          <div className={`network-featured-item flip position-relative flex-column flex-md-row`}>
            <img src={pooling} />
            <div className="network-featured-item-content network-featured-item-content1">
              <div className="network-featured-item-title">
                Resource Pooling
              </div>
              <div className="network-featured-item-desc">
                Maximize your IT efficiency with our resource pooling feature.
                We offer shared infrastructure and multi-tenant usage that
                ensures dynamic resource allocation and efficient utilization.
                This approach not only reduces costs but also enhances overall
                operational efficiency.
              </div>
              <ul className="">
                <li>Shared Infrastructure </li>
                <li>Multi-Tenant Usage</li>
                <li>Dynamic Allocation</li>
                <li>Efficient Utilization</li>
              </ul>
              <img className={`position-absolute start-0`} style={{margin : "auto", top: "0" , bottom: "0", maxWidth:"140px" }} src={bgSideIcon1} alt="" />

            </div>
          </div>

          <div className={`network-featured-item position-relative flex-column flex-md-row-reverse`}>
            <img src={enablement} />
            <div className="network-featured-item-content">
              <div className="network-featured-item-title">
                Innovation Enablement
              </div>
              <div className="network-featured-item-desc">
                Our managed cloud computing services are geared towards enabling
                innovation in your business. We provide tools for rapid
                prototyping, access to advanced analytics, platforms for
                experimental development, and support agile methodologies,
                helping you stay ahead in the market with cutting-edge solutions.
              </div>
              <ul className="">
                <li>Rapid Prototyping </li>
                <li>Advanced Analytics </li>
                <li>Experimental Platforms </li>
                <li>Agile Development </li>
              </ul>
              <img className={`position-absolute end-0`} style={{margin : "auto", top: "0" , bottom: "0", maxWidth:"140px" }} src={bgSideIcon2} alt="" />
            </div>
          </div>


          {/* ))} */}
        </div>
        {/* </div> */}
      </div>
        <div className="container">
            <OurPartners />
          </div>
      <TechStack
        title="Network and Cloud Computing Technology Stack"
        desc="Our networking and cloud computing services seamlessly integrate cutting-edge technology to empower your business in the ever-evolving digital world."
      />
      <div className="happyclient pt-5 pb-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="client text-center">
                <h2>
                  <AutoCounter limit={10} interval={10} spanContnet="+" />
                </h2>
                <p className="text-uppercase pt-3">Years of Experience</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client text-center">
                <h2>
                  <AutoCounter limit={35} interval={30} spanContnet="" />
                </h2>
                <p className="text-uppercase pt-3">New Innovations</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="client text-center">
                <h2>
                  <AutoCounter limit={92} interval={300} spanContnet="%" />
                </h2>
                <p className="text-uppercase pt-3">Client Retention</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <YourTrusted />
      <div className="m-maximize-value"
        style={{backgroundImage: `url(${image14})`}}
      >
        <div className="m-maximize-value-content">
          <div className="lh-base">Make Tech Easy: Start Your Journey Today!</div>
          <div>
            Join us for hassle-free, lightning-fast cloud and networking
            solutions. It’s business growth made simple!
          </div>
          <div>
            Get in touch
            <img src={headPhone} />
          </div>
        </div>

        {/* <img src={image1} /> */}
      </div>

      {/* <div className="game-dev-discuss">
        <img src={image3} />
        <div>
          <div className="game-dev-discuss-title">
            Discuss Your Game Development Idea With us Today
          </div>
          <div className="game-dev-discuss-btn">Get in touch</div>
        </div>
    </div> */}

      <div className="insights mt-5 pb-5">
        <div className="insights-title">
          <span>Insights</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div className="insight-item">
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div className="insight-item">
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div className="insight-item">
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NetworkAndCloud;
