import React from 'react'

function Icon5({fill = "black"}) {
  return (
    <svg width="143" height="175" viewBox="0 0 143 175" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M123.266 16.2227H29.7032V19.7979H123.266C126.223 19.7979 128.629 22.2038 128.629 25.1608V60.9135C128.629 63.8705 126.223 66.2765 123.266 66.2765H107.234V69.8517H123.266C128.194 69.8517 132.204 65.8422 132.204 60.9135V25.1608C132.204 20.2324 128.194 16.2227 123.266 16.2227Z" fill={fill}/>
<path d="M36.9978 62.7048C37.9395 62.7048 38.8807 62.4535 39.7367 61.9518L64.0247 47.7089C67.5699 45.7067 67.5756 40.3696 64.0247 38.3657L39.7367 24.1228C38.041 23.1287 36.0105 23.1179 34.3051 24.0954C32.5997 25.0722 31.5815 26.8291 31.5815 28.7945V57.2804C31.5049 60.2071 34.107 62.7493 36.9978 62.7048ZM35.1567 28.7945C35.1927 27.2157 36.8228 26.5407 37.928 27.2071L62.216 41.45C63.0367 41.9315 63.1254 42.7204 63.1254 43.0374C63.1254 43.3544 63.0367 44.1436 62.216 44.6248L37.928 58.8678C36.6726 59.5928 35.1634 58.7019 35.1567 57.2804V28.7945Z" fill={fill}/>
<path d="M93.2537 23.9691H96.829V62.1053H93.2537V23.9691Z" fill={fill}/>
<path d="M86.1031 30.0196H89.6784V56.0547H86.1031V30.0196Z" fill={fill}/>
<path d="M78.9526 36.8863H82.5279V49.1883H78.9526V36.8863Z" fill={fill}/>
<path d="M100.404 30.0196H103.98V56.0547H100.404V30.0196Z" fill={fill}/>
<path d="M107.555 36.8863H111.13V49.1883H107.555V36.8863Z" fill={fill}/>
<path d="M28.9694 101.12L31.4129 104.174H27.8496C24.8924 104.174 22.4867 101.768 22.4867 98.8114V69.8517H103.659V66.2765H19.1061C16.1489 66.2765 13.7432 63.8705 13.7432 60.9135V25.1608C13.7432 22.2038 16.1489 19.7979 19.1061 19.7979H26.128V16.2227H19.1061C14.1775 16.2227 10.168 20.2324 10.168 25.1608V60.9135C10.168 65.7769 14.0729 69.7426 18.9117 69.8467V98.8114C18.9117 103.74 22.9212 107.75 27.8498 107.75H31.4132L28.9696 110.804L31.7614 113.037L37.4218 105.962L31.7614 98.886L28.9694 101.12Z" fill={fill}/>
<path d="M111.48 80.1652L113.713 82.957L116.768 80.5134V98.8114C116.768 101.768 114.362 104.174 111.405 104.174H97.7016V103.723L103.114 98.836L98.9696 88.8305L91.687 89.2028L88.1832 85.699L88.5555 78.4164L78.55 74.2717L73.6634 79.6844H68.7083L63.8218 74.2717L53.8163 78.4164L54.1884 85.699L50.6848 89.2028L43.402 88.8305L39.2578 98.836L44.6705 103.723V108.678L39.2578 113.564L43.402 123.57L50.6848 123.198L54.1884 126.701L53.8163 133.984L63.8218 138.129L68.7083 132.716H73.6634L78.55 138.129L88.5555 133.984L88.1832 126.701L91.687 123.198L98.9696 123.57L103.114 113.564L97.7016 108.678V107.749H111.405C116.333 107.749 120.343 103.74 120.343 98.8112V80.5132L123.397 82.9568L125.631 80.1649L118.555 74.5046L111.48 80.1652ZM98.8454 114.527L96.6323 119.87L90.2826 119.546L84.5317 125.297L84.8559 131.647L79.5127 133.86L75.2522 129.141H67.1194L62.8589 133.86L57.5158 131.647L57.8399 125.297L52.0895 119.546L45.7395 119.87L43.5264 114.528L48.2458 110.267V102.134L43.5264 97.8733L45.7395 92.5304L52.0895 92.8545L57.8399 87.1036L57.5158 80.7539L62.8589 78.5408L67.1194 83.2599H75.2522L79.5127 78.5408L84.8559 80.7539L84.5317 87.1036L90.2826 92.8545L96.6323 92.5304L98.8454 97.8735L94.1263 102.134V110.267L98.8454 114.527Z" fill={fill}/>
<path d="M64.893 93.1901L65.804 87.6287L62.2756 87.0509L60.4248 98.349L71.7229 100.2L72.3007 96.6713L67.6595 95.9109C75.5158 93.1079 83.733 100.346 81.7361 108.625L85.2186 109.434C88.025 97.6335 75.5616 87.8942 64.893 93.1901Z" fill={fill}/>
<path d="M70.3152 113.122L76.0862 115.824C68.1095 119.926 58.6606 112.804 60.5924 103.898L57.0955 103.153C54.7108 114.138 65.4893 123.481 75.9496 119.761L74.1279 123.651L77.3657 125.167L82.24 114.758L71.8314 109.884L70.3152 113.122Z" fill={fill}/>
<path d="M41.3949 41.2496H44.9701V44.8248H41.3949V41.2496Z" fill={fill}/>
<path d="M48.5454 41.2496H52.1207V44.8248H48.5454V41.2496Z" fill={fill}/>
</svg>

    

  )
}

export default Icon5