import "./style.css";
import icon from "./icons/tech-left1.png";
import icon1 from "./icons/tech-left2.png";
import icon2 from "./icons/tech-left3.png";
import icon3 from "./icons/tech-right1.png";
import icon4 from "./icons/tech-right2.png";
import icon5 from "./icons/tech-right3.png";
// import icon3 from "./icons/icon9.png";
// import icon4 from "./icons/icon2.png";
// import icon5 from "./icons/icon8.png";
// import icon6 from "./icons/icon6.png";
//  import icon13 from "./icons/icon11.png";
//  import icon7 from "./icons/icon13.png";
//  import icon8 from "./icons/icon3.png";
//  import icon11 from "./icons/icon5.png";
//  import icon9 from "./icons/icon12.png";
//  import icon15 from "./icons/icon5.png";
//  import icon16 from "./icons/icon4.png";
//  import icon17 from "./icons/icon6.png";
//  import icon18 from "./icons/icon8.png";





import bigIcon from "./icons/big-icon.png";
import bgGradient from "./icons/bg-gradient.png";

function TechStack({title, desc}) {
  return (
    <div className="tech-stack aistack">
        <div className="content">
        <div>{title}</div>
      <div>
        {desc}
      </div>
        </div>
      <div className="icons">
        <div className="left-icons">
          <div className="hidden">
            <img src={icon} />
          </div>
          <div className="hidden">
            <img src={icon} />
            <img src={icon} />
          </div>
          <div>
            <img src={icon} />
            <img src={icon1} />
            <img src={icon2} />
          </div>
        </div>
        <div
          className="big-icon-container"
        style={{
          backgroundImage: `url(${bgGradient})`,
          "background-size":     "cover",                     
          "background-repeat":   "no-repeat",
          "background-position": "center center" 
        }}>
        <img className="big-icon bigsicon" src={bigIcon} />

        </div>
        <div className="right-icons">
        <div className="">
            <img src={icon3} />
            <img src={icon4} className="hidden" />
            <img src={icon5} />
          </div>
          <div className="hidden">
            <img src={icon4} />
            <img src={icon5} />
          </div>
          <div className="aistacklast">
            <img src={icon4} />
          </div>
         
          
        </div>
      </div>
    </div>
  );
}

TechStack.defaultProps = {
  title: "Mobile app development technology stack",
  desc: "With a rich palette of major technologies at our fingertips, we ensure versatile, efficient, and future-proof solutions across various industry domains"
}

export default TechStack;
