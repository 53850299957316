import React, { useEffect, useRef, useState } from 'react'
import image from "../images/image1.svg";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import image4 from "../images/image4.png";
import image5 from "../images/image5.png";



import CollapsableItem from './CollapsableItem/CollapsableItem';
import "./style.css";


const collapsableData = [{
  title: "E-Commerce",
  desc: "Boost your online store's success with our e-commerce apps. They're designed to enhance customer shopping experiences and skyrocket your sales.",

},
{
  title: "Learning Management Systems",
  desc: "We take educational offerings to the next level. Our LMS apps provide engaging, interactive learning tools that educators and students love."
},
{
  title: "Finance & Banking",
  desc: "What’s better than revolutionizing your financial services? Our apps offer user-friendly interfaces for customers, making banking simpler and more secure."
},
{
  title: "Healthcare",
  desc: "You can now improve patient care with our healthcare apps. They streamline appointments, records, and communication, making healthcare management a breeze."
},

{
  title: "Automotive",
  desc: "Our team drives your automotive business into the future. Our apps connect dealers and customers, offering innovative solutions for a smarter car-buying and service experience."
},


]

function GoToExpert({title, desc, data}) {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [image, image2, image3, image4, image5]
  const [sideImageClass, setSideImageClass] = useState("");
  const imageRef = useRef(null);
  const [show, setShow] = useState(0);

  useEffect(() => {
   if(imageRef.current) {
    setTimeout(()=> {
      imageRef.current.style.opacity = 0;
      imageRef.current.style.display = "block";


    }, 200)
    setTimeout(()=> {
      imageRef.current.style.opacity = 1;
      // imageRef.current.style.display = "block";


    }, 300)
    // imageRef.current.style.opacity = 0;

    imageRef.current.style.display = "none";

    
   }
  }, [imageIndex, imageRef])
  return (
    <div className='go-to-main'>
        <div>
            <div>{title}</div>
            <div>{desc}</div>
            <div className='collapsable-items'>
                {data.map((item, index) => 
                  
              <CollapsableItem show={show} setShow={setShow} setImageIndex={setImageIndex} item={item} index={index}  />
                  
                  )}
                
            </div>
        </div>
        {images[imageIndex] && 
        
        <img ref={imageRef}  className={sideImageClass} src={images[imageIndex]} />
        }
    </div>
  )
}



GoToExpert.defaultProps = {
  title: "We've Got Industry-Wide Expertise",
  desc: "At Appedology, the professional mobile app development company, we specialize in crafting bespoke apps for every industry, ensuring each one offers an exceptional user experience and opens new avenues for business growth and success.",  
  data: collapsableData


}

export default GoToExpert