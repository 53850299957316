import React, { useState, useEffect } from 'react';
import whoarewe1 from './images/who-we-are.png';
import whoarewe2 from './images/whoweare2.png';
import whoarewe3 from './images/whoweare3.png';
import Learnmore from './Learnmore.js'
import { Link } from "react-router-dom";

const FadingSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const images = [whoarewe1, whoarewe2, whoarewe3];
  const [active, setActive] = useState("active");   

  useEffect(() => {
    const fadeOutTimeout = setTimeout(() => {
      // Move to the next slide after fade-out

      setCurrentSlide((prevSlide) => (prevSlide % 3) + 1);


    }, 6000); // Set the timeout for fade-out (adjust as needed)


    return () => {
      clearTimeout(fadeOutTimeout);
      // clearTimeout(opacityTimeout);
    };
  }, [currentSlide]);


  console.log({currentSlide});


  // const items = [
  //    {
  //     title: <h3 className='h1 text-white'><span className='redtext'>Who We </span> Are?</h3>,
  //     text: "We are remote pros helping businesses connect, create & conquer! We are thrilled to introduce ourselves as a strategic partner on the journey of growth and transformation. Committed to bringing a fresh twist to the world of outsourcing, we help you reshape your business for a brighter future."
  //   },
  //   {
  //     title: <h3 className='h1 text-white'>Join Forces with <span className='redtext'>Appedology</span> Today
  //     </h3>,
  //     text: "Be part of a revolution where development meets digital brilliance, redefining your experience. Discover how our innovative digital solutions can unleash your business's true potential."
  //   },
  //   {
  //     title: <h3 className='h1 text-white'>Fast-Track Your <br></br><span className='redtext'>Tech Vision</span></h3>,
  //     text: "Appedology swiftly turns your technology ideas into tangible solutions, offering a range of exceptional development services with just a click"
  //   },


  // ]
  

  // const data = items[currentSlide-1]

  return (
    <div className="slide-container">
      <div 
      style={{ backgroundImage: `url(${images[currentSlide-1]})` }}
      className={`slide visible`}>
        {/* <div className="background">
            <img src={whoarewe1} className='img-fluid' alt='slide1' />
        </div> */}

            <div className={`slidetop    ${currentSlide === 1 ?  "active" : "not-active"}`}>
                <div className="text whowearetext ">
                <h3 className='h1 text-white'><span className='gradiant-text-color'>Who We </span> Are?</h3>
                <p className='lh-base text-white'>We are remote pros helping businesses connect, create & conquer! We are thrilled to introduce ourselves as a strategic partner on the journey of growth and transformation. Committed to bringing a fresh twist to the world of outsourcing, we help you reshape your business for a brighter future.</p>
                <Learnmore />
                </div>
            </div>


            
            <div className={`slidetop    ${currentSlide === 2 ?  "active" : "not-active"}`}>
                <div className="text whowearetext ">
                <h3 className='h1 text-white'>Join Forces with <span className='gradiant-text-color'>Appedology</span> Today</h3>
                <p className='lh-base text-white'>Be part of a revolution where development meets digital brilliance, redefining your experience. Discover how our innovative digital solutions can unleash your business's true potential.</p>
                <Learnmore />
                </div>
            </div>


            <div className={`slidetop   ${currentSlide === 3 ?  "active" : "not-active"}`}>
                <div className="text whowearetext ">
                <h3 className='h1 text-white'>Fast-Track Your <br></br><span className='gradiant-text-color'>Tech Vision</span></h3>
                <p className='lh-base text-white'>Appedology swiftly turns your technology ideas into tangible solutions, offering a range of exceptional development services with just a click</p>
                <Learnmore />
                </div>
            </div>  


      </div>

      {/* <div className={`slide ${currentSlide === 2 ? 'visible' : 'hidden'}`}>
        <div className="background">
        <img src={whoarewe2} className='img-fluid' alt='slide1' />
        </div>
        <div className='slidetop'>
                <div className="text whowearetext ">
                <h3 className='h1 text-white'>Join Forces with <span className='redtext'>Appedology</span> Today <br />
                 </h3>
                <p className='lh-base text-white'>Be part of a revolution where development meets digital brilliance, redefining your experience. Discover how our innovative digital solutions can unleash your business's true potential.</p>
                <button className='btn btn-outline-primary mt-3'>Learn More</button>
                </div>
            </div>
      </div>

      <div className={`slide ${currentSlide === 3 ? 'visible' : 'hidden'}`}>
        <div className="background">
        <img src={whoarewe3} className='img-fluid' alt='slide1' />
        </div>
        <div className='slidetop'>
                <div className="text whowearetext">
                <h3 className='h1 text-white'>Fast-Track Your <br></br><span className='redtext'>Tech Vision</span></h3>
                <p className='lh-base text-white'>Appedology swiftly turns your technology ideas into tangible solutions, offering a range of exceptional development services with just a click</p>
                <button className='btn btn-outline-primary mt-3'>Learn More</button>
                </div>
          </div>
      </div> */}

      
    </div>
  );
};

export default FadingSlides;
