import React, { useState } from 'react';
import contactworld from './images/GLobeApp.gif'
import axios from "axios";

//import { useLocation } from 'react-router-dom';

import './App.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";

const initalState = {
  name: "",
  email: "",
  phone: "",
  address: ""
  }

function Contact() {
  const [state, setState] = useState(initalState);
  const navigate = useNavigate();
  const handleChange = (ev) => {
  const {name, value} = ev.target;
  setState({...state, [name]: value})

}


const handleSubmit = async (ev) => {
  ev.preventDefault();

  const formData = new FormData();

  formData.append("name", state.name);
  formData.append("email", state.email);
  formData.append("phone", state.phone);
  formData.append("address", state.address);


 try {
  console.log(state);
  axios({
    method: "post",
    url: "https://contact.appedology.pk/email_form",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      console.log(response);
      setState(initalState);
      toast.success("Form submitted successfully")
    })
    .catch(function (response) {
      //handle error
      console.log(response);
      toast.error("Something went wrong")
    });
//   const data = await axios("https://contact.appedology.pk/email_form", state);
//   if(data) {
//     setState(initalState);
//     // navigate("/")
//     toast.success("Form submitted successfully")

//   }
 }catch(error) {
  console.log(error)
  // setState(initalState);
    // navigate("/")
    // toast.success("Form submitted successfully")
   
   
    toast.error("Something went wrong")

 }

}

  return (
    <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us - Appedology.pk</title>
            <meta name="description" content="Have a query? Reach out to us through email, phone number, or our contact form and have all your questions answered!" />
            <link rel="canonical" href="https://appedology.pk/contact" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Contact Us - Appedology.pk" />
            <meta property="og:description" content="Have a query? Reach out to us through email, phone number, or our contact form and have all your questions answered!" />
            <meta property="og:url" content="https://appedology.pk/contact" />
            <meta property="og:locale" content= "pakistan" />
            <meta property="og:site_name" content="Appedology" />
            <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>
    <div className='fullcontactus'>
        <div className='container-fluid ps-sm-5'>
          <div className='row contactus mt-5'>
              <div className='col-md-6'>
                  <h3 className='h1 text-center'>Let's Collaborate on Your Next Project</h3>
                  <p className='h6 text-center contactpara'>Together, we'll turn your visions into a reality with a project that's destined for success.</p>
                  <form onSubmit={handleSubmit}>
                    <div className='row pt-5'>
                      <div className='col-md-12 float-start'>
                          <div className="form-floating mb-3">
                              <input required={true} type="text" name='name' value={state.name} onChange={handleChange} className="form-control pt-0 pb-0" id="floatingInput" placeholder="Your Name" />
                          </div>
                      </div>
                      <div className='col-md-12 float-start'>
                          <div className="form-floating mb-3">
                              <input required={true} type="email" name='email' value={state.email} onChange={handleChange} className="form-control pt-0 pb-0" id="floatingInput" placeholder="Email Address" />
                          </div>
                      </div>
                      <div className='col-md-12 float-start'>
                          <div className="form-floating mb-3">
                              <input required={true} type="tel" name='phone' value={state.phone} onChange={handleChange}  className="form-control pt-0 pb-0" id="floatingInput" placeholder="Phone Number" />
                          </div>
                      </div>
                      <div className='col-md-12 float-start'>
                          <div className="form-floating mb-3">
                              <textarea required={true} name='address' value={state.address} onChange={handleChange}  className='form-control textareaown' rows="6"></textarea>
                          </div>
                      </div>
                        <div className="col-md-12">
                          <button type="submit" className="btn-bg btn btn-primary w-100 btn-block pt-3 pb-3">Submit Now</button>
                        </div>
                    </div>
                  </form>
              </div>
              <div className='col-md-6 pt-0 contactusfooter'>
                <img className='img-fluid mx-auto world d-block pt-5 w-65' src={contactworld} alt='footerlogo' />
                  <div className='row pt-5 ps-5'>
                      <div className='col-md-3 pt-2'>
                        <span className='d-flex gap-1 align-items-top'><i class='bx bxs-envelope bx-sm'></i> <span className='pt-1'>sales@appedology.com</span></span>
                      </div>
                      <div className='col-md-4 pt-2 numberscontact'>
                        <span className='d-flex gap-1 align-items-center justify-content-top'>
                        <i class='bx bxs-phone bx-sm'></i> <span>021-35187210</span></span>
                      </div>
                      <div className='col-md-5 pt-2'>
                          <span className='d-flex gap-1 align-items-top'>
                          <i class='bx bxs-map bx-sm'></i>
                            <span className='lh-base'>2nd Floor, 66c-68c, 25th Street, Phase 5 Tauheed Commercial Area, Karachi, 75500.</span>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      </>
  );
}

export default Contact;
