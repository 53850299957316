import React from 'react';
import TextSliderContent from './TextMovingContentCreation.js'


function TextMoving() {

  const texts = [
    {
      title: 'WEBSITE CONTENT',
    },
    {
      title: 'RESEARCH PAPERS',
    },
    {
      title: 'ARTICLES',
    },
    {
      title: 'NEWSLETTERS',
    },
    {
      title: 'PRESS RELEASES',
    },
    {
      title: 'CITY PAGES',
    },
    {
      title: 'PRODUCT DESCRIPTIONS',
    },
    
  ];

  return (
    <>
        <div class="content">
          <div class="col-md-12 text-white  text-center">
            <h2>Digital Content Creation Service</h2>
            <h1>HIRE THE BEST WRITERS FOR<TextSliderContent texts={texts} /></h1>
            <h4>If you think content writers were a thing of the past, rethink! Humans bring a level of creativity and depth that no automated tool can match.</h4>
            </div>
          </div>
    
    
    </>
  )
}

export default TextMoving