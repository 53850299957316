import React from 'react'
import iconBg from "../icon_bg.svg";
import "./style.css"
import iconDefault from "../icon1.svg";


function IconContainer({icon}) {
  return (
    <div className='icon-container'>
    <img src={iconBg} />
    <img src={icon} />
    </div>
  )
}


IconContainer.defaultProps = {
    icon: iconDefault
}

export default IconContainer