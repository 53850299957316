import TopSection from "../../components/TopSection";
import image from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image5.png";
import { Link } from "react-router-dom";


import image6 from "./images/image6.png";
import image7 from "./images/image7.png";
import image8 from "./images/image8.png";
import image9 from "./images/image9.png";
import image10 from "./images/image10.png";
import image11 from "./images/image11.png";
import image12 from "./images/image12.png"
import image13 from "./images/image13.png";

import image14 from "./images/image14.png";
import image15 from "./images/image15.png";
import image16 from "./images/image16.png"; 




import icon from "../ContentCreation/icons/icon.png";
import icon2 from "../ContentCreation/icons/icon2.png";
import icon3 from "../ContentCreation/icons/icon3.png";
import icon4 from "../ContentCreation/icons/icon4.png";
import icon5 from "../ContentCreation/icons/icon5.png";
import icon6 from "../ContentCreation/icons/icon6.png";
import icon7 from "../ContentCreation/icons/icon7.png";
import icon9 from "./icons/icon.png";


import Learnmore from "../BusinessmManagement/component/Learnmore";

import icon10 from "./icons/icon2.png";
import icon11 from "./icons/icon3.png";
import icon12 from "./icons/icon4.png";
import icon13 from "./icons/icon5.png";
import icon14 from "./icons/icon6.png";
import icon15 from "./icons/icon8.png";
import icon16 from "./icons/icon9.png";
import icon17 from "./icons/icon7.png";



import icon22 from "./icons/icon10.png";
import icon18 from "./icons/icon11.png";
import icon19 from "./icons/icon12.png";
import icon20 from "./icons/icon13.png";
import icon21 from "./icons/icon14.png";





import AutoCounter from "../about/component/Countdown1.js";

import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.png";
import brand5 from "../images/brand5.png";
import brand6 from "../images/brand6.png";

import {Helmet} from "react-helmet";

import arrowRight from "../ContentCreation/icons/arrow_right.svg";
import headPhone from "../ContentCreation/icons/headset_mic.svg";
import Logo from "../../images/Logo.jsx";
import OurServices from "../../components/OurServices/index.jsx";

import PartnersSaying from "../../components/ParnersSying";

import bgSideIcon2 from "../images/bg-side-icon2.png"


function GraphicDesign() {
  return (

      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Graphic Designing Services in Pakistan - Appedology.pk</title>
            <meta name="description" content="From logos to posters, brochures, and flyers, our professional graphic designers whip-up the most eye-catching designs that builds a unique brand identity!" />
            <link rel="canonical" href="https://appedology.pk/graphic-designing-services" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Graphic Designing Services in Pakistan - Appedology.pk" />
            <meta property="og:description" content="From logos to posters, brochures, and flyers, our professional graphic designers whip-up the most eye-catching designs that builds a unique brand identity!" />
            <meta property="og:url" content="" />
            <meta property="og:locale" content= "pakistan" />
            <meta property="og:site_name" content="Appedology" />
            <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
        </Helmet>

    <div className="content-creation">
      <TopSection
        subtitle="Animation & Graphic Design Services"
        title="Visuals that Bring Your Brand to Life"
        desc="Discover the power of design with Appedology’s animation and digital graphic design services. Our visuals breathe life into your ideas, transforming them into stunning, effective graphics. "
        lets="Let's create something amazing together!"
        btn={{
          text: "Schedule Call",
          link: "/contact",
        }}
        image={image}
      />

      <div className="content-creation-content">
        <div className="medical-billing-intro pt-3 pb-1">
          <div className="medical-billing-intro-title">
            Design <span>Beyond Expectations?</span> 
          </div>
          <div className="medical-billing-intro-desc">
            Exceeding expectations with exceptional graphic solutions,<br />
            Appedology specializes in both print and digital designs that
            captivate and inspire.
          </div>
        </div>

        <div className="happyclient pt-5 pb-5 mb-24">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={50} interval={10} spanContnet="+" />
                  </h2>
                  <p className="text-uppercase pt-3">Designers & Animators</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={15} interval={300} spanContnet="k+" />
                  </h2>
                  <p className="text-uppercase pt-3">Completed Projects</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter
                      limit={0}
                      interval={0}
                      noCounter={true}
                      spanContnet="5/5"
                    />
                  </h2>
                  <p className="text-uppercase pt-3">Client Satisfaction</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <OurServices
          additionalClass="in-content-creation"
          title="The Services Suite"
          icons={[
            {
              icon: icon18,
              title: "Graphic Design ",
            },

            {
              icon: icon19,
              title: "Website Design",
            },
            {
              icon: icon20,
              title: "Animation Services",
            },
            {
              icon: icon21,
              title: "Digital Marketing Design",
            },
            {
              icon: icon22,
              title: "Print Media Design",
            },
          ]}
          images={[image14, image15, image16, image14, image15]}
          items={[
            [
              "Logo and Branding Design",
              "Business Card Design",
              "Brochure and Flyer Design",
              "Social Media Graphic Design",
              "Infographic Creation",
              "Poster and Banner Design",
            ],

            [
              "UI/UX Design",
              "Responsive Web Design",
              "Landing Page Design",
              "E-commerce Website Design",
              "Website Redesign",
              "Mobile App Design",
            ],
            [
              "2D Animation",
              "3D Animation",
              "Motion Graphics",
              "Whiteboard Animation",
              "Character Animation",
              "Explainer Videos",
            ],

            [
              "Email Campaigns/Templates",
              "Social Media Ads Design",
              "Social Media Reels",
              "Infographics",
              "Carousel",
              "Digital Banners",
            ],

            [
              "Catalog Design",
              "Magazine Layouts",
              "Trade Show Displays",
              "Packaging Design",
              "Corporate Report Design",
              "Direct Mail Design",
            ],
          ]}
        />

        {/* <div className="m-our-services in-content-creation">
          <div className="m-our-services-title in-content-creation">
          What do you get when you use the no. 1 graphic designing platform??
          </div>
          <div className="m-our-services-items">
            <div className="m-our-services-item">
              <img src={icon} />
              <div>Claim Management</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon2} />
              <div>Eligibility Verification</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon3} />
              <div>Medical Coding</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon4} />
              <div>Compliance Support</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon5} />
              <div>AR Management</div>
            </div>
          </div>

          <div className="m-our-services-content">
            <div>
              <ul>
                <li>Defining Sales Goals and Objectives</li>
                <li>Understanding the Target Market</li>
                <li>Creating a Value Proposition</li>
                <li>Choosing Sales Channels</li>
                <li>Developing Sales Processes and Techniques</li>
                <li>Training and Equipping the Sales Team</li>
              </ul>
              <button className="learn-more-btn2">
                Learn more
                <img src={arrowRight} />
              </button>
            </div>
            <img src={image3} />
          </div>
        </div> */}
      </div>

      <div className="brands-container">
        <div className="title1">200k+ brands trust Appedology</div>

        <div className="brands-icons">
          <img src={brand1} />
          <img src={brand2} />
          <img src={brand3} />
          <img src={brand4} />
          <img src={brand5} />
          <img src={brand6} />
        </div>
      </div>

      <div className="why-use-content">
        <div className="title-with-befor-and-after in-content-creation socialMedia lineissue">
          <span className="">Animating Your Ideas, Designing Your Dreams</span>
        </div>

        <div className="">
          <div className="ps-5">
            <div>
              Appedology is where innovative design meets animation.
              Specializing in everything from eye-catching logos to immersive
              animations, our graphic designing company in Pakistan brings your
              vision to life with flair and precision.
            </div>
            <div>
              We're not just designers; we're storytellers, dedicated to
              creating interactive, engaging visuals that captivate your
              audience. Your participation is key to our process, ensuring each
              project is a tailored, creative journey. <br />Join us in crafting
              designs that don't just meet, but redefine expectations.
            </div>
            <Learnmore />
          </div>
          <img src={image6} />
        </div>
      </div>

      <div className="position-relative">
        <div className="effectoive-designing">
          <div className="effectoive-designing-title title2 mb-5">
            What can you expect from us?
          </div>
          <div className="effectoive-designing-items">
            <div className="effectoive-designing-item">
              <div><img src={icon10} /></div>
              <div>Storyboard Creation</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon11} /></div>
              <div>Motion Graphics </div>
            </div>
            <div className="effectoive-designing-item">
              <div><img src={icon12} /></div>
              <div>Flash Animation</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon13} /></div>
              <div>3D Modeling & Animation</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon14} /></div>
              <div>Character Design </div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon15} /></div>
              <div>Explainer Video </div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon16} /></div>
              <div>Web Animation</div>
            </div>

            <div className="effectoive-designing-item">
              <div><img src={icon17} /></div>
              <div>Infographic Animations</div>
            </div>
          </div>
          <Learnmore />
        </div>
        <img className={`position-absolute top-0 bottom-0 end-0 my-auto`} style={{ maxWidth:"140px" }} src={bgSideIcon2} alt="" />
      </div>

      <div className="why-choose-content-creation">
        <div className="title2 mb-5 pb-5">
          Why Choosing Appedology Can Be a Game-changer For You?
        </div>
        <div className="why-choose-content-creation-content">
          <img src={image6} />
          <div>
            <div>
              Choosing Appedology can revolutionize your business's visual
              presence. Our expert team blends innovative design with advanced
              animation, creating custom solutions that make your brand stand
              out.
            </div>
            <div>
              We focus on collaborative processes and timely delivery, ensuring
              your vision is realized with creativity and precision, setting new
              benchmarks in your industry.
            </div>
            <br />
            <Learnmore />
          </div>
        </div>
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Innovative Design Solutions
          </div>
          <div className="fntuui">
            Elevate your brand with fresh, innovative designs that capture
            attention and set trends.
          </div>
          <div className="fntuui">
            Our approach combines creativity with strategic insight, ensuring
            each design is not only visually stunning but effectively
            communicates your unique message. Experience a fusion of art and
            market understanding that makes your brand stand out.
          </div>
          <Learnmore />
        </div>

        <img src={image7} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Expert Video Animation Services{" "}
          </div>
          <div className="fntuui">
            Our team excels in bringing narratives to life with,
            compelling animations.
          </div>
          <div className="fntuui">
            Specializing in both 2D and 3D animation services, we craft
            animations that tell your story with creativity and clarity. From
            intricate character animations to dynamic motion graphics, each
            frame is designed to engage and captivate.
          </div>
          <Learnmore />
        </div>

        <img src={image8} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Tailored Creative Approaches
          </div>
          <div className="fntuui">
            We believe in bespoke solutions for every project. Our process is
            adaptive, focusing on your specific goals and vision
          </div>
          <div className="fntuui">
            Collaborating closely, we ensure the final product is not just a
            visual treat but a true reflection of your ideas, resonating with
            your target audience.
          </div>
          <Learnmore />
        </div>

        <img src={image9} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Cutting-Edge Technology Use
          </div>
          <div className="fntuui mb-2">
          Leveraging the latest in design and animation technology, we craft innovative solutions that captivate audiences and drive results. 
          </div>
          <div className="fntuui mb-2">
          Our expertise in the latest tools and techniques empowers us to deliver visually stunning, highly engaging content that not only captures attention but also inspires action.
          </div>
          <div className="fntuui mb-2">Experience the future of visual storytelling with us.</div>
          <Learnmore />
        </div>

        <img src={image10} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Collaborative Client Involvement
          </div>
          <div className="fntuui">Your vision and input are crucial in our creative process.</div>
          <div className="fntuui">
            We emphasize collaboration, ensuring your ideas and feedback are
            integral from conception to completion. This partnership results in
            a product that is a true blend of professional expertise and your
            unique vision.
          </div>
          <Learnmore />
        </div>

        <img src={image11} />
      </div>

      <div className="content-creation-item flip mb-20">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Timely Project Delivery
          </div>
          <div className="fntuui">
            Recognizing the importance of deadlines, we are dedicated to
            delivering your projects on time without compromising quality.
          </div>
          <div className="fntuui">
            Our efficient and well-planned workflow guarantees that every phase
            of the project aligns with your timeline, ensuring readiness for
            your strategic launches or campaigns.
          </div>
          <Learnmore />
        </div>

        <img src={image12} />
      </div>

      <PartnersSaying
        items={[
          {
            text: "Absolutely thrilled with the animations! The attention to detail and seamless storytelling really brought our project to life. Highly recommend their services.",
            user: "Saife Ali",
          },

          {
            text: "Their design team transformed our brand image with exceptional creativity. The new logo and branding are spot-on and resonate with our audience.",
            user: "Khurram Shah",
          },

          {
            text: "Impressive turnaround time! They delivered high-quality motion graphics under a tight deadline without compromising on the intricate details and design quality.",
            user: "Ayesha Sheikh",
          },

          {
            text: "The level of interactivity and engagement in the web animations they created is outstanding. It has significantly improved our user experience and website traffic.",
            user: "Imran Alijah",
          },

          {
            text: "Their 3D animation services exceeded our expectations. The lifelike animations have added a new dimension to our educational content, making learning more engaging.",
            user: "Naiha Khan",
          },
        ]}
      />

      {/*       
  <div className="partners-saying">
    <Logo />
    <div className="partners-saying-title">What Our Partners Are Saying</div>
    <div className="partners-saying-desc">Absolutely thrilled with the animations! The attention to detail and seamless storytelling really brought our project to life. Highly recommend their services.</div>
      <div className="partners-saying-user">Saife Ali - Customer support</div>
</div> */}

      <div className="expectations">
        <div className="expactations-heading">
          <img src={icon9} />
          <div className="expect-title">Expectations </div>
        </div>

        <div className="expectations-items">
          <div className="expectation-item in-g-d-item1 graphics-1">
            <div className="expectation-item-title">Versatility</div>
            <div className="expectation-item-list">
              <div> Diverse Styles </div>
              <div> Multi-Platform Adaptability </div>
              <div> Wide Genre Range </div>
              <div> Flexible Design Solutions </div>
              <div> Varied Animation Techniques </div>
              <div> Cross-Media Compatibility </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item2 graphics-2">
            <div className="expectation-item-title">Professionalism</div>
            <div className="expectation-item-list">
              <div> Timely Delivery </div>
              <div> Expert Team </div>
              <div> Quality Assurance </div>
              <div> Effective Communication </div>
              <div> Client-Centric Approach </div>
              <div> Ethical Practices </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item3 graphics-3">
            <div className="expectation-item-title">Technical Excellence</div>
            <div className="expectation-item-list">
              <div> Advanced Animation </div>
              <div> Cutting-Edge Tools </div>
              <div> High-Resolution Graphics </div>
              <div> Interactive Design </div>
              <div> Fresh Visual Ideas </div>
              <div> State-of-the-Art Techniques </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-maximize-value in-content-creation">
        <div className="m-maximize-value-content">
          <div>Let's design & animate your dream project!</div>
          <div>
            Connect now to transform your vision into stunning, impactful
            designs and animations.
          </div>
          <div className="talkexpert pt-3">
            <Link to="/contact" className="linkexpert">
              Talk to Expert
              <i class='bx bx-headphone'></i>
            </Link>
          </div>
        </div>

        <img src={image4} />
      </div>

      <div className="insights">
        <div className="insights-title">
          <span>Blogs</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default GraphicDesign;
