import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import image from "../images/image3.png"
import image2 from "../images/image5.png"
import image3 from "../images/image6.png"
import image4 from "../images/image7.png"
import image5 from "../images/image8.png"
import image6 from "../images/image9.png"

import image7 from "../images/image10.png"
import image8 from "../images/image11.png"
import image9 from "../images/image12.png"
import image10 from "../images/image13.png"
import CustomTab from "./Tab";






import arrowRight from "../../medicalBilling/icons/arrow_right.svg";

import "./style.css"

export default function Tabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  console.log(value)

  const items = [
    {
      title: "Agriculture",
      desc: "AI & ML enhance agricultural practices, optimizing crop yields and resource usage through predictive analysis and smart farming techniques.",
      image: image,
    },
    {
      image: image2,
      title: "Manufacturing",  desc: "Revolutionize manufacturing with AI & ML, improving efficiency, quality control, and predictive maintenance for machinery and production processes."
    
    },
    {
      image: image3,

      title: "Retail",
      desc: "Transform retail experiences with AI & ML, offering personalized shopping, inventory optimization, and predictive trend analysis for customer satisfaction."
    },
    {
      image: image4,

      title: "Healthcare",
      desc: "AI & ML in healthcare provide advanced diagnostics, personalized treatment plans, and improved patient care through data-driven insights."
    },
    {
      image: image5,

      title: "Ecommerce",
      desc: "Elevate ecommerce with AI & ML, enabling personalized recommendations, optimized logistics, and enhanced customer engagement."
    },
    {
      image: image6,

      title: "Education",
      desc: "Companies working on machine learning are redefining education with personalized learning paths, automated grading, and interactive, adaptive educational tools."
    },
   
    { 
      image: image,

      title: "Automotive",
      desc: "The Automotive industry benefits from AI & ML in autonomous driving, enhanced safety features, and predictive vehicle maintenance.",
  
    },
    ];


    const items2 = [
   
  
    {
      image: image2,

      title: "Logistics",
      desc: "AI & ML streamline logistics operations, optimizing supply chain management, route planning, and real-time delivery tracking."
    },
  
    {
      image: image3,

      title: "Banking",
      desc: "The banking sector leverages AI & ML for fraud detection, risk assessment, personalized banking services, and customer data analysis."
  
    },
  
    {
      image: image4,

      title: "Stock Market",
      desc: "AI & ML transform stock market analysis with predictive algorithms, market trend analysis, and automated trading strategies."
    },
  
    {
      image: image5,
      
      title: "Travel & Tourism",
    desc: "Enhance travel and tourism experiences with AI & ML, offering personalized itineraries, automated booking systems, and customer service chatbots."
  },
  
    {
      image: image7,

      title: "Real Estate",
      desc: "AI & ML in real estate provide market analysis, predictive pricing, virtual property tours, and personalized property recommendations."
    },
  
    {
      image: image8,

      title: "Marketing",
      desc: "Revolutionize marketing with AI & ML, enabling targeted campaigns, customer behavior analysis, and ROI optimization."
    },
  
    {
      image: image9,

      title: "Transportation",
      desc: "AI & ML improve transportation systems through smart traffic management, route optimization, and predictive maintenance of vehicles."
    }
    ]
  

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider',
          }}>
          <TabList
            textColor='red'
            className='ai-tabs'
            variant='scrollable'
            
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#860404',
              height: 3
            },
          }} 
          
          onChange={handleChange} aria-label="lab API tabs example"
          
          >
            {items.map((item, index) => (
              <Tab 
            className='ai-tab'
            style={{
              color: "#7B7B7B", 
              fontWeight: "600",
              textTransform: "capitalize",
              fontSize: "larger",
              letterSpacing: "1.1px",
              marginRight: "20px",
              position: "relative",
              fontFamily: "Poppins"


            }}
          
            label={item.title} value={String(index+1)} />
            ))}
            
          
          </TabList>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider',
          }}>
          <TabList
            textColor='red'
            className='ai-tabs'
            variant='scrollable'
            
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#860404',
              height: 3


            },
          }} 
          
          onChange={handleChange} aria-label="lab API tabs example"
          
          >
            {items2.map((item, index) => (
              <Tab 
            className='ai-tab'
            style={{
              color: "#7B7B7B", 
              fontWeight: "700",
              textTransform: "capitalize",
              fontSize: "larger",
              letterSpacing: "1.1px",
              marginRight: "20px",
              position: "relative",
              fontFamily: "Poppins"

            }}
          
            label={item.title} value={String((items.length) + index  +1)} />
            ))}
            
          
          </TabList>
        </Box>


        {[...items, ...items2].map((el, index )=> ( <CustomTab el={el} index={index} />
          
            //  <TabPanel
            //  className='ai-tab'
            //  value={String(index+1)}>
            //      <div className='ai-tab-contnet'>
            //          <div>
            //            <div className='ai-tab-contnet-text'>{el.desc}</div>
            //            <button className="learn-more-btn2">
            //          Learn more
            //          <img src={arrowRight} />
            //        </button>
            //          </div>
            //          <img className='ai-tab-image' src={el.image} />
            //      </div>
            //  </TabPanel>
        ))}
     
       
      </TabContext>
    </Box>
  );
}
