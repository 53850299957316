import "./style.css";

import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";
import image5 from "./images/image5.png";
import image6 from "./images/image6.png";

import image7 from "./images/image7.png";
import image8 from "./images/image8.png";
import image9 from "./images/image9.png";

import image10 from "./images/image10.png";
import image11 from "./images/image11.png";

import icon from "./icons/icon.png";
import icon2_3 from "./icons/benefits1.png"; 
import icon3_3 from "./icons/benefits-2.png";
import icon4_4 from "./icons/benefits-3.png"; 

import automaticsli1 from "./images/smothtabs1.png";
import automaticsli2 from "./images/smothtabs2.png";
import automaticsli3 from "./images/smothtabs3.png";
import automaticsli4 from "./images/smothtabs4.png";

import Learnmore from "./component/Learnmore.js";
import OurPartners from "./component/OurPartners/OurPartners.jsx"
import { Link } from "react-router-dom";




import icon2 from "./icons/icon2.png";
import arrowRightWhite from "./icons/arrow_right_white.svg";
import arrowRight from "../medicalBilling/icons/arrow_right.svg";

import headPhone from "../medicalBilling/icons/headset_mic.svg";

import TopSection from "../../components/TopSection";
import Items from "../mobilAppDevelopement/PremiumExpertise/Items/Items";
//import OurPartners from "../mobilAppDevelopement/OurPartners/OurPartners";

import AutoCounter from "../about/component/Countdown1.js";
import { useEffect, useState } from "react";

import AutoCounter4 from "./component/Countdown1.jsx";
import {Helmet} from "react-helmet";


import bgSideIcon1 from "../images/bg_side_icon1.png"
import bgSideIcon2 from "../images/bg-side-icon2.png"
import bgSideIcon3 from "../images/bg-side-icon3.png"
import bgSidecorner from "./images/corner-bg.png"




const offersItems = [
  {
    icon: icon,
    column: 'web-3',
    class: "in-b-m",
    title: "Time & Cost Saving",
    desc: "Our small business management services efficiently cut costs and time, significantly reducing overheads and speeding up your operations.",
  },
  {
    icon: icon2_3,
    column: 'web-3',
    class: "in-b-m",

    title: "Increased Efficiency",
    desc: "Outsourcing custom business development services means revolutionizing your business operations, achieving peak efficiency, and seamlessly smooth workflows.",
  },
  {
    icon: icon3_3,
    column: 'web-3',
    class: "in-b-m",

    title: "Stronger Team Dynamics",
    desc: "We excel in sourcing top-tier industry talent, and building your team with a focus on strong work ethics and a collaborative spirit.",
  },

  {
    icon: icon4_4,
    column: 'web-3',
    class: "in-b-m",

    title: "High-performance Outcomes",
    desc: "We take care of the details, freeing you to focus on your business's key objectives, and ensuring smooth and successful outcomes.",
  },
];

function BusinessManagment() {
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const fadeOutTimeout = setTimeout(() => {
      // Move to the next slide after fade-out

      setCurrentSlide((prevSlide) => (prevSlide % 4) + 1);
    }, 3000); // Set the timeout for fade-out (adjust as needed)

    return () => {
      clearTimeout(fadeOutTimeout);
      // clearTimeout(opacityTimeout);
    };
  }, [currentSlide]);

  const images = [automaticsli1, automaticsli2, automaticsli3, automaticsli4];

  return (
    
      <>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Business Management Services - Appedology.pk</title>
                <meta name="description" content="Looking for a company to manage your business for you? Our team of expert business managers is skilled in doubling your growth & exposure!" />
                <link rel="canonical" href="https://appedology.pk/business-management" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Business Management Services - Appedology.pk" />
                <meta property="og:description" content="Looking for a company to manage your business for you? Our team of expert business managers is skilled in doubling your growth & exposure!" />
                <meta property="og:url" content="" />
                <meta property="og:locale" content= "pakistan" />
                <meta property="og:site_name" content="Appedology" />
                <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
            </Helmet>
      


    <div className="business-management">
      <TopSection
        subtitle="A 360° Business Management Suite "
        title="All-Round Answer for Your Business Needs"
        desc="We are the go-to managed business services provider for 55+ companies worldwide, who choose us for all things HR and workforce management. Trust us to streamline operations, enhance efficiency, and drive growth. "
        image={image1}
      />
      <div className="business-management-main">
        <div className="features-and-benefits">
          <div className="title1 mb-4">HR to Audits </div>
          <div className="features-and-benefits-header">
            A Full Spectrum of Business Solutions
          </div>
          <div className="features-and-benefits-items">
            <div className="features-and-benefits-item mt-sm-5">
              <div className="title3">HR Management</div>
              <img src={image2} alt=""/>
              <div className="desc">
                Optimize your workforce with our HR management services,
                ensuring efficient operations and a dream team.
              </div>
            </div>
            <div className="features-and-benefits-item mt-3 mt-sm-5">
              <div className="title3">Accounting Services</div>
              <img src={image7} alt="" />
              <div className="desc">
                Our accounting services provide precise financial management,
                keeping your business on track and profitable.
              </div>
            </div>
            <div className="features-and-benefits-item mt-3 mt-sm-5">
              <div className="title3">Financial Analysis</div>
              <img src={image8} alt=""/>
              <div className="desc">
                Gain insightful financial analysis to make informed decisions,
                driving your business towards sustainable growth.
              </div>
            </div>
            <div className="features-and-benefits-item mt-3 mt-sm-5">
              <div className="title3">Audit Services</div>
              <img src={image9} alt=""/>
              <div className="desc">
                Our thorough audit services are your way to accurate financial
                reporting and compliance, safeguarding your business's
                integrity.
              </div>
            </div>
          </div>
         <Learnmore />
        </div>
        <div className="benefits-of-business">
          <div className="insights-title in-b-m">
            <span>Features & Benefits</span>
          </div>
          <div className="featured_benefits">
            <Items items={offersItems} />
                <div className="mx-auto col-md-12 text-center btn_feature">
                  <Learnmore />
                </div>
          </div>
        </div>
        <div className="b-m-featured">
          <div className="b-m-featured-header">
            <div className="b-m-featured-title pb-4">
              Concerned About Adapting to Market Changes?{" "}
            </div>
            <div className="b-m-featured-desc col-md-12 mx-auto text-center">
              Appedology stands at the forefront of business management. Our
              seasoned team of business service management specializes in custom
              solutions for diverse company sizes and needs. We are a team of
              strategic planners who streamline business operations to keep your
              business agile and responsive!
            </div>
          </div>
          <div>
            <div className="main_box4 position-relative">
              <div className="halfleft agency-item-heading bg_red radius_heading_left float-start">
                <strong className="headingstrong text-white d-flex h-100 justify-content-center align-items-center pe-4">
                Strategic Planning
                </strong>
              </div>
              <div className="halfright in-sales float-start"></div>
              <div className="p-0 container-fluid align-items-center agency-item-container  d-flex flex-column flex-md-row pt-4 pb-5 position-relative">
                <div className="col-md-6 col-lg-6 p-0">
                  <div className="pt-5 p-4 agency_item in-sales">
                    <ul className="list_item agency-item in-sales bigitem w-75 mx-auto">
                      <li>We identify key business objectives</li>
                      <li>Customize growth strategies</li>
                      {/* <li>Digital Marketing and SEO</li> */}
                      <li>Optimize operational efficiency</li>
                      <li>Forecast market trends</li>
                      <li>Enhance competitive edge</li>
                      <li>Streamline resource allocation</li>
                      <li>Track progress closely</li>
                      <Learnmore />
                     
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 p-0 agency-item-image-container">
                  <div className="d-flex justify-content-end">
                    <img
                      className="img-fluid agency-item-image"
                      src={image3}
                      alt="leadgeneration"
                    />
                  </div>
                </div>
              </div>
              <img className="bg-side-icon bg-side-icon-left" src={bgSideIcon1} alt="" />
            </div>
            <div className="main_box4 position-relative">
              <div className="halfright in-sales float-start"></div>
              <div className="halfleft agency-item-heading bg_red radius_heading_right float-start">
                <strong className="headingstrong text-white d-flex h-100 justify-content-center align-items-center ps-4">
                  Client Management
                </strong>
              </div>
              <div className="p-0 container-fluid align-items-center agency-item-container d-flex pt-4 pb-5 position-relative">
                
                    <div className="col-md-6 p-0 agency-item-image-container">
                      <div className="d-flex justify-content-start">
                        <img
                          className="img-fluid agency-item-image"
                          src={image10}
                          alt="leadgeneration"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 p-0">
                      <div className="pt-5 p-4 agency_item in-sales">
                        <ul className="list_item agency-item in-sales bigitem w-100 mx-auto ps-5">
                          <li>Personalized CRM interaction</li>
                          <li>Optimized client lifecycle </li>
                          <li>Multi-channel support</li>
                          <li>Client data and insights management</li>
                          <li>Nurture client trust</li>
                          <li>Execute targeted client outreach</li>
                          <Learnmore />
                        </ul>
                      </div>
                    
                </div>
              </div>
              <img className="bg-side-icon bg-side-icon-right" src={bgSideIcon2} alt="" />
            
            </div>
            <div className="main_box4 position-relative">
              <div className="halfleft agency-item-heading bg_red radius_heading_left float-start">
                <strong className="headingstrong text-white d-flex h-100 justify-content-center align-items-center pe-4">
                  Project Management
                </strong>
              </div>
              <div className="halfright in-sales float-start"></div>
              <div className="p-0 container-fluid align-items-center agency-item-container d-flex pt-4 pb-5 position-relative">
                
                    <div className="col-md-6 p-0">
                      <div className="pt-5 p-4 agency_item in-sales">
                        <ul className="list_item agency-item in-sales bigitem w-75 mx-auto">
                          <li>Plan & develop upcoming projects</li>
                          <li>Build & guide teams</li>
                          <li>Track progress and ensure timely deliveries</li>
                          <li>Work closely with stakeholders to achieve goals</li>
                          <li>Assess project performance</li>
                          <li>Oversee financial management</li>
                          <li>Address and resolve challenges</li>
                          <Learnmore />
                        </ul>
                        {/* <p className="text-left mx-auto w-75">
                          <a
                            href="/"
                            className="learnmoregrey text-decoration-none text-white mt-3 rounded-pill"
                          >
                            LEARN MORE
                          </a>
                        </p> */}
                      </div>
                    </div>
                    <div className="col-md-6 p-0 agency-item-image-container">
                      <div className="d-flex justify-content-end">
                        <img
                          className="img-fluid agency-item-image"
                          src={image4}
                          alt="leadgeneration"
                        />
                      </div>
                    </div>
                    
              </div>
              <img className="bg-side-icon bg-side-icon-left" src={bgSideIcon1} alt="" />

            </div>
            <div className="main_box4 position-relative">
              <div className="halfright in-sales float-start"></div>
              <div className="halfleft agency-item-heading bg_red radius_heading_right float-start">
                <strong className="headingstrong text-white d-flex h-100 justify-content-center align-items-center ps-4">
                  Remote Support
                </strong>
              </div>
              <div className="p-0 container-fluid align-items-center agency-item-container d-flex pt-4 pb-5 position-relative">
                
                    <div className="col-md-6 p-0 agency-item-image-container">
                      <div className="d-flex justify-content-start">
                        <img
                          className="img-fluid agency-item-image"
                          src={image11}
                          alt="leadgeneration"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 p-0">
                      <div className="pt-5 p-4 agency_item in-sales">
                        <ul className="list_item agency-item in-sales bigitem w-100 mx-auto ps-5">
                          <li>Data entry & online chat support</li>
                          <li>Digital marketing and social media management</li>
                          <li>Cloud-based accounting and finance services</li>
                          <li>Remote sales and lead generation</li>
                          <li>IT and technical support</li>
                          <li>Offsite supply chain coordination</li>
                          <li>Graphic design and multimedia services</li>
                          <Learnmore />
                        </ul>

                        {/* <p className="text-left mx-auto w-100 ps-5">
                          <a
                            href="/"
                            className="learnmoregrey text-decoration-none text-white mt-3 rounded-pill"
                          >
                          
                          </a> */}
                        {/* </p> */}
                      </div>
                    
              </div>

              </div>
              <img className="bg-side-icon bg-side-icon-right2" src={bgSidecorner} alt="" />

            </div>
          </div>
        </div>
          <div className="container">
              <OurPartners />
          </div>
        <div className="our-process">
          <h1 className="h1 mainheading in-b-m text-center position-relative">
            <span className="ps-5 pe-5">Our Process</span>
          </h1>
          <div className="our-process-content pt-5">
            <div>
              <img className="our-process-logo" src={icon2} alt=""/>
              <div className="our-process-content-title pb-3 lh-base">
                Business Process Outsourcing
              </div>
              <div className="our-process-content-desc lh-base">
                Appedology, more than a service provider, is your strategic
                business ally. We turn challenges into growth opportunities and
                exceed goals, transforming aspirations into achievements.
              </div>
            </div>
            <img src={image6}  alt=""/>
          </div>
        </div>

        <div className="depends-on p-5">
          <div className="depends-on-title">
            Drive Growth, Cut Costs, and Boost Productivity
          </div>
          <div className="depends-on-desc col-md-7 mx-auto lh-base">
            Appedology, a technologically advanced hub for business management
            services, offers tailored business management solutions aimed at
            reducing costs and enhancing productivity for scalable growth.
          </div>
          <Link className="btn p-3 bg-transparent text-white representative" to="/contact">
            Contact Our Representatives
            <i class='bx bx-right-arrow-alt ms-3'></i>
          </Link>
        </div>

        <div className="happyclient pt-5 pb-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={500} interval={10} spanContnet="+" />
                  </h2>
                  <p className="text-uppercase pt-3">Successful Projects</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={99} interval={30} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">Client Satisfaction</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={22} interval={400} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">Higher Profit Margins</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="client text-center">
                <h2 className="mb-0">
                    <AutoCounter4 limit={30} interval={400} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-2">Increase in sales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="resilient-business pt-5">
          <div className="resilient-business-title">
            Give an Edge to your <span className="redgradient">Business Horizon</span>
          </div>
          <div className="resilient-business-desc pb-3">
            Streamline your business plans & operations with our expert
            outsourcing solutions, tailored for growth, efficiency, and
            competitive advantage.
          </div>
          <div> 
            <div>
              <div>
                <div className="resilient-business-content-title pb-1">
                  Access to Skilled Professionals
                </div>
                <div className="resilient-business-content-desc lh-base">
                  Tap into a vast network of skilled professionals, bringing
                  specialized expertise to your business.
                </div>
              </div>

              <div>
                <div className="resilient-business-content-title">
                  Business Size Flexibility
                </div>
                <div className="resilient-business-content-desc lh-base">
                  We easily scale with your business, up or down, ensuring
                  agility and responsiveness every step of the way.
                </div>
              </div>

              <div>
                <div className="resilient-business-content-title">
                  24/7 Global Operations
                </div>
                <div className="resilient-business-content-desc lh-base">
                  Our operations know no boundaries. We are available 24/7,
                  ensuring your operations thrive across all time zones.
                </div>
              </div>

              <div>
                <div className="resilient-business-content-title">
                  Customized Pricing Plans
                </div>
                <div className="resilient-business-content-desc lh-base">
                  The best part, we tailor service packages according to the
                  services you need, with no additional costs or employee
                  benefits.
                </div>
              </div>

              <button className="learn-more-btn our-case">
                Our Case Studies
              </button>
            </div>

            <div
              className="resilient-business-image-container"
              style={{ backgroundImage: `url(${images[currentSlide - 1]})` }}
            ></div>

            {/* <img src={image3} /> */}
            {/* <img src={image10} />
            <img src={image4} />
            <img src={image11} /> */}
          </div>
        </div>

        <div className="m-maximize-value mb-5">
          <div className="m-maximize-value-content">
            <div className="lh-base">Is Your Business Reaching Its Full Potential?</div>
            <div>
              Our managed business services cover everything from staffing to
              decision making. Let's talk and make it happen!
            </div>
            <div className="ps-5 pe-5">
              Contact Now
              <img src={headPhone} alt=""/>
            </div>
          </div>

          <img src={image5} alt=""/>
        </div>

        <div className="insights mt-5 pb-5 mb-0">
          <div className="insights-title mt-5">
            <span className="pt-5">Blogs</span>
          </div>
          <div className="insights-desc">
            Uncover opportunities with our expertise.
          </div>

          <div className="insight-items mt-5 mb-5 pb-5">
            <div
              className="insight-item"
              style={{
                backgroundImage: `url(${image1})`,
                backgroundSize: "cover",
              }}
            >
              <div className="lh-base">
                Navigating the Network and Cloud Computing Landscape: Challenges
                and Opportunities for Business Transformation.
              </div>
            </div>

            <div
              className="insight-item"
              style={{
                backgroundImage: `url(${image2})`,
                backgroundSize: "cover",
              }}
            >
             <div className="lh-base">
                Navigating the Network and Cloud Computing Landscape: Challenges
                and Opportunities for Business Transformation.
              </div>
            </div>

            <div
              className="insight-item"
              style={{
                backgroundImage: `url(${image4})`,
                backgroundSize: "cover",
              }}
            >
             <div className="lh-base">
                Navigating the Network and Cloud Computing Landscape: Challenges
                and Opportunities for Business Transformation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default BusinessManagment;
