import React from 'react'
import { Link } from "react-router-dom";

function Learnmore() {
  return (
    <>
        <Link className="btn btn-outline-primary mt-3 btnblack"  to="/contact">
            LEARN MORE
        </Link>
    </>
  )
}

export default Learnmore