import "./style.css";

import TopSection from "../../components/TopSection";
import image from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";

// import image4 from "./images/image4.jpg";
// import image5 from "./images/image5.jpg";
// import image6 from "./images/image6.jpg";

// import image13 from "./images/image6.jpg";

// import image14 from "./images/image14.png";
// import image15 from "./images/image15.png";
import tabs1 from "./images/tabs1.png";
import tabs2 from "./images/tabs2.png";
import tabs3 from "./images/tabs3.png";
import tabs4 from "./images/tabs4.png";
import tabs5 from "./images/tabs5.png";


import icon from "../ContentCreation/icons/icon.png";
import icon2 from "../ContentCreation/icons/icon2.png";
import icon3 from "../ContentCreation/icons/icon3.png";
import icon4 from "../ContentCreation/icons/icon4.png";
import icon5 from "../ContentCreation/icons/icon5.png";
import icon6 from "../ContentCreation/icons/icon6.png";
import icon7 from "../ContentCreation/icons/icon7.png";
import icon9 from "../ContentCreation/icons/icon9.png";

import Learnmore from "../BusinessmManagement/component/Learnmore";

import icon10 from "./icons/icon1.png";
import icon11 from "./icons/icon2.png";
import icon12 from "./icons/icon3.png";
import icon13 from "./icons/icon4.png";
import icon14 from "./icons/icon5.png";
import icon15 from "./icons/icon6.png";
import icon16 from "./icons/icon7.png";
import icon17 from "./icons/icon8.png";


import icon18 from "./icons/icon10.png";
import icon19 from "./icons/icon11.png";
import icon20 from "./icons/icon12.png";
import icon21 from "./icons/icon13.png";
import icon22 from "./icons/icon9.png";






import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.png";
import brand5 from "../images/brand5.png";
import brand6 from "../images/brand6.png";


import image5 from "./images/1.png";
import image6 from "./images/image2.png";
import image7 from "./images/image7.png";
import image8 from "./images/image8.png";
import image9 from "./images/image9.png";

import image10 from "./images/image10.png";
import image11 from "./images/image11.png";





import AutoCounter from "../about/component/Countdown1.js";

import arrowRight from "../ContentCreation/icons/arrow_right.svg";
import headPhone from "../ContentCreation/icons/headset_mic.svg";
import Logo from "../../images/Logo.jsx";
import OurServices from "../../components/OurServices/index.jsx";
import PartnersSaying from "../../components/ParnersSying/index.jsx";
import {Helmet} from "react-helmet";

function SEO() {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>SEO & Marketing Services in Pakistan - Appedology.pk</title>
          <meta name="description" content="Make your brand climb at the top of search results with our full suite of digital marketing services! ✔SEO ✔PPC ✔Email Marketing ✔ORM" />
          <link rel="canonical" href="https://appedology.pk/seo-and-marketing"/>
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="SEO & Marketing Services in Pakistan - Appedology.pk" />
          <meta property="og:description" content="Make your brand climb at the top of search results with our full suite of digital marketing services! ✔SEO ✔PPC ✔Email Marketing ✔ORM" />
          <meta property="og:url" content="" />
          <meta property="og:locale" content= "pakistan" />
          <meta property="og:site_name" content="Appedology" />
          <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />
      </Helmet>
    <div className="content-creation">
      <TopSection
        subtitle="SEO and Marketing Companies"
        title="Does Your Brand Have the Top Spot It Deserves?"
        desc="Explore if your brand is in its rightful place and boost your online presence through our SEO and digital marketing services. We are all about drawing new customers and generating qualified leads. "
        btn={{
          text: "Schedule Call",
          link: "/contact",
        }}
        image={image}
      />

      <div className="content-creation-content">
        <div className="medical-billing-intro pt-5 pb-5">
          <div className="medical-billing-intro-title col-md-10 mx-auto">
            We Have Helped 500+ Businesses Rank<span> High on Search
            Engines</span>
          </div>
          <div className="medical-billing-intro-desc">
            We understand the challenge of juggling core business tasks while
            striving for top search engine rankings. That's where our expertise
            comes in! Having elevated 500+ businesses to prominent online
            positions, our SEO marketing and web services ensure you stay ahead
            of your competitors in the competitive digital landscape.
          </div>
        </div>

        <div className="happyclient pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={15} interval={100} spanContnet="k+" />
                  </h2>
                  <p className="text-uppercase pt-3">Satisfied Clients </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={70} interval={50} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">Top 10 Searches</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="client text-center">
                  <h2>
                    <AutoCounter limit={65} interval={200} spanContnet="%" />
                  </h2>
                  <p className="text-uppercase pt-3">Conversion Rate</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <OurServices
          additionalClass="in-content-creation"
          title="Wondering why your website isn't showing up in online searches? 
          We've got you covered!"
          icons={[
            {
              icon: icon22,
              title: "Keyword Optimization",
            },

            {
              icon: icon18,
              title: "On-Page SEO",
            },
            {
              icon: icon19,
              title: "Off-Page SEO",
            },
            {
              icon: icon20,
              title: "Technical SEO",
            },
            {
              icon: icon21,
              title: "Local SEO",
            },
          ]}
          images={[tabs1, tabs2, tabs3, tabs4, tabs5]}
          items={[
            [
              "Keyword Research",
              "Content Analysis",
              "Search Trends",
              "Long-Tail Keywords",
              "Competitor Keywords",
            ],

            [
              "Meta Tags",
              "Header Tags",
              "Page Speed",
              "Image Optimization",
              "URL Structure",
            ],
            [
              "Backlink Building",
              "Social Media",
              "Influencer Outreach",
              "Guest Blogging",
              "Directory Submission",
            ],

            [
              "Site Audit",
              "Mobile-Friendly",
              "Schema Markup",
              "XML Sitemaps",
              "HTTPS Migration",
            ],

            [
              "Google My Business",
              "Local Listings",
              "Map Optimization",
              "Local Keywords",
              "Review Management",
            ],
          ]}
        />

        {/* <div className="m-our-services in-content-creation">
          <div className="m-our-services-title in-content-creation">
            What do you get when you use the no. 1 graphic designing platform??
          </div>
          <div className="m-our-services-items">
            <div className="m-our-services-item">
              <img src={icon} />
              <div>Claim Management</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon2} />
              <div>Eligibility Verification</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon3} />
              <div>Medical Coding</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon4} />
              <div>Compliance Support</div>
            </div>

            <div className="m-our-services-item">
              <img src={icon5} />
              <div>AR Management</div>
            </div>
          </div>

          <div className="m-our-services-content">
            <div>
              <ul>
                <li>Defining Sales Goals and Objectives</li>
                <li>Understanding the Target Market</li>
                <li>Creating a Value Proposition</li>
                <li>Choosing Sales Channels</li>
                <li>Developing Sales Processes and Techniques</li>
                <li>Training and Equipping the Sales Team</li>
              </ul>
              <button className="learn-more-btn2">
                Learn more
                <img src={arrowRight} />
              </button>
            </div>
            <img src={image3} />
          </div>
        </div> */}
      </div>

      <div className="brands-container">
        <div className="title1">200k+ brands trust Appedology</div>

        <div className="brands-icons">
          <img src={brand1} />
          <img src={brand2} />
          <img src={brand3} />
          <img src={brand4} />
          <img src={brand5} />
          <img src={brand6} />
        </div>
      </div>

      <div className="why-use-content">
        <div className="title-with-befor-and-after in-content-creation">
          <span>Grow your Business with Appedology</span>
        </div>

        <div className="">
          <div>
            <div>
              In today's digital world, having a strong online presence is
              crucial for business success. Our SEO and internet marketing
              services are the key to unlocking this potential. Think of SEO as
              a roadmap that guides customers to your website. By optimizing
              your site, we ensure it ranks high on search engines, making it
              easier for potential customers to find you.
            </div>
            <div>
              Our marketing strategies engage these visitors, converting them
              into loyal customers. We focus on understanding your unique
              business needs, tailoring our approach to not just attract more
              traffic, but the right kind of traffic. This leads to increased
              sales, sustained growth, and a robust online presence, all
              essential for thriving in the modern marketplace.
            </div>
            {/* <Learnmore /> */}
          </div>
          <img src={image5} />
        </div>
      </div>

      <div className="effectoive-designing">
        <div className="effectoive-designing-title title2">
          Marketing Answers to Your Business Challenges
        </div>
        <div className="effectoive-designing-items">
          <div className="effectoive-designing-item">
            <div> <img src={icon10} /></div>
            <div>Local SEO</div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon11} /></div>
            <div>Content Marketing</div>
          </div>
          <div className="effectoive-designing-item">
            <div> <img src={icon12} /></div>
            <div>PPC Advertising</div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon13} /></div>
            <div>Conversion Rate Optimization</div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon14} /></div>
            <div>Analytics</div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon15} /></div>
            <div>Email Marketing</div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon16} /></div>
            <div>Online Reputation Management </div>
          </div>

          <div className="effectoive-designing-item">
            <div> <img src={icon17} /></div>
            <div>Keyword Research</div>
          </div>
        </div>
        <Learnmore />
      </div>

      <div className="why-choose-content-creation">
        <div className="title2">Why Marketing and SEO Services Are Important for Your Business?</div>
        <div className="why-choose-content-creation-content">
          <img src={image6} />
          <div>
            <div>
              Have you ever wondered why SEO is such a big deal for businesses
              today? Let us break it down for you. Imagine your business is a
              treasure, and SEO is the map that guides people to it. In the vast
              world of the internet, where countless websites are competing for
              attention, SEO helps your website stand out. It's like having a
              bright neon sign pointing to your business on a busy street.
            </div>
            <div>
              By optimizing your site for search engines, you're essentially
              making it easier for potential customers to find you. And when
              they find you easily, that's where the magic happens – more
              visits, more customers, and more growth for your business. In
              today's fast paced world, SEO and online marketing services are
              not just about being online; it's about being found!
            </div>
          </div>
        </div>
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title digitaljourpara">
            <div className="content-creation-item-content-title">We Master the Top Search Keywords</div>
          </div>
          <div className="digitaljourpara">
            Oftentimes, being unaware of what your prospects are searching for
            and how to reach them can be a challenge. That's where turning to
            Appedology becomes your ideal solution. We analyze the most
            in-demand keywords and understand what your potential customers are
            looking for. Our expertise lies in identifying these key search
            terms and integrating them seamlessly into your content.
          </div>
          <div className="digitaljourpara">
            This strategy bridges the gap between your business and those
            searching for it online. We don't just stop at finding the right
            keywords; we ensure they are effectively utilized to elevate your
            online presence. By partnering with us, you're ensuring that your
            business is not only visible but also resonating with the exact
            audience you want to attract, leading to increased engagement and
            growth.
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image7} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Ahead of the Curve: Services for Every Industry
          </div>
          <div className="digitaljourpara">
            Our digital marketing and SEO company caters to every industry out
            there. Whether you're in retail, healthcare, technology, education,
            or any other field, we've got you covered.
          </div>
          <div className="digitaljourpara">
            We understand the unique challenges and opportunities each industry
            faces. That's why we tailor our strategies to fit your specific
            needs, ensuring that no matter your sector, our services will help
            you thrive. With us, you get expertise that adapts to your industry,
            driving success in your specific market.
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image8} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Customized Marketing Plans
          </div>
          <div className="digitaljourpara">
          At the heart of our approach is the belief that your business deserves a marketing strategy as unique as you are. 
          </div>
          <div className="digitaljourpara">
          We reject the one-size-fits-all mindset, opting instead for a personalized touch. Our process begins with a deep dive into the needs of your business, your aspirations, and who your audience is. 
          </div>
          <div className="digitaljourpara">Armed with this insight, we craft a bespoke marketing plan tailored to your specific goals. This means every step we take is aligned with propelling your business to new heights. </div>
          <div className="digitaljourpara">Partner with us for a marketing strategy that’s not just custom-made, but also a perfect fit for your brand's unique narrative and vision.</div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image9} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Comprehensive SEO and Marketing Solutions
          </div>
          <div className="digitaljourpara">
            In the digital world, standing out is key, and that's where our
            comprehensive SEO and digital marketing services come in.
          </div>
          <div className="digitaljourpara">
            We tailor our approach to fit your unique business needs, ensuring
            your website not only ranks high in search engine results but also
            engages and captivates your audience. From thorough keyword research
            to creating compelling content and effective online marketing
            strategies, we cover all bases. Our goal is to boost your online
            visibility and attract more customers, helping your business thrive
            in the bustling digital marketplace.
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image10} />
      </div>

      <div className="content-creation-item">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Thorough SEO Audit
          </div>
          <div className="digitaljourpara">
            Our SEO audit services offer a deep dive into your website's
            performance. We meticulously analyze every element - from keywords
            to content structure, and from backlinks to site speed. Our goal? To
            pinpoint exactly where your website excels and where it needs
            improvement. We don't just stop at identifying issues; we provide
            actionable solutions tailored to your specific needs.
          </div>
          <div className="digitaljourpara">
            This comprehensive audit lays the groundwork for a robust SEO
            strategy, ensuring your site is fully optimized for search engines
            and ready to attract more traffic. With our audit, you'll gain
            insights that empower you to make informed decisions, driving better
            results and online success.
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image11} />
      </div>

      <div className="content-creation-item flip">
        <div className="content-creation-item-content">
          <div className="content-creation-item-content-title">
            Clear and Transparent Analytics
          </div>
          <div className="digitaljourpara">
            We understand that data and analytics might seem overwhelming, but
            we're here to make them easy for you. Appedology is a digital
            marketing and SEO company focused on simplifying processes for our
            clients. With us, you receive clear and transparent analytics that
            are easy for anyone to understand.
          </div>
          <div className="digitaljourpara">
            We work as your personal guide through the world of online data. Our
            team simplifies the complex stuff, showing you exactly what you need
            to know: how your website is doing, what's working, and where we can
            make things better. No jargon, just clear-cut insights that help
            your business grow online. With us, you'll feel confident about your
            digital strategy, supported by data that makes sense.
          </div>
          {/* <button className="learn-more-btn">LEARN MORE</button> */}
        </div>

        <img src={image5} />
      </div>

        <div className="pt-4">
      <PartnersSaying
        title="Customer Testimonials"
        items={[
          {
            text: "Their SEO audit was a game-changer for our website. Insightful, detailed, and actionable – saw improvements in weeks! ",
            user: "Alex Jr.",
          },

          {
            text: "I'm truly impressed with their personalized approach to our marketing needs. Since we started working with them, we've seen a significant boost in our online engagement and customer interactions.",
            user: "Maria Ali",
          },

          {
            text: "Their team is professional, efficient, and results-driven. The SEO strategies they implemented have led to a remarkable increase in traffic to our website. Highly recommended!",
            user: " Taqueer Abbas",
          },

          {
            text: "This team is reliable and maintains transparency throughout the process. Keeping us informed at every step, their efforts have been invaluable.",
            user: " Sumair A. Khan",
          },

          {
            text: "Appedology always offers exceptional service whenever we need it! Their marketing expertise has been instrumental in helping us expand our online presence and effectively reach a wider audience.",
            user: "Sarah Sulaiman",
          },
        ]}
      />
      </div>
      {/*       
  <div className="partners-saying">
    <Logo />
    <div className="partners-saying-title">What Our Partners Are Saying</div>
    <div className="partners-saying-desc">Absolutely thrilled with the animations! The attention to detail and seamless storytelling really brought our project to life. Highly recommend their services.</div>
      <div className="partners-saying-user">Saife Ali - Customer support</div>
</div> */}

      <div className="expectations">
        <div className="expactations-heading">
          <img src={icon9} />
          <div className="expect-title">Expectations</div>
        </div>

        <div className="expectations-items">
          <div className="expectation-item in-g-d-item1 seohov1">
            <div className="expectation-item-title">Strategic Planning</div>
            <div className="expectation-item-list">
              <div> Market Analysis </div>
              <div> Competitor Benchmarking </div>
              <div> Audience Segmentation </div>
              <div> Goal Setting </div>
              <div> Brand Positioning </div>
              <div> Targeted Ads Campaign </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item2 seohov2">
            <div className="expectation-item-title">Content Optimization</div>
            <div className="expectation-item-list">
              <div> Keyword Research </div>
              <div> SEO Copywriting </div>
              <div> Visual Content </div>
              <div> Site Structuring </div>
              <div> Metadata Enhancement </div>
              <div> User Experience </div>
            </div>
          </div>

          <div className="expectation-item in-g-d-item3 seohov3">
            <div className="expectation-item-title">Performance Tracking</div>
            <div className="expectation-item-list">
              <div> Traffic Monitoring </div>
              <div> Conversion Tracking </div>
              <div> Engagement Analysis </div>
              <div> ROI Measurement </div>
              <div> Report Generation </div>
              <div> Trend Identification </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-maximize-value in-content-creation">
        <div className="m-maximize-value-content">
          <div>Your road to growth is just clicks away!</div>
          <div>
            Grow your online presence with Appedology. Our expert marketing &
            SEO services await you!
          </div>
          <div>
            Get Started
            <img src={headPhone} />
          </div>
        </div>

        <img src={image4} />
      </div>

      <div className="insights">
        <div className="insights-title">
          <span>Blogs</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SEO;
