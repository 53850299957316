import TopSection from "../../components/TopSection";
import "./style.css";
import image1 from "./images/image1.svg";
import image3 from "./images/imag3.svg";
import image4 from "./images/image4.svg";

import IconContainer from "./icons/IconContainer";

import icon1 from "./icons/icon1.svg";
import icon2 from "./icons/icon2.svg";
import icon3 from "./icons/icon3.svg";
import icon4 from "./icons/icon4.svg";

import icon5 from "./icons/icon5.svg";
import icon6 from "./icons/icon6.svg";
import icon7 from "./icons/icon7.svg";
import icon8 from "./icons/icon8.svg";

import icon9 from "./icons/icon9.svg";
import icon10 from "./icons/icon10.svg";
import icon11 from "./icons/icon11.svg";
import icon12 from "./icons/icon12.svg";

import video1 from "./videos/video.mp4";
import video2 from "./videos/video2.mp4";
import video3 from "./videos/video4.mp4";
import VideoItem from "./VideoItem";

import TechStack from "../mobilAppDevelopement/TeckStack/TechStack";

function GameDev() {
  return (
    <div className="game-dev">
      <TopSection
        image={image1}
        title="FROM CONCEPT TO CREATION"
        subtitle="MOBILE GAME DEVELOPMENT COMPANY"
        desc="Let Appedology craft a highly addictive mobile game for you!"
      />
      <div className="empoviring-business">
        <div className="empoviring-business-heading">
          <div>EUNLIMITED IMAGINATION AND INFINITE POSSIBILITIES</div>
          <div>
            It's all fun and games until you start developing one – that's our
            cue! Being one of the top video game developer companies, we craft
            highly addictive games that keep players coming back to play more.
          </div>
        </div>
        <div className="empoviring-business-items">
          <div className="empoviring-business-item">
            <IconContainer icon={icon1} />
            <div>Ultra-HD Graphics</div>
            <div>
              We create games with Ultra-HD graphics, making every scene a
              visual feast that captures and retains your audience's attention.
            </div>
          </div>
          <div className="empoviring-business-item">
            <IconContainer icon={icon2} />
            <div>Cross-Platform Compatibility</div>
            <div>
              Reach players anywhere, everywhere! We skillfully adapt your game
              for multiple platforms, ensuring a great experience whether on a
              phone, PC, or console
            </div>
          </div>
          <div className="empoviring-business-item">
            <IconContainer icon={icon3} />
            <div>Social Integration</div>
            <div>
              We embed social features to create a community around your game,
              enhancing player engagement to make your game the talk of the
              town.
            </div>
          </div>
          <div className="empoviring-business-item">
            <IconContainer icon={icon4} />
            <div>Robust Performance and Security</div>
            <div>
              Smooth gameplay, no worries. We ensure the game has no bugs or
              glitches, and include strong security measures to protect user
              privacy and data.
            </div>
          </div>
        </div>
        <div className="game-cards">
          <div className="game-card">
            <img src={icon5} />
            <div>
              <div>175+</div>
              <div>Games Developed</div>
            </div>
          </div>
          <div className="game-card">
            <img src={icon6} />
            <div>
              <div>95%</div>
              <div>Bug-Free Launches</div>
            </div>
          </div>
          <div className="game-card">
            <img src={icon7} />
            <div>
              <div>10+</div>
              <div>Years of Experience </div>
            </div>
          </div>

          <div className="game-card">
            <img src={icon8} />
            <div>
              <div>98% </div>
              <div>Client Satisfaction</div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-gaming">
        <div className="video-gaming-heading">
          <div className="video-gaming-title">
            GAME DEVELOPMENT ACROSS DIVERSE PLATFORMS
          </div>
          <div className="video-gaming-desc">
            We craft immersive games for all platforms, from mobile and PC to
            consoles and VR.
          </div>
        </div>
        <div className="video-gaming-icons">
          <img src={icon9} />
          <img src={icon10} />
          <img src={icon11} />
          <img src={icon12} />
        </div>
      </div>
      <div className="videos-section">
        <VideoItem
          title="What sets us apart in game development in Pakistan?"
          paras={[
            "It's simple: we listen! Our game developers take your vision and mix it with their expertise to create games that are fun and memorable",
            "Our team stays ahead of tech trends, ensuring your game feels fresh and exciting on any platform. We focus on user experience, making sure each game is intuitive and engaging. Plus, our efficient process means we get your game to market faster without skimping on quality.",
            "We're more than game developers; we're your creative allies, crafting games that not only make an impact but also keep players eagerly returning for more",
          ]}
          flip="flip"
        />
        <VideoItem
          title="Dynamic Gameplay Mechanics"
          paras={[
            "At the heart of our mobile game development company lies dynamic gameplay mechanics that promise an exhilarating experience.",
            "Imagine diving into a world where each move and decision opens new possibilities. We design mechanics that are intuitive yet challenging, ensuring that every session is fresh and engaging. From strategic puzzles to adrenaline-pumping action, our games are tailored to keep players on the edge of their seats, constantly craving more.",
            "It's not just about playing a game; it's about embarking on an ever-evolving adventure",
          ]}
          video={video2}
        />

        <VideoItem
          flip="flip"
          title="Stunning Visuals and Soundtracks"
          paras={[
            "Step into a realm where every frame is a visual feast and every note in the soundtrack amplifies the experience. This is Appedology’s promise to you!",
            "Our games are a blend of art and technology, boasting stunning visuals that transport players to other worlds. Paired with immersive soundtracks that resonate with every twist and turn, the sensory experience is unparalleled.",
            "It's like being in a blockbuster movie, but you're in control. This sensory symphony not only captivates but also deeply connects players to the game world.",
          ]}
          video={video3}
        />

        <VideoItem
          title="Regular Updates & Expansions"
          paras={[
            "Our game development service doesn't end at launch; it evolves.",
            "We believe in keeping our games vibrant with regular updates and expansions. New content means new adventures, keeping the gameplay experience fresh and exciting. Whether it's introducing new levels, characters, or story arcs, our mobile app and game development company is committed to expanding the universes we create.",
            "This approach keeps our games not just relevant but also gives players something to look forward to, constantly reigniting their passion and curiosity for the game.",
          ]}
        />
      </div>

      <TechStack
        title="Our Tech Stack"
        desc="We use a mix of the latest technology and creative genius to craft unforgettable gaming experiences."
      />
      <div className="game-dev-discuss">
        <img src={image3} />
        <div>
          <div className="game-dev-discuss-title">
            Discuss Your Game Development Idea With us Today
          </div>
          <div className="game-dev-discuss-btn">Get in touch</div>
        </div>
      </div>

      <div className="custom-game">
        <div className="custom-game-title">
          Ready to Get Your Masterpiece Crafted?
        </div>
        <div className="custom-game-desc">
          Here's how we'll develop the game for you!
        </div>
        <div className="custom-game-content">
          <div>
            <div className="custom-game-item">
              <div>01</div>
              <div>
                <div className="custom-game-item-title">Conceptualization</div>
                <div className="custom-game-item-desc">
                  We brainstorm the game's core idea, laying the creative
                  groundwork.
                </div>
              </div>
            </div>

            <div className="custom-game-item">
              <div>02</div>
              <div>
                <div className="custom-game-item-title">Design</div>
                <div className="custom-game-item-desc">
                  Our team crafts the game mechanics and visual style, shaping
                  the experience.
                </div>
              </div>
            </div>

            <div className="custom-game-item">
              <div>03</div>
              <div>
                <div className="custom-game-item-title">Programming</div>
                <div className="custom-game-item-desc">
                  Transforming ideas into reality, we code the game's essential
                  features.
                </div>
              </div>
            </div>
          </div>
          <img className="custom-game-content-image" src={image4} />
          <div>
            <div className="custom-game-item right">
              <div>04</div>
              <div>
                <div className="custom-game-item-title">Art Creation</div>
                <div className="custom-game-item-desc">
                  Artists design engaging visuals, characters, and environments,
                  bringing the game to life.
                </div>
              </div>
            </div>

            <div className="custom-game-item right">
              <div>05</div>
              <div>
                <div className="custom-game-item-title">Testing</div>
                <div className="custom-game-item-desc">
                  Quality assurance teams identify and fix bugs, refining
                  gameplay for a smooth experience.
                </div>
              </div>
            </div>

            <div className="custom-game-item right">
              <div>06</div>
              <div>
                <div className="custom-game-item-title">Launch</div>
                <div className="custom-game-item-desc">
                  The game is ready to be released to the public.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameDev;
