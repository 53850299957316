import { useState } from "react";
import classes from "./style.module.css";
import Icon from "../icons/Icon";


function InterfaceItem({text,Icon}) {
    const [fill, setFill] = useState("black");
    return (
    <div
    onMouseEnter={() => setFill("#860404")}
    onMouseLeave={() => setFill("black")}
    className={classes.naturalIterfaceIcon}
    >
  

    <Icon fill={fill} />

    <div>{text}</div>

  </div>

  )
}

InterfaceItem.defaultProps = {
  text:"",
  Icon: Icon
}

export default InterfaceItem