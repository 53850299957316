import React, { useEffect, useRef } from "react";
import video1 from "./videos/video.mp4";

function VideoItem({ title, desc, video, flip, paras = [] }) {
  const videoRef = useRef();
    console.log({paras});
 
    useEffect(() => {
      if(videoRef.current) {
        videoRef.current.muted = "muted"
      }
    }, [])

    return (
    <div className={`video-item ${flip}`}>
      <div>
        <div className="video-item-title">{title}</div>
        {paras.map(el => {
         return <div className="video-item-desc">{el}</div>

        })}       
      </div>

      <video width="320" height="240" autoplay="true" muted="muted" volime="0">
        <source src={video} type="video/mp4"></source>
      </video>
    </div>
  );
}

VideoItem.defaultProps = {
  video: video1,
  title: "Building Immersive Mission Games",
  flip:  "",
  desc: "Transform your game concepts into engaging, mission-focused experiences with our specialized game development services. Our team of experts will meticulously analyze and refine your ideas, ensuring the creation of compelling gameplay.  Partner with us to leverage cutting-edge technologies and select the ideal database, programming language, and framework, ensuring the resilience and appeal of your games.",
};

export default VideoItem;
