import React, { useState, useEffect, useRef } from 'react';
import { useIntersection } from '../../../hooks/useIntersection';

const AutoCountertwo = ({limit, interval, spanContnet, noCounter }) => {
  const [count, setCount] = useState(1);

  const triggerRef = React.useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");


  React.useEffect(() => {
   if(!noCounter) {
     if (isVisible) {
    
      // callbackFn(); // Trigger a function when the div is visible on view port
      const incrementCount = () => {
        setCount(prevCount => (prevCount < limit ? prevCount + 1 : prevCount));
      };
  
      const intervalId = setInterval(incrementCount, interval);
  
      return () => clearInterval(intervalId);
    
    }
   }
  }, [ isVisible]);


 

  return ( 
    <div ref={triggerRef}>
      <h1>{!noCounter  && count}<span>{spanContnet}</span></h1>
    </div>
  );
};

export default AutoCountertwo;
