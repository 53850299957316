import React from 'react'

function Icon4({fill = "black"}) {
  return (
    <svg width="143" height="175" viewBox="0 0 143 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M77.0239 104.981L70.7258 95.2627C70.5607 86.5593 67.3782 79.4008 61.4966 74.5275C56.2528 70.1823 48.9073 67.7889 40.8134 67.7884C40.813 67.7884 40.8127 67.7884 40.812 67.7884C30.0683 67.7884 19.9554 73.5474 14.4205 82.8175C8.09541 93.4109 8.82454 107.145 16.2351 116.993C18.2822 119.714 20.7473 122.063 23.5621 123.974C24.1351 124.363 24.4771 125.001 24.4771 125.682L24.4764 139.994C24.4762 141.425 25.0335 142.771 26.0458 143.784C27.0579 144.796 28.4038 145.354 29.8349 145.354L50.286 145.355C53.2405 145.355 55.6446 142.951 55.6451 139.996L55.6454 135.473C55.6454 135.278 55.7362 135.146 55.8124 135.07C55.8884 134.994 56.0201 134.903 56.2153 134.903L60.9773 134.903C66.0248 134.903 70.132 130.797 70.1322 125.749L70.1327 115.466C70.1327 115.152 70.3884 114.896 70.7029 114.896H71.3412C73.6956 114.896 75.8164 113.686 77.0141 111.659C78.2119 109.632 78.2485 107.19 77.1124 105.128C77.0844 105.078 77.055 105.029 77.0239 104.981ZM72.8908 109.223C72.7335 109.489 72.268 110.108 71.3407 110.108H70.7026C67.7476 110.108 65.3435 112.512 65.3435 115.467L65.343 125.749C65.343 126.915 64.8887 128.012 64.064 128.836C63.2395 129.661 62.1433 130.115 60.977 130.115L56.2156 130.114C56.2156 130.114 56.2153 130.114 56.2149 130.114C54.7838 130.114 53.4381 130.672 52.426 131.684C51.4137 132.696 50.8564 134.042 50.8564 135.473L50.8562 139.996C50.8562 140.31 50.6005 140.566 50.2862 140.566L29.8353 140.565C29.6403 140.565 29.5086 140.474 29.4324 140.398C29.3564 140.322 29.2656 140.19 29.2656 139.995L29.2663 125.682C29.2668 123.414 28.1402 121.294 26.2528 120.012C23.8744 118.397 21.7917 116.413 20.0617 114.114C13.8087 105.803 13.1938 94.2131 18.5322 85.2726C23.2081 77.4414 31.7458 72.5769 40.8132 72.5774C52.9628 72.5781 65.9445 78.7269 65.9438 95.9811C65.9438 96.4433 66.0776 96.8957 66.329 97.2836L72.95 107.5C73.3491 108.287 73.0439 108.964 72.8908 109.223Z" fill={fill}/>
    <path d="M119.689 60.6044L116.864 63.3955L116.315 62.8465C115.38 61.9116 113.864 61.9116 112.929 62.8465C111.994 63.7817 111.994 65.2978 112.929 66.2329L115.161 68.465C115.629 68.9324 116.242 69.1662 116.854 69.1662C117.463 69.1662 118.071 68.9358 118.537 68.475L123.055 64.0111C123.995 63.0817 124.005 61.5653 123.075 60.625C122.145 59.6838 120.629 59.6748 119.689 60.6044Z" fill={fill}/>
    <path d="M129.293 55.4111C130.289 54.1519 130.856 52.5749 130.856 50.9106V30.247C130.856 26.2489 127.603 22.9961 123.605 22.9961H80.0664C78.7441 22.9961 77.6718 24.0682 77.6718 25.3907C77.6718 26.7132 78.7439 27.7853 80.0664 27.7853H123.605C124.962 27.7853 126.067 28.8896 126.067 30.247V50.9106C126.067 51.3109 125.965 51.6937 125.789 52.0364C123.437 50.4233 120.594 49.4772 117.533 49.4772C113.704 49.4772 110.215 50.957 107.604 53.3723H72.165C71.4311 53.3723 70.7373 53.7091 70.2835 54.2857L64.7458 61.3194C64.7386 61.3228 64.719 61.3228 64.7032 61.3089L59.1751 54.286C58.7213 53.7093 58.0278 53.3726 57.2936 53.3726H38.4365C37.0791 53.3726 35.9748 52.2682 35.9748 50.9108V30.247C35.9748 28.8896 37.0791 27.7853 38.4365 27.7853H58.5124C59.8347 27.7853 60.907 26.7132 60.907 25.3907C60.907 24.0682 59.8349 22.9961 58.5124 22.9961H38.4365C34.4386 22.9961 31.1858 26.2489 31.1858 30.247V50.9106C31.1858 54.9088 34.4386 58.1615 38.4368 58.1615H56.1312L60.9395 64.2706C61.8596 65.4404 63.2409 66.1115 64.7293 66.1115C66.2174 66.1115 67.5987 65.4407 68.5181 64.2713L73.3271 58.1615H104.167C103.353 59.9811 102.897 61.9943 102.897 64.1129C102.897 72.1831 109.463 78.7489 117.533 78.7489C125.603 78.7489 132.169 72.1831 132.169 64.1129C132.169 60.8558 131.098 57.8451 129.293 55.4111ZM117.533 73.9594C112.103 73.9594 107.686 69.5423 107.686 64.1124C107.686 58.683 112.103 54.2657 117.533 54.2657C122.962 54.2657 127.38 58.6828 127.38 64.1124C127.38 69.5423 122.962 73.9594 117.533 73.9594Z" fill={fill}/>
    <path d="M119.683 42.1122V38.4984C119.683 37.1761 118.611 36.1038 117.288 36.1038C115.966 36.1038 114.894 37.1758 114.894 38.4984V42.1122C114.894 43.4345 115.966 44.5068 117.288 44.5068C118.611 44.5068 119.683 43.4345 119.683 42.1122Z" fill={fill}/>
    <path d="M107.686 45.6723V34.9382C107.686 33.616 106.614 32.5437 105.292 32.5437C103.969 32.5437 102.897 33.6157 102.897 34.9382V45.6726C102.897 46.9949 103.969 48.0672 105.292 48.0672C106.614 48.0672 107.686 46.9949 107.686 45.6723Z" fill={fill}/>
    <path d="M95.6891 42.1122V38.4984C95.6891 37.1761 94.6171 36.1038 93.2945 36.1038C91.9722 36.1038 90.8999 37.1758 90.8999 38.4984V42.1122C90.8999 43.4345 91.972 44.5068 93.2945 44.5068C94.6171 44.5068 95.6891 43.4345 95.6891 42.1122Z" fill={fill}/>
    <path d="M83.6923 45.6723V34.9382C83.6923 33.616 82.6202 32.5437 81.2977 32.5437C79.9751 32.5437 78.9031 33.6157 78.9031 34.9382V45.6726C78.9031 46.9949 79.9751 48.0672 81.2977 48.0672C82.6202 48.0672 83.6923 46.9949 83.6923 45.6723Z" fill={fill}/>
    <path d="M69.3008 44.5068C70.6231 44.5068 71.6954 43.4348 71.6954 42.1122V38.4984C71.6954 37.1761 70.6233 36.1038 69.3008 36.1038C67.9782 36.1038 66.9062 37.1758 66.9062 38.4984V42.1122C66.9064 43.4345 67.9785 44.5068 69.3008 44.5068Z" fill={fill}/>
    <path d="M54.9095 34.9382V45.6726C54.9095 46.9949 55.9816 48.0672 57.3041 48.0672C58.6264 48.0672 59.6987 46.9951 59.6987 45.6726V34.9382C59.6987 33.616 58.6267 32.5437 57.3041 32.5437C55.9816 32.5437 54.9095 33.6157 54.9095 34.9382Z" fill={fill}/>
    <path d="M42.9124 38.4984V42.1122C42.9124 43.4345 43.9845 44.5068 45.307 44.5068C46.6293 44.5068 47.7016 43.4348 47.7016 42.1122V38.4984C47.7016 37.1761 46.6295 36.1038 45.307 36.1038C43.9847 36.1038 42.9124 37.1761 42.9124 38.4984Z" fill={fill}/>
    <path d="M69.3008 27.7858C70.6233 27.7858 71.6954 26.7137 71.6954 25.3912C71.6954 24.0687 70.6233 22.9966 69.3008 22.9966C67.9783 22.9966 66.9062 24.0687 66.9062 25.3912C66.9062 26.7137 67.9783 27.7858 69.3008 27.7858Z" fill={fill}/>
    </svg>
    

  )
}

export default Icon4