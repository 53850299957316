import "./style.css";
import image1 from "./images/image1.svg";
import image2 from "./images/image2.svg";
import image3 from "./images/image3.svg";
import image4 from "./images/image4.svg";
import image5 from "./images/image5.svg";





function OurPartners() {
    return (
        <div className='our-partners'>
             <div className='row pt-2 pb-5'>
                    <h1 className='h1 mainheading text-center position-relative'><span className='ps-5 pe-5'>Our Partners</span></h1>
                </div>
            <div className="images">
                <img src={image1} />
                <img src={image2} />
                <img src={image3} />
                <img src={image4} /> 
                <img src={image5} />
                <img src={image1} />
            </div>

        </div>
    ) 
}

export default OurPartners