import React from 'react'
import  "./style.css"
import PremiumExpetise from './PremiumExpertise/PremiumExpetise';
import GoToExpert from './GoToExpert/GoToExpert';
import OurFeatured from './OurFeatured/OurFeatured';
import OurPartners from './OurPartners/OurPartners';
import TechStack from './TeckStack/TechStack';

import AutoCounter from '../about/component/Countdown1.js'

import OurClientsLove from './OurClientsLove/OurClientsLove.jsx';





function MobileAppDev() {
  return (
    <div className='main'>
        <div className='top-section mb-20'>
            <div className='content'>
                <div className='col-md-12 text-white  text-center'>
                    <h2>Android / IOS App Development </h2>
                    <h1>Your Idea, Our Code</h1>
                    <h4>Appedology transforms your mobile app development ideas into digital reality. We bring your vision to life in just 30 days! Stunning Android & iOS App Development - just a few clicks away!</h4>
                </div>
            </div>
        </div>
         <PremiumExpetise />
         <GoToExpert />
         <OurFeatured />
          <div className='container'>
            <OurPartners />
          </div>
         <TechStack 
          title="Mobile App Development Technology Stack"
          desc="With a rich palette of major technologies at our fingertips, we ensure versatile, efficient, and future-proof solutions across various industry domains."
         />
         <div className='happyclient pt-5 pb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={50}
                                interval={100}
                                spanContnet="+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Skilled Developers</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={100}
                                interval={30}
                                spanContnet="+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Apps Developed</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={95}
                                interval={200}
                                spanContnet="M+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Customer Satisfaction</p>
                        </div>
                    </div>
                    
                </div>
            </div>
         </div>
        <OurClientsLove />
        <div>
      </div>
           
    </div>
  )
}
 
export default MobileAppDev;