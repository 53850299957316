import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const SliderFour = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current index
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentIndex, images.length]);

  return (
    <div className="slider" style={{ backgroundImage: `url(${images[currentIndex]})` }}>
        <div className='container'>
            <div className='col-md-6'>
                <div className='slidertext'>
                    <div>
                        <h1 className="h1">Innovate, Elevate, Dominate!</h1>
                        <p className='h5 mt-4'>Beyond boundaries, Appedology is the software company that turns your dreams into reality. Offering an extensive array of business services, we build today's solutions with an eye on tomorrow's possibilities.</p>
                        {/* <p className='h5'>We are at the forefront in areas like business management, app development, Artificial Intelligence, and digital marketing. Think of us as your trusted allies in boosting business growth, catalyzing innovation, and changing the way you use digital technology</p> */}
                        <Link className='btn btn-outline-primary mt-3 btnblack text-white' to="/contact">Learn More</Link>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
};

export default SliderFour;
