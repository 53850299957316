import { Outlet, NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Logo from './images/Appedology-Logo.svg';
import Clickableservice from './ServicsLinks'
import MegaMenu from './ServicsLink'
import './header.css'

function Header() {
  const [isHovered, setIsHovered] = useState(false);

 
    const [isClicked, setIsClicked] = useState(false);



    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    setIsClicked(!isClicked);
  };
  
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMouseLeave = () => {
    setIsHovered(false);
  };



  return ( 
    <>
        
        <div  className={`header_fixed ${isScrolled ? '' : 'scrolled'} top pt-3 pb-3`}>
            <div className="container">
                <div className="header d-flex justify-content-between align-items-center">
                <div className='logo'>
                    <NavLink to="/"><img src={Logo} alt="logo" /></NavLink>
                </div>
                <div className={`menu-toggle ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <span className={`${isClicked ? 'hidemobilenavicon' : 'hidedesktop'}`}><i class='bx bx-x'></i></span>
                </div>
                
                    <ul 
               onMouseEnter={handleMouseLeave}
                  
                    className={`nav-list ${isMobileMenuOpen ? 'open' : ''} nav navbar`}>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li
               onMouseEnter={handleMouseLeave}
                        
                        ><NavLink to="/about">About Us</NavLink></li>
                        <li className="desktop"><MegaMenu isHovered={isHovered} setIsHovered={setIsHovered}  /></li>
                        <li className="tabletmobile"><Clickableservice /></li>
                        {/* <li><NavLink to="/portfolio">Portfolio</NavLink></li> */}
                        {/* <li><NavLink to="/solutions">Solutions</NavLink></li> */}
                        {/* <li><NavLink to="/blog">Blog</NavLink></li> */}
                        {/* <li><NavLink to="/faq">FAQ</NavLink></li> */}
                        <li
               onMouseEnter={handleMouseLeave}
                        
                        ><NavLink to="/contact">Contact Us</NavLink></li>
                        <li className="quote"><NavLink to="" className="px-3 text-white rounded-1 pt-2 pb-2 ms-4">get a quote</NavLink></li>
                    </ul>
                
                </div>
            </div>


            {isHovered && (
        <div
        onMouseLeave={handleMouseLeave}
        className="mega-menu-content pb-5">
          <div className='container'>
              <div className='row'>
                  <div className='col-md-3'>
                      <div className='menucontent definemegamenu'>
                          <h4 className='h5'>Our Services</h4>
                          <p className='lh-base'>Harnessing state-of-the-art technologies to craft pioneering digital solutions for our BPO technology company</p>
                      </div>
                  </div>
                  <div className='col-md-9 pt-4'>
                    <div className='row'>
                      <div className='col-md-4'>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                  <h4 className='h6 mb-4'>BPO</h4>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/business-management">
                                            <li>Business Management</li>
                                            <span>Planning, Organizing, Leading, and Achieving</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/sales-and-business-development">
                                            <li>Sales & Business Development</li>
                                            <span>Selling, Expansion, and Relationships</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/medical-billing">
                                            <li>Medical Billing & RCM</li>
                                            <span>Coding, Reimbursement, and Compliance</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/chat-support">
                                            <li>Exceptional Chat Support</li>
                                            <span>Responsive, Knowledgeable, and Proactive</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/tailored-solutions">
                                            <li>Tailored Solutions</li>
                                            <span>Customized, Adaptive, and Personalized</span>
                                        </NavLink>
                                    </ul>
                              </div>
                            </div>
                        </div>
                        {/* end first link mega menu col */}
                        <div className='col-md-4'>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                  <h4 className='h6 mb-4'>TECH</h4>
                                    <ul className='mengamenuul'>
                                        <NavLink to="">
                                            <li>Mobile App Development</li>
                                            <span>IOS and Android </span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          {/* <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="">
                                            <li>Game Development</li>
                                            <span>IOS, andriod, TV, and wearables </span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div> */}
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/web-dev">
                                            <li>Website Development</li>
                                            <span>PHP and wordpress development</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/machine-learning-and-ai">
                                            <li>Machine Learning & AI</li>
                                            <span>Automation, Prediction, Optimization</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/network-and-cloud">
                                            <li>Network and Cloud Computing</li>
                                            <span>Connectivity, Scalability, Virtualization</span>
                                        </NavLink>
                                    </ul>
                              </div>
                            </div>
                        </div>
                        {/* end second menga menu */}
                        <div className='col-md-4'>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                  <h4 className='h6 mb-4'>DIGITAL</h4>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/social-media-management">
                                            <li>Social Media Management</li>
                                            <span>Engagement, Content, Analytics</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/graphic-designing-services">
                                            <li>Graphic Designing service</li>
                                            <span>Creativity, Innovation, Aesthetics</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/content-creation">
                                            <li>Content Creation</li>
                                            <span>Storytelling, Engagement, Originality</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/seo-and-marketing">
                                            <li>SEO & Marketing Services</li>
                                            <span>Optimization, Visibility, Strategy</span>
                                        </NavLink>
                                    </ul>
                              </div>
                          </div>
                          <div className='col-md-12 pt-2 pb-2'>
                              <div className='menucontent'>
                                    <ul className='mengamenuul'>
                                        <NavLink to="/customer-relationship-management">
                                            <li>Customer Relationship Management</li>
                                            <span>Personalization, Engagement, Retention</span>
                                        </NavLink>
                                    </ul>
                              </div>
                            </div>
                        </div>
                      </div>
                    {/* end row colum */}
                    
                  </div>
              </div>
          </div>
        </div>
      )}
        </div>


       
        <Outlet />
           
       

    </>
  )
}

export default Header;







