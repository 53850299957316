import React, { useState, useEffect } from 'react';

const TextSliderContent = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentIndex, texts.length]);

  return (
    <div className="text-slider">
      
      {texts.map((text, index) => (
        <div
          key={index}
          className={`text-slide ${index === currentIndex ? 'active' : ''}`}
        >
          <h1> {text.title}</h1>
        </div>
      ))}
    </div>
  );
};

export default TextSliderContent;
