import React from 'react'
import image from "../images/featured_image1.svg"
import icon from "../images/featured_icon.svg";

function Item({ item }) {
  return (
    <div className={`featured-item flex-md-row flex-column ${item.flip || ""}`}>
      <img src={item.image} />
      <div>
        <div>
          <img className='featured-item-logo' src={item.icon} />
          <span className='featured-item-logo-text'>Mobile App</span>
        </div>

        <div>{item.title}</div>
        <div>{item.desc}</div>
      </div>
    </div>
  )
}

export default Item