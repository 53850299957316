import "./style.css";
import icon from "./icons/icon1.svg";
import icon2 from "./icons/icon2.svg";
import icon3 from "./icons/icon3.svg";
import icon4 from "./icons/icon4.svg";
import icon5 from "./icons/icon5.svg";
import icon6 from "./icons/icon6.svg";
import icon7 from "./icons/icon7.svg";
import icon8 from "./icons/icon8.svg";
import icon9 from "./icons/icon9.svg";
import icon10 from "./icons/icon9.svg";
import icon11 from "./icons/icon9.svg";



import bigIcon from "./icons/big-icon.png";
import bgGradient from "./icons/bg-gradient.png";

function TechStack({title, desc}) {
  return (
    <div className="tech-stack">
        <div className="content">
        <div>{title}</div>
      <div>
        {desc}
      </div>
        </div>
      <div className="icons">
        <div className="left-icons">
          <div>
            <img src={icon} />
          </div>
          <div>
            <img src={icon2} />
            <img src={icon3} />
          </div>
          <div>
            <img src={icon4} />
            <img src={icon5} />
            <img src={icon6} />
          </div>
        </div>
        <div
          className="big-icon-container"
        style={{
          backgroundImage: `url(${bgGradient})`,
          "background-size":     "cover",                     
          "background-repeat":   "no-repeat",
          "background-position": "center center" 
        }}>
        <img className="big-icon bigsicon" src={bigIcon} />

        </div>
        <div className="right-icons">
        <div>
            <img src={icon} />
            <img src={icon3} />
            <img src={icon2} />
          </div>
          <div>
            <img src={icon4} />
            <img src={icon5} />
          </div>
          <div>
            <img src={icon6} />
          </div>
         
          
        </div>
      </div>
    </div>
  );
}

TechStack.defaultProps = {
  title: "Mobile app development technology stack",
  desc: "With a rich palette of major technologies at our fingertips, we ensure versatile, efficient, and future-proof solutions across various industry domains"
}

export default TechStack;
