import React from 'react'
import Item from './Item/Item'
import "./style.css"



function Items({items}) {
  return (
    <div className='items row mx-xxl-5 mx-0'>
      {items.map(el => {
        return <Item item={el} />
      })}

    </div>
  )
}

export default Items