import React from 'react'

function Icon2({fill = "black"}) {
  return (
    <svg width="142" height="175" viewBox="0 0 142 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110.009 95.7153L117.636 88.0881L121.232 91.6829L113.603 99.3111L110.009 95.7153Z" fill={fill}/>
    <path d="M114.348 125.481H106.721V120.396H114.348C115.752 120.396 116.891 119.258 116.891 117.854V89.8869C116.891 88.859 117.511 87.9326 118.461 87.5388C119.41 87.1459 120.503 87.3628 121.231 88.09L128.858 95.7172L125.263 99.312L121.975 96.0245V117.854C121.975 122.066 118.561 125.481 114.348 125.481Z" fill={fill}/>
    <path d="M124.518 82.2597H96.5518V77.1752H124.518C125.922 77.1752 127.061 76.0365 127.061 74.6325V33.9544C127.061 32.5504 125.922 31.4117 124.518 31.4117H94.0091C92.605 31.4117 91.4663 30.273 91.4663 28.869V21.2417H71.1273V74.6325C71.1273 76.0365 72.266 77.1752 73.6701 77.1752H81.2973V82.2597H73.6701C69.457 82.2597 66.0428 78.8455 66.0428 74.6325V18.7C66.0428 17.2959 67.1806 16.1572 68.5846 16.1572H94.0091C95.4131 16.1572 96.5518 17.2959 96.5518 18.7V26.3272H124.518C128.73 26.3272 132.145 29.7414 132.145 33.9544V74.6325C132.145 78.8455 128.73 82.2597 124.518 82.2597Z" fill={fill}/>
    <path d="M81.2974 26.3272H94.0092L94.0091 31.4117H81.2974V26.3272Z" fill={fill}/>
    <path d="M99.0936 138.193H12.6521C11.2481 138.193 10.1094 137.054 10.1094 135.65V110.227C10.1094 108.823 11.2481 107.684 12.6521 107.684H99.0936C100.498 107.684 101.636 108.823 101.636 110.227V135.65C101.636 137.054 100.498 138.193 99.0936 138.193ZM15.1939 133.109H96.5518V112.769H15.1939V133.109Z" fill={fill}/>
    <path d="M38.0756 110.227H32.9911V105.141H27.9066V110.227H22.8211V102.6C22.8211 101.196 23.9598 100.057 25.3639 100.057H35.5338C36.9379 100.057 38.0756 101.196 38.0756 102.6V110.227Z" fill={fill}/>
    <path d="M27.9065 69.548H32.991V102.6H27.9065V69.548Z" fill={fill}/>
    <path d="M96.5519 110.227H91.4665V105.141H86.3819V110.227H81.2974V102.6C81.2974 101.196 82.4352 100.057 83.8392 100.057H94.0092C95.4132 100.057 96.5519 101.196 96.5519 102.6V110.227Z" fill={fill}/>
    <path d="M86.3817 69.548H91.4662V102.6H86.3817V69.548Z" fill={fill}/>
    <path d="M30.4484 72.0907C26.2363 72.0907 22.8211 68.6756 22.8211 64.4634C22.8211 60.2504 26.2363 56.8362 30.4484 56.8362C34.6614 56.8362 38.0756 60.2504 38.0756 64.4634C38.0756 68.6756 34.6614 72.0907 30.4484 72.0907ZM30.4484 61.9207C29.0443 61.9207 27.9066 63.0594 27.9066 64.4634C27.9066 65.8675 29.0443 67.0052 30.4484 67.0052C31.8524 67.0052 32.9911 65.8675 32.9911 64.4634C32.9911 63.0594 31.8524 61.9207 30.4484 61.9207Z" fill={fill}/>
    <path d="M88.9247 72.0907C84.7116 72.0907 81.2974 68.6756 81.2974 64.4634C81.2974 60.2504 84.7116 56.8362 88.9247 56.8362C93.1368 56.8362 96.5519 60.2504 96.5519 64.4634C96.5519 68.6756 93.1368 72.0907 88.9247 72.0907ZM88.9247 61.9207C87.5206 61.9207 86.3819 63.0594 86.3819 64.4634C86.3819 65.8675 87.5206 67.0052 88.9247 67.0052C90.3287 67.0052 91.4665 65.8675 91.4665 64.4634C91.4665 63.0594 90.3287 61.9207 88.9247 61.9207Z" fill={fill}/>
    <path d="M20.2793 120.396H25.3639V125.481H20.2793V120.396Z" fill={fill}/>
    <path d="M30.4483 120.396H35.5337V125.481H30.4483V120.396Z" fill={fill}/>
    <path d="M40.6182 120.396H91.4662V125.481H40.6182V120.396Z" fill={fill}/>
    <path d="M48.2456 102.6C47.5715 102.6 46.9244 102.331 46.4477 101.855L38.8205 94.2275L42.4162 90.6318L45.7029 93.9194V74.6324C45.7029 70.4203 49.118 67.0052 53.3301 67.0052H60.9574V72.0906H53.3301C51.9261 72.0906 50.7883 73.2284 50.7883 74.6324V100.057C50.7883 101.461 49.6496 102.6 48.2456 102.6Z" fill={fill}/>
    <path d="M46.4487 98.2572L54.0768 90.6299L57.6726 94.2247L50.0444 101.853L46.4487 98.2572Z" fill={fill}/>
    <path d="M32.2464 52.4956C31.2427 51.5319 29.6571 51.5319 28.6534 52.4956L25.0539 48.9008C28.0734 46.0219 32.8218 46.0219 35.8412 48.9008L32.2464 52.4956Z" fill={fill}/>
    <path d="M39.4388 45.3059C34.4744 40.3415 26.4254 40.3415 21.461 45.3059L17.8643 41.7111C24.8137 34.7598 36.0833 34.7598 43.0336 41.7111L39.4388 45.3059Z" fill={fill}/>
    <path d="M46.6283 38.1135C37.6864 29.1902 23.2085 29.1902 14.2666 38.1135L10.6736 34.5187C21.6033 23.6141 39.2972 23.6141 50.226 34.5187L46.6283 38.1135Z" fill={fill}/>
    <path d="M78.7546 36.4963H119.434V41.5817H78.7546V36.4963Z" fill={fill}/>
    <path d="M78.7546 46.6662H119.434V51.7507H78.7546V46.6662Z" fill={fill}/>
    <path d="M101.636 56.8362H119.434V61.9207H101.636V56.8362Z" fill={fill}/>
    <path d="M101.636 67.0052H119.434V72.0906H101.636V67.0052Z" fill={fill}/>
    </svg>
    

  )
}

export default Icon2