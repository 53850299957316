import Item from "./Item"
import "./style.css"
import icon1 from "../images/featured_icon.svg"
import icon2 from "../images/featured_icon2.svg"
import icon3 from "../images/featured_icon3.svg"

import image1 from "../images/featured_image1.svg"
import image2 from "../images/featured_image2.svg"
import image3 from "../images/featured_image3.svg"




const data = [
{
  icon: icon1,
  title: "Brewed Perfection Your Favorite Neighborhood Coffee Shop",
  image: image1,
  desc: "Experience the perfect blend of artisanal coffee and a warm, inviting atmosphere at our cherished neighborhood coffee shop. Join us for a delightful coffee journey like no other."
},
{
  icon: icon2,
  image: image2,
  flip: "flip",
  title: "Elevate Your Music Experience with Our Cutting-Edge Music App",
  desc: "Discover a world of music at your fingertips with our feature-rich music app. Enjoy seamless streaming, personalized playlists, and a vast library of songs to suit every mood"
},

{
  icon: icon3,
  image: image3,
  title: "Embark on Culinary Journeys with Our Food Adventure App",
  desc: "Unleash your inner foodie and explore a world of flavors with our Food Adventure App. From discovering hidden gems to trying exotic cuisines, it's your passport to gastronomic delight."
},

]


function OurFeatured() {
  return (
    <div className="our-featured">
      <div>
        <div>Our Featured Works</div>
        <div className="text-capitalize"><p>Appedology has been a part of the development and design industry for over a decade. Our team comprises over 100+ certified and trained developers with experience of 16 years or more, making them the best at what they do. No matter what your company size or needs are, our team knows how to create a spectacular app. Here are some of our clients from various industries who’ve been with us since the start.</p></div>
      </div>
      <div className="our-featured-items">
        {data.map(item => <Item item={item} />)}

      </div>
    </div>
  )
}

export default OurFeatured