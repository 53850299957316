import React from 'react'
import { Link } from "react-router-dom";

function TopSection({subtitle, title, desc, image, lets, btn = {} }) {
  return (
    <div style={image ? {backgroundImage: `url(${image})`} : {}} className='top-section mb-20 mt-5'>
    <div className='content'>
        <div className='col-md-12 text-white  text-center'>
            <h2>{subtitle}</h2>
            <h1>{title}</h1>
            {desc && <h4>{desc}</h4>}
            <h5 className="pt-4 pb-4 h4">{lets}</h5>
            {btn.text && (
            <p className="pt-2">
              <div className="cmn-btn pt-3">
                <Link className='slidebtn' to={btn.link}>{btn.text}</Link>
              </div>
            </p>
          )}
        </div>
    </div>
</div>
  )
}

export default TopSection