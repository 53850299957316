import React from 'react'
import "./style.css"
import Items from './Items/Items'

import icon from "../images/item_icon.svg"
import icon2 from "../images/item_icon2.svg"
import icon3 from "../images/item_icon3.svg"
import icon4 from "../images/item_icon4.svg"
import icon5 from "../images/item_icon5.svg"
import icon6 from "../images/item_icon6.svg"



import activeIcon1 from "../icons/active-icon1.png"
import activeIcon2 from "../icons/active-icon2.png"
import activeIcon3 from "../icons/active-icon3.png"
import activeIcon4 from "../icons/active-icon4.png"
import activeIcon5 from "../icons/active-icon5.png"
import activeIcon6 from "../icons/active-icon6.png"


import appTestingWhite from "../icons/app_testing_white.svg"











const data = [
  {
    icon,
    activeIcon: appTestingWhite,
    title: "Planning & Wireframing ",
    desc: "Kicking off with initial planning and wireframing, we align client needs with a strategic project blueprint, establishing objectives, and timelines, and designing the app's core layout and user journey.",
  },
  {
    activeIcon: activeIcon2,

    icon: icon2,
    title: "Mockup",
    desc: "Mockups provide a visual representation of the app, showcasing the design elements and user interface. This high-fidelity prototype will give you a realistic preview of the final product's look and feel.",
  },
  {
    activeIcon: activeIcon3,

    icon: icon3,
    title: "Copy & Graphics",
    desc: "In this stage, we integrate compelling copy and engaging graphics. The content is tailored to resonate with the target audience, while the graphics are designed to be visually appealing and brand-aligned.",
  },

  {
    activeIcon: activeIcon1,

    icon: icon4,
    title: "Development",
    desc: "Our development team will then translate designs into a functional app. This involves coding the application, building features, and ensuring the software architecture is robust and scalable.",
  },

  {
    activeIcon: activeIcon5,

    icon: icon5,
    title: "Testing",
    desc: "Testing, a critical step in the development process will ensure the app's quality. We conduct various tests, including functionality, usability, performance, and security testing, to identify and resolve any issues.",
  },

  {

    icon: icon6,
    activeIcon: activeIcon6,
    title: "Deployment & Optimization",
    desc: "The final step is deploying the app to the appropriate platforms. Post-launch, we focus on optimization, monitoring performance, and making necessary adjustments for continuous improvement.",
  },

]

function PremiumExpetise({title, desc, items, dialogue,progress}) {
  return (
    <div className='premium'>
        <div className='premium-top flex-md-row flex-column '>
            <div>{title}<p>{dialogue} {progress}</p></div>
            <div>{desc}</div>
        </div>
        <Items items={items} />
    </div>
  )
}


PremiumExpetise.defaultProps = {
  title: "The Development Process",
  dialogue: " Open Dialogue, Clear Progress",
  progress: "",
  desc:  "At Appedology, we’ve got the best mobile app developers in Pakistan. We prioritize transparent communication, ensuring clients are always in the loop with clear, consistent updates throughout the development process. We champion ensuring your vision and our execution align perfectly for exceptional results.",
  items: data
}




export default PremiumExpetise