import "./style.css";
import icon1 from "./icons/icon1.svg";
import icon2 from "./icons/icon2.svg";
import icon3 from "./icons/icon3.svg";
import star from "./icons/star_fill.svg";



function OurClientsLove() {
  return (
    <div className="our-clients-love pb-5 pt-5 mt-5">
        <div>Why Our Clients Love  <span className="gradiant-text-color">Appedology</span> </div>
        <div className="pb-5">Appedology wins hearts with its reliability, transparent pricing and cutting-edge tech. We are loved for our innovation, exceptional customer care, prompt delivery, and skillful transformation of complex ideas into intuitive, impactful solutions</div>
        <div>
     <div className="d-flex justify-center rating-cards pt-4">
               
       <div className="rating-item items-center mr-5">
       <img className="mr-3" src={icon1} />
        <div  className="flex flex-col">
            <div className="d-flex gap-3">
                <span>5.0/5.0</span>
                <div className="flex mb-2">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                </div>
            </div>
            <div className="text-[#606060]">Based on 80+ Clients</div>
        </div>
       </div>

       <div className="rating-item items-center mr-5">
       <img className="mr-3" src={icon2} />
        <div  className="flex flex-col">
            <div className="d-flex gap-3">
                <span>5.0/5.0</span>
                <div className="flex mb-2">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                </div>
            </div>
            <div className="text-[#606060]">Based on 80+ Clients</div>
        </div>
       </div>
       <div className="rating-item items-center ">
       <img className="mr-5" src={icon3} />
        <div  className="flex flex-col">
            <div className="d-flex gap-3">
                <span>5.0/5.0</span>
                <div className="flex mb-2">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                </div>
            </div>
            <div className="text-[#606060]">Based on 80+ Clients</div>
        </div>
       </div>
       
     </div>  
        <div className="user-cards">
        <div className="flex user-card">
        <div>
            <div className="mb-2"><strong>Zain Abbas</strong></div>
            <div>COO</div>
        </div>
        <div className="flex">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
        </div>
     </div>

     <div className="flex user-card">
        <div>
            <div className="mb-2"><strong>Agha Ali</strong></div>
            <div>CEO</div>
        </div>
        <div className="flex">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
        </div>
     </div>
     <div className="flex user-card">
        <div>
            <div className="mb-2"><strong>Mohib Haider</strong></div>
            <div>Director Operations</div>
        </div>
        <div className="flex">
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
        </div>
     </div>

        </div>
        <div className="texts lh-base">
            <div>The app Appedology developed for my travel & tourism company has been a game-changer for us. Our customers find it incredibly easy to use for both information and bookings. We have found some great clients through them!</div>
            <div>Appedology's android mobile app development services have propelled our business forward. Our business app’s robust features and user-centric design have made financial transactions smoother for us and our clients, enhancing overall user experience and trust in our services.</div>
            <div>Since launching our app created by Appedology, we've seen a noticeable uptick in orders. Customers love the ease of use and efficiency, which has directly boosted our sales and revenue.</div>
        </div>

        </div>
    </div>
  )
}

export default OurClientsLove