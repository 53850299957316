import "./style.css";
import classes from "./style.css";

import image1 from "./images/image1.png";
import image2 from "./images/image2.png";
import image3 from "./images/image3.png";
import image4 from "./images/image4.png";


import TopSection from "../../components/TopSection";
import Tabs from "./Tabs";

import icon from "./icons/icon.png";

import icon2 from "./icons/service-icon2.png";
import icon3 from "./icons/service-icon3.png";
import icon4 from "./icons/service-icon4.png";
import icon5 from "./icons/service-icon5.png";
import icon6 from "./icons/service-icon6.png";



import bgSideIcon1 from "../images/bg_side_icon1.png"
import bgSideIcon2 from "../images/bg-side-icon2.png"



import activeIcon1 from "./icons/active-icon1.png";
import activeIcon2 from "./icons/serice-active-icon2.png";

import activeIcon3 from "./icons/service-ative-icon3.png";
import activeIcon4 from "./icons/service-active-icon4.png";
import activeIcon5 from "./icons/service-active-icon5.png";
import activeIcon6 from "./icons/service-active-icon6.png";
// import activeIcon7 from "./icons/serice-active-icon7.png";


import {Helmet} from "react-helmet";

import Items from "../mobilAppDevelopement/PremiumExpertise/Items/Items";
import TechStack from "./techstack/TechStackCloud.jsx";

import AutoCounter from "../about/component/Countdown1";

import Icon from "./icons/Icon";
import Icon2 from "./icons/Icon2";
import Icon3 from "./icons/Icon3";

import { useState } from "react";
import InterfaceItem from "./InterfaceItem";
import Icon4 from "./icons/Icon4";
import Icon7 from "./icons/Icon7";

import Icon5 from "./icons/Icon5";
import Icon6 from "./icons/Icon6";
import Icon8 from "./icons/Icon8";







const offersItems = [
  {
    activeIcon: activeIcon1,
    icon,
    class: "in-ai",

    title: "Predictive Analytics",
    desc: "Anticipate the future with data-driven insights. Our predictive analytics services decode patterns and forecast market trends, consumer behavior, financial risks, etc.",
  },
  {
    class: "in-ai",
    activeIcon: activeIcon2,

    icon: icon2,
    title: "Natural Language Processing",
    desc: "Give your data a voice. Our NLP solutions empower your systems to understand, interpret, and communicate in human language, bridging the gap between data and real-world impact.",
  },
  {
    class: "in-ai",
    activeIcon: activeIcon3,

    icon: icon3,
    title: "Computer Vision",
    desc: "We develop ML solutions using smart algorithms that extract valuable insights from diverse visual inputs, aiding you in making informed, appropriate decisions.",
  },

  {
    class: "in-ai",
    activeIcon: activeIcon4,

    icon: icon4,
    title: "Reinforcement Learning",
    desc: "Our reinforcement learning solutions teach systems to learn from experience, creating smart, self-sufficient agents that can improve results in challenging situations.",
  },

  {
    class: "in-ai",
    activeIcon: activeIcon5,

    icon: icon5,
    title: "Anomaly Detection",
    desc: "Find the hidden issues in your data with ease with our anomaly detection services. Identify unusual patterns and exceptions, protecting your operations from potential unseen problems.",
  },

  {
    class: "in-ai",
    activeIcon: activeIcon6,

    icon: icon6,
    title: "Personalization and Recommendation",
    desc: "Create experiences that truly connect. Our systems personalize and recommend based on what users like, building stronger relationships and increasing involvement.",
  },
];

function AI() {
  const [fill, setFill] = useState("black");
  const [index, setIndex] = useState(0);
  const handleMouseEnter = (index) => {
    setFill("#860404");
    setIndex(index);
  };
  return (

      <>
          <Helmet>
          <meta charSet="utf-8" />
          <title>AI & Machine Learning Services - Appedology</title>
          <meta name="description" content="Future-proof ML & AI solutions to automate your business operations, improving efficiency and productivity, fostering smarter workflows! Contact today!" />
          <link rel="canonical" href="https://appedology.pk/machine-learning-and-ai" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="AI & Machine Learning Services - Appedology" />
          <meta property="og:description" content="Future-proof ML & AI solutions to automate your business operations, improving efficiency and productivity, fostering smarter workflows! Contact today!" />
          <meta property="og:url" content="" />
          <meta property="og:locale" content= "pakistan" />
          <meta property="og:site_name" content="Appedology" />
          <meta property="og:updated_time" content="2024-03-29T07:08:50+00:00" />

      </Helmet>



    <div className="ai">
      <TopSection
        subtitle="Machine Learning & AI"
        title="AI & Machine Learning Services"
        desc="At Appedology, we develop future-proof ML & AI solutions to automate business operations and help human employees improve efficiency and productivity, fostering smarter workflows."
        image={image1}
      />
      <div className="network-top">
        <div className="network-top-title in-ai pt-5 mt-2">
          <span>AI & ML Revolution</span>
        </div>
        <div className="network-top-content row pt-4">
          <div className="network-top-contnet-texts col-lg-6">
            <div>
              In a world rich with data, AI and ML are key to unlocking insights, driving businesses to unparalleled success. 
            </div>
            <div>
            At Appedology, we tailor AI and machine learning services to your unique needs, guiding you from concept to a fully operational solution. 
            </div>

            <div>
              We're with you at every step, ensuring your business becomes smarter, faster, and more competitive.
            </div>

            <div>
              Imagine a world of sharper decisions and tailor-made customer experiences. With our AI and ML services, that world is within reach.
            </div>
          <div>
            Join us on this journey of transformation, where data becomes wisdom and where your success knows no bounds.
          </div>

          </div>
          <div className="col-lg-6">
            <img className="img-fluid imgaitop" src={image2} alt="" />
          </div>
        </div>
      </div>
      <div className="ai-solutions">
        <div className="ai-solutions-title network-top-title in-ai">
          <span>AI & ML Solutions Across Industries</span>
        </div>
        <div className="ai-solutions-desc poppins-regular">
        Explore how AI & machine learning as a service are tailored for different industries, each designed to tackle unique operational challenges and complexities specific to each sector.
        </div>
        <Tabs />
      </div>
      <div className="network-our-offers">
        <div className="network-heading">
          
          <div>
            <span>Our Services</span>
          </div>
          <div>
          Here’s how the biggest machine learning companies are empowering your Business with Advanced AI and ML Solutions.
          </div>
        </div>
        <div className="aiitems">
          <Items items={offersItems} />
        </div>
      </div>
      <div className="position-relative my-5">
        <img className={`position-absolute end-0`} style={{marginTop : "-280px", maxWidth:"140px", }} src={bgSideIcon2} alt="" />
      </div>
      <div className="ai-our-process">
        <div className="network-top-title in-ai mb-5">
          <span>AI & ML Services Roadmap</span>
        </div>
        <div className="ai-process-content mt-3">
          <div className="ai-proces`s-content-row pt-4">
            <div className="ai-process-content-item">
              <div className="mb-2">Needs Assessment</div>
              <div class="lh-sm">
              We begin with a thorough analysis of your business needs and goals to tailor the AI & ML solution.
              </div>
            </div>
          </div>

          <div className="ai-process-content-row">
            <div className="ai-process-content-item">
              <div className="mb-2 datacollectionhead">Data Collection and Preparation</div>
              <div class="lh-sm">
              Our team focuses on gathering and preprocessing necessary data, ensuring it is clean, organized, and suitable for model training.
              </div>
            </div>
            <div className="ai-process-content-item">
              <div className="mb-2">Model Selection</div>
              <div class="lh-sm">
              The next step includes selecting a suitable tech stack that caters specifically to your business challenges.
              </div>
            </div>
          </div>

          <div className="ai-process-content-row">
            <div className="ai-process-content-item">
              <div className="mb-2">Model Training and Testing</div>
              <div class="lh-sm">
              Our team then trains the models with your data, followed by rigorous testing to refine accuracy and performance
              </div>
            </div>
            <div className="ai-process-content-item integration_dply">
              <div className="mb-2">Integration & Deployment</div>
              <div class="lh-sm">
              The model is then seamlessly integrated into your existing systems, ensuring they work harmoniously with your business processes.
              </div>
            </div>
          </div>

          <div className="ai-process-content-row">
            <div className="ai-process-content-item">
              <div className="mb-2">Monitoring and Evaluation</div>
              <div class="lh-sm">
              We continuously monitor the performance of the solution, making adjustments as needed to optimize results.
              </div>
            </div>
            <div className="ai-process-content-item">
              <div className="mb-2">Feedback and Iteration</div>
              <div class="lh-sm">
              The designated team collects the feedback and improves the models for sustained efficiency and effectiveness.
              </div>
            </div>
          </div>

          <div className="ai-process-content-row row-end">
            <div className="ai-process-content-item">
              <div className="mb-2">Ongoing Support</div>
              <div class="lh-sm">
              We offer ongoing support and updates, and improve your AI models using the latest data, ensuring accuracy and up-to-date performance, all in line with DevOps best practices.
              </div>
            </div>
            {/* <div className="ai-process-content-item">
              <div>Needs Assessment</div>
              <div>
                Find the hidden issues in your data with ease with our anomaly
                detection services.
              </div>
            </div> */}
          </div>

          {/* <div className="ai-process-content-row row-end">
            <div className="ai-process-content-item">
              <div>Needs Assessment</div>
              <div>
                Find the hidden issues in your data with ease with our anomaly
                detection services.
              </div>
            </div>
          </div> */}

          <img className="ai-process-content-child" src={image4} />
        </div>
      </div>
      <div className="position-relative my-5">
        <img className={`position-absolute start-0`} style={{marginTop : "-380px", maxWidth:"140px", }} src={bgSideIcon1} alt="" />
      </div>
      <div className="natural-iterface">
        <div className=" network-top-title  natuaral-title">
          <span>Natural Interface in Action</span>
        </div>
        <div className="ai-solutions-desc in-natural-iterface pb-5">
        In the realm of Natural Interface, the possibilities are endless. Harnessing the potential of AI-driven technologies, our best machine learning services seamlessly bridge the gap between humans and machines. 
        </div>
          

        <div className="natural-iterface-icons-- row">
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem text="Natural Language Processing" Icon={Icon} />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem text="Speech Processing" Icon={Icon2} />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem text="Audio Processing" Icon={Icon3} />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem text="Signal Processing" Icon={Icon4} />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem text="Sensor Data Processing" Icon={Icon5} />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem
            Icon={Icon6}
            text="Image/Video Processing/Computer Vision"
          />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem Icon={Icon7} text="Multisensory Interfaces" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3">
          <InterfaceItem Icon={Icon8} text="Virtual Reality" />
          </div>          
        </div>
        <p className="col-md-12 p-2 lh-base mx-auto h5 text-center pb-5 pt-2">We redefine the way you interact with technology. From intuitive voice commands to immersive VR experiences, we make your interactions feel, well, natural.</p>
      </div>
      <div className="position-relative my-5">
        <img className={`position-absolute end-0`} style={{marginTop : "-480px", maxWidth:"140px", }} src={bgSideIcon2} alt="" />
      </div>

      <TechStack
        title="Tech Stack"
        desc="Your AI journey starts with our proven technology stack."
      />
      <div className='happyclient pt-4 pb-4 mb-24'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={50}
                                interval={30}
                                spanContnet="+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Data Scientists </p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2><AutoCounter 
                                limit={10}
                                interval={100}
                                spanContnet="+"
                          /></h2>
                          <p className='text-uppercase pt-3'>Industries Served</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='client text-center'>
                          <h2>24/7</h2>
                          <p className='text-uppercase pt-3'>Customer Support </p>
                        </div>
                    </div>
                    
                </div>
            </div>
         </div>

      <div className="insights mt-5">
        <div className="insights-title ">
          <span>Insights</span>
        </div>
        <div className="insights-desc">
          Uncover opportunities with our expertise.
        </div>

        <div className="insight-items">
          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image1})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image3})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>

          <div
            className="insight-item"
            style={{
              backgroundImage: `url(${image1})`,
              backgroundSize: "cover",
            }}
          >
            <div>
              Navigating the Network and Cloud Computing Landscape: Challenges
              and Opportunities for Business Transformation.
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default AI;
